import React from "react";

import Verification from "components/Verification/Verification";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const VerifyEmail = () => {
  const [loginData, setLoginData] = useState({});
  const [otpInvalid, setOtpInvalid] = useState(false);
  const [OTP, setOTP] = useState("");
  let Navigate = useNavigate();

  async function loginHandler(e) {
    e.preventDefault();
    let api = process.env.REACT_APP_API;
    let route = "/auth/signup/complete";
    let url = api + route;

    let ref = localStorage.getItem("refferer");
    let body = {
      ...loginData,
      otp: OTP,
      // referrer: ref
    }


    try {
      let { data, status } = await axios.post(url, body);
      let token = data.token;


      if (status == 201) {
        Navigate("/signup-success")

      } else {
        setOtpInvalid(true);
      }

    } catch (e) {
      setOtpInvalid(true);
    }
  }

  async function getSignupData() {
    let loginData = localStorage.getItem("signup-details");
    loginData = JSON.parse(loginData);
    setLoginData(loginData);
    return loginData;
  }

  useEffect(() => {
    getSignupData();
  }, []);
  return (
    <Verification
      title={loginData?.method === "Email" ? "Verify Email" : "Verify Phone"}
      data={loginData}
      handler={loginHandler}
      otp={OTP}
      setOTP={setOTP}
      setOtpInvalid={setOtpInvalid}
      subTitle={`We send 6 digit verification code to your ${loginData?.method}. Please check your ${loginData?.method}.`}
      redirect="/set-password"
    />
  );
};

export default VerifyEmail;
