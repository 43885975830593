import React, { useState } from "react";
import classes from "./LegalPolicyAndTerms.module.css";
import Policy from "components/LegalPolicyAndTerms/Policy/Policy";
import clsx from "clsx";
import Topic from "components/LegalPolicyAndTerms/Topic/Topic";
import { useTheme } from "ThemeContext/ThemeContext";
import Footer from "components/Footer/Footer";

const LegalPolicyAndTerms = () => {
  const { isDarkTheme } = useTheme();
  const tabs = [
    "Terms of Use",
    "Privacy Policy",
    "Cookies Policy",
    "Exchange Terms",
    "Trading Rules",
  ];
  const [activeTab, setActiveTab] = useState("Terms of Use");
  return (
    <>
      <div
        className={clsx(
          classes.wrapper,
          "container",
          !isDarkTheme && classes.lightTheme
        )}
      >
        <div className={classes.textContainer}>
          <h2 className={classes.heading}>
            Legal, Policy & <span className={classes.highlight}>Terms</span>
          </h2>
          <p className={classes.text}>
            Exploring Innovations and Opportunities in the Evolving
            Cryptocurrency Exchange Landscape
          </p>{" "}
          <div className={classes.tabs}>
            {tabs.map((tab, i) => (
              <p
                className={clsx(
                  classes.tab,
                  tab === activeTab && classes.activeTab
                )}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </p>
            ))}
          </div>
        </div>
        <div className={classes.container}>
          {" "}
          <Topic />
          <Policy />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LegalPolicyAndTerms;
