import React from "react";
import { FaAngleRight } from "react-icons/fa";
import classes from "./ViewMore.module.css";

const ViewMore = ({ onClick, text }) => {
  return (
    <button className={classes.viewMore} onClick={onClick}>
      {text ? text : " View More"} <FaAngleRight className={classes.right} />
    </button>
  );
};

export default ViewMore;
