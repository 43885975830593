import React from "react";
import styles from "./Toggle.module.css";
import { useTheme } from "ThemeContext/ThemeContext";
import clsx from "clsx";

const Toggle = ({ enable, handleToggle }) => {
  const { isDarkTheme } = useTheme();
  return (
    <div className={clsx(styles.wrapper, !isDarkTheme && styles.lightTheme)}>
      <input
        checked={enable}
        onChange={handleToggle}
        className={styles.switchCheckbox}
        id={`switch`}
        type="checkbox"
      />
      <label
        className={clsx(styles.switchLabel, enable && styles.active)}
        htmlFor={`switch`}
      >
        <span className={styles.switchButton} />
      </label>
    </div>
  );
};

export default Toggle;
