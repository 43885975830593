import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchMarkets = createAsyncThunk("get_markets", async (args, {
    rejectWithValue
}) => {

    try {
        let base_url = process.env.REACT_APP_API
        let route = "/market/markets"
        let data = await axios.get(base_url + route)
        let markets = (data.data.markets)
        return markets
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


const marketsSlice = createSlice({
    name: 'markets',
    initialState: {
        markets: [],
        isLoading: false,
        isFailed: false
    },
    reducers: {
    },
    // extra reducer use builder callback
    extraReducers: (builder) => {
        builder.addCase(fetchMarkets.pending, (state, action) => {
            state.isLoading = true
            state.isFailed = false
        })
        builder.addCase(fetchMarkets.fulfilled, (state, action) => {
            state.isLoading = false
            state.isFailed = false
            state.markets = action.payload
        })
        builder.addCase(fetchMarkets.rejected, (state, action) => {
            state.isLoading = false
            state.isFailed = true
        })
    }

})

export default marketsSlice.reducer