import React from "react";

import classes from "./SingleRow.module.css";
import clsx from "clsx";
import Button from "components/Button/Button";
import CopyToClipboard from "react-copy-to-clipboard";
import { copy } from "assets";
import { useSelector } from "react-redux";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
const SingleRow = ({
  coin,
  id,
  amount,
  date,
  amountEarned,
  status,
  currency,
  is_claimed,
}) => {
  let currencies = useSelector((state) => state.currencies.currencies);
  let curr = currencies.find((curr) => curr.id == currency);

  async function claimReward() {
    let api = process.env.REACT_APP_API
    let route = "/rewards/claim";
    let url = api + route;

    try {
      const { data, status } = await axios.post(url, {
        id: id
      },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("auth-token")}`,
          },
        })

      if (status == 200) {
        toast.success("claimed rewards", {
          position: "top-center",
          theme: "dark",
          toastId: "claimed-rewards"
        })

        setTimeout(() => {
          window.location.reload()
        }, 3000)
      }


    } catch (e) {
      alert(e)
    }
  }


  return (

    <>
      <tr className={clsx(classes.row, classes.valueContainer)}>
        <td className={clsx(classes.item, classes.firstItem)}>
          <div className={classes.logoAndNameContainer}>
            <img src={curr?.logo} alt="#" className={classes.logo} />
            <div className={classes.nameContainer}>
              <p>{curr?.name}</p>
              <span>{curr?.symbol?.toUpperCase()}</span>
            </div>
          </div>
        </td>

        <td className={classes.item}>
          <div className={classes.stakeId}>
            {id}{" "}
            <CopyToClipboard text={id}>
              <img src={copy} alt="#" className={classes.copyIcon} />
            </CopyToClipboard>
          </div>
        </td>
        <td className={clsx(classes.item, classes.amount)}>{amount / 100000000} {curr?.symbol.toUpperCase()}</td>
        <td className={clsx(classes.item, classes.amount)}>{new Date(date).toLocaleString()}</td>
        <td className={clsx(classes.item)}>
          <p
            className={clsx(
              status?.toLowerCase() === "finished" && classes.lastItem,
              status?.toLowerCase() === "live" && classes.live,
              status?.toLowerCase() === "finished" && classes.finished
            )}
          >
            {" "}
            {is_claimed ? "Claimed" : "Unclaimed"}
          </p>
        </td>
        {!is_claimed && (
          <td className={classes.item}>
            <div className={classes.buttonContainer}>
              <Button btnGray size="sm" onClick={() => {
                claimReward()
              }}>
                Claim
              </Button>
            </div>
          </td>
        )}
      </tr>
    </>
  );
};

export default SingleRow;
