import React, { useRef, useState } from "react";

import Buy from "./Buy/Buy";
import Sell from "./Sell/Sell";
import clsx from "clsx";
import useOnClickOutside from "hooks/useOnClickOutside";
import Dropdown from "components/Dropdown/Dropdown";

import classes from "./BuySell.module.css";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import axios from "axios";
import { useTheme } from "ThemeContext/ThemeContext";
import { fetchBalance } from "../../../redux/balance";
const dropdownItems = [
  {
    label: "Stop Limit",
  },
  {
    label: "Stop Market",
  },
  {
    label: "Oco",
  },
];
const BuySell = ({
  onlyBuyModal,
  onlySellModal,
  onClose,
  askSideAmount,
  pair,
}) => {
  const { isDarkTheme } = useTheme();
  let user = useSelector((state) => state.user.userInfo);
  const [activeButton, setActiveButton] = useState("Spot");
  const buttons = ["Spot"];
  const [activeTab, setActiveTab] = useState("Limit");

  const tabs = ["Limit", "Market"];


  let dispatch = useDispatch();

  const dropdownRef = useRef(null);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  async function executeOrder(side, order_amount, price, pair) {
    let api = process.env.REACT_APP_API;
    let route = "/spot/order/create";

    let body = {
      pair: pair,
      side: side,
      price: Number(price),
      order_type: "Limit",
      amount: Number(order_amount),
    };

    try {
      let { data, status } = await axios.post(api + route, body, {
        headers: {
          Authorization: "Bearer " + Cookies.get("auth-token"),
        },
      });

      if (status == 200) {
        dispatch(fetchBalance());
      }
    } catch (e) { }
  }

  return (
    <div
      className={clsx(
        classes.mainWrapper,
        (onlyBuyModal || onlySellModal) && classes.mobileMainWrapper,
        !isDarkTheme && classes.lightTheme
      )}
    >
      <div
        className={clsx(
          classes.activeButtons,
          (onlyBuyModal || onlySellModal) && classes.showOnMobile
        )}
      >
        {buttons.map((tab, i) => (
          <p
            className={clsx(
              classes.button,
              tab.toLowerCase() === activeButton.toLowerCase() &&
              classes.activeButton
            )}
            key={i}
            onClick={() => setActiveButton(tab)}
          >
            {tab}
          </p>
        ))}
      </div>
      <div
        className={clsx(
          classes.tabs,
          (onlyBuyModal || onlySellModal) && classes.showOnMobile
        )}
      >
        {tabs.map((el, i) => (
          <p
            key={i}
            className={[
              classes.tab,
              el.toLowerCase() === activeTab.toLowerCase() && classes.activeTab,
            ].join(" ")}
            onClick={() => setActiveTab(el)}
          >
            {el}
          </p>
        ))}

        {/* <div ref={dropdownRef}>
          <Dropdown
            placeholder="Trailing Shop"
            className={classes.tab}
            isActive={isDropdownActive}
            selectedValue={selectedValue}
            dropdownItems={dropdownItems}
            setIsDropdownActive={setIsDropdownActive}
            dropdownRef={dropdownRef}
            onClick={() => {
              if (isDropdownActive) {
                setIsDropdownActive(false);
              } else {
                setIsDropdownActive(true);
              }
            }}
            isDropdownActive={isDropdownActive}
            onSelect={(val) => {
              setSelectedValue(val);
              setIsDropdownActive(false);
            }}
          />
        </div> */}
      </div>
      <div className={classes.wrapper}>
        <Buy
          side={"buy"}
          activeTab={activeTab}
          pair={pair}
          user={user}
          executeOrder={executeOrder}
        />
        <Buy
          side={"sell"}
          activeTab={activeTab}
          pair={pair}
          user={user}
          executeOrder={executeOrder}
        />
      </div>
      {/* for mobile */}
      <div className={clsx(classes.wrapper, classes.mobileWrapper)}>
        {onlyBuyModal && (
          <>
            <Buy
              onClose={onClose}
              side={"buy"}
              activeTab={activeTab}
              pair={pair}
              user={user}
              executeOrder={executeOrder}
            />
          </>
        )}
        {onlySellModal && (
          <Buy
            onClose={onClose}
            side={"sell"}
            activeTab={activeTab}
            pair={pair}
            user={user}
            executeOrder={executeOrder}
          />
        )}
      </div>
    </div>
  );
};

export default BuySell;
