import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'


export const getStakingInstances = createAsyncThunk("fetch-staking-instances", async (args, {
    rejectWithValue
}) => {

    try {
        let base_url = process.env.REACT_APP_API
        let route = "/staking/instances"
        let data = await axios.get(base_url + route)
        let instances = (data.data.staking_instances)
        return instances
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getMyStakes = createAsyncThunk("fetch-stakings", async (args, {
    rejectWithValue
}) => {

    try {
        let base_url = process.env.REACT_APP_API
        let route = "/staking/mystakes"
        let data = await axios.get(base_url + route, {
            headers: {
                "Authorization": "Bearer " + Cookies.get("auth-token")
            }
        })




        let instances = (data.data.staking_instances)
        return instances;
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


const stakingInstancesSlice = createSlice({
    name: 'stakingInstances',
    initialState: {
        stakingInstances: [],
        mystakes: [],
        isLoading: false,
        isFailed: false
    },
    reducers: {
    },
    // extra reducer use builder callback
    extraReducers: (builder) => {
        builder.addCase(getStakingInstances.pending, (state, action) => {
            state.isLoading = true
            state.isFailed = false
        })
        builder.addCase(getStakingInstances.fulfilled, (state, action) => {
            state.isLoading = false
            state.isFailed = false
            state.stakingInstances = action.payload
        })
        builder.addCase(getStakingInstances.rejected, (state, action) => {
            state.isLoading = false
            state.isFailed = true
        })

        builder.addCase(getMyStakes.pending, (state, action) => {
            state.isLoading = true
            state.isFailed = false
        })
        builder.addCase(getMyStakes.fulfilled, (state, action) => {
            state.isLoading = false
            state.isFailed = false
            state.mystakes = action.payload
        })
        builder.addCase(getMyStakes.rejected, (state, action) => {
            state.isLoading = false
            state.isFailed = true
        })
    }

})

export default stakingInstancesSlice.reducer