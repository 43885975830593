import React, { useState } from "react";

import { FaArrowRight } from "react-icons/fa";

import classes from "./InviteFriends.module.css";

import { inviteFriends } from "assets";
import ReferreModal from "modals/ReferrelModal/ReferrelModal";
import { useNavigate } from "react-router-dom";

const InviteFriends = ({ user }) => {
  let Navigate = useNavigate();
  const [isRefferelModalShow, setIsRefferelModalShow] = useState(false);
  return (
    <>
      <div className={classes.container}>
        <div className={classes.left}>
          <h4 className={classes.heading}>Invite Friends</h4>
          <p className={classes.info}>
            Invite with your referral and get <span>10%</span> commissions
          </p>
          <button
            className={classes.button}
            onClick={() => Navigate("/refer-and-earn")}
          >
            Invite now{" "}
            <FaArrowRight
              className={classes.arrow}
              style={{ fill: "var(--color-primary)" }}
            />
          </button>
        </div>
        <img src={inviteFriends} alt="#" className={classes.image} />
      </div>
      <ReferreModal
        user={user}
        isActive={isRefferelModalShow}
        onClose={() => setIsRefferelModalShow(false)}
      />
    </>
  );
};

export default InviteFriends;
