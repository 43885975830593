import React, { useMemo, useState } from "react";

import classes from "./OrderHistoryMobileView.module.css";
import clsx from "clsx";
import SingleRow from "./SingleRow/SingleRow";
import { useTheme } from "ThemeContext/ThemeContext";

const OrderHistoryMobileView = ({ data }) => {
  const { isDarkTheme } = useTheme();
  const [showDetails, setShowDetails] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return data?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, itemsPerPage]);
  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <div className={classes.row}>
        <p className={classes.heading}>Pair</p>{" "}
        <p className={classes.heading}>Time</p>
        <p className={clsx(classes.status, classes.heading)}>Status</p>
      </div>
      {currentTableData.map((el, i) => (
        <SingleRow
          {...el}
          key={i}
          showDetails={showDetails}
          setShowDetails={setShowDetails}
          id={i}
        />
      ))}
    </div>
  );
};

export default OrderHistoryMobileView;
