import React, { useState } from "react";
import classes from "./Policy.module.css";
import { magnifier } from "assets";
import Heading from "components/common/Heading/Heading";
const policies = [
  {
    title: "Lorem ipsum dolor sit amet",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet mattis vulputate enim nulla. Nunc mi ipsum faucibus vitae aliquet nec ullamcorper. Nisi vitae suscipit tellus mauris a diam. Tincidunt lobortis feugiat vivamus at augue eget arcu dictum. Eu sem integer vitae justo eget magna fermentum iaculis. Lectus proin nibh nisl condimentum id venenatis a condimentum vitae. Morbi leo urna molestie at elementum eu. Feugiat sed lectus vestibulum mattis. Auctor urna nunc id cursus metus aliquam eleifend. Mauris nunc congue nisi vitae suscipit tellus mauris. Non diam phasellus vestibulum lorem sed risus. Velit ut tortor pretium viverra suspendisse potenti nullam ac tortor.",
  },
  {
    title: "Lorem ipsum dolor sit amet",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet mattis vulputate enim nulla. Nunc mi ipsum faucibus vitae aliquet nec ullamcorper. Nisi vitae suscipit tellus mauris a diam. Tincidunt lobortis feugiat vivamus at augue eget arcu dictum. Eu sem integer vitae justo eget magna fermentum iaculis. Lectus proin nibh nisl condimentum id venenatis a condimentum vitae. Morbi leo urna molestie at elementum eu. Feugiat sed lectus vestibulum mattis. Auctor urna nunc id cursus metus aliquam eleifend. Mauris nunc congue nisi vitae suscipit tellus mauris. Non diam phasellus vestibulum lorem sed risus. Velit ut tortor pretium viverra suspendisse potenti nullam ac tortor.",
  },
  {
    title: "Lorem ipsum dolor sit amet",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet mattis vulputate enim nulla. Nunc mi ipsum faucibus vitae aliquet nec ullamcorper. Nisi vitae suscipit tellus mauris a diam. Tincidunt lobortis feugiat vivamus at augue eget arcu dictum. Eu sem integer vitae justo eget magna fermentum iaculis. Lectus proin nibh nisl condimentum id venenatis a condimentum vitae. Morbi leo urna molestie at elementum eu. Feugiat sed lectus vestibulum mattis. Auctor urna nunc id cursus metus aliquam eleifend. Mauris nunc congue nisi vitae suscipit tellus mauris. Non diam phasellus vestibulum lorem sed risus. Velit ut tortor pretium viverra suspendisse potenti nullam ac tortor.",
  },
  {
    title: "Lorem ipsum dolor sit amet",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet mattis vulputate enim nulla. Nunc mi ipsum faucibus vitae aliquet nec ullamcorper. Nisi vitae suscipit tellus mauris a diam. Tincidunt lobortis feugiat vivamus at augue eget arcu dictum. Eu sem integer vitae justo eget magna fermentum iaculis. Lectus proin nibh nisl condimentum id venenatis a condimentum vitae. Morbi leo urna molestie at elementum eu. Feugiat sed lectus vestibulum mattis. Auctor urna nunc id cursus metus aliquam eleifend. Mauris nunc congue nisi vitae suscipit tellus mauris. Non diam phasellus vestibulum lorem sed risus. Velit ut tortor pretium viverra suspendisse potenti nullam ac tortor.",
  },
];
const Policy = () => {
  const [searchValue, setSearchValue] = useState("");

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Heading heading="Privacy Policy" lg />
        <div className={classes.searchContainer}>
          <img src={magnifier} alt="magnifier" className={classes.icon} />
          <input
            type="search"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </div>
      <div className={classes.policies}>
        {policies.map((el, i) => (
          <div className={classes.policy} key={i}>
            <h4 className={classes.title}>
              <span>{i + 1}.</span> {el.title}
            </h4>
            <p className={classes.info}>{el.info}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Policy;
