import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import classes from "./Topic.module.css";
import Heading from "components/common/Heading/Heading";
import clsx from "clsx";

const Topic = () => {
  const topics = [
    "Latest Brandname News",
    "New Cryptos Listing",
    "Product Updates Info",
    "System Maintenance Info",
    "Delisting Info",
    "Others Topic",
  ];
  const [activeTopic, setActiveTopic] = useState("Latest Brandname News");
  const [showAllTopic, setShowAlltopic] = useState(false);
  return (
    <div className={classes.wrapper}>
      <div
        className={classes.header}
        onClick={() => {
          if (showAllTopic) {
            setShowAlltopic(false);
          } else {
            setShowAlltopic(true);
          }
        }}
      >
        <Heading heading="Topic" />
        {showAllTopic ? (
          <IoIosArrowUp className={classes.arrow} />
        ) : (
          <IoIosArrowDown className={classes.arrow} />
        )}
      </div>
      <div
        className={clsx(classes.topics, showAllTopic && classes.showAllTopic)}
      >
        <p className={classes.heading}> All Announcement</p>
        {topics.map((topic, i) => (
          <p
            className={clsx(
              classes.topic,
              topic === activeTopic && classes.activeTopic
            )}
            key={i}
            onClick={() => setActiveTopic(topic)}
          >
            {topic}
          </p>
        ))}
      </div>
    </div>
  );
};

export default Topic;
