import React, { useRef, useState } from "react";
import classes from "./BalanceContainer.module.css";
import Button from "components/Button/Button";
import useOnClickOutside from "hooks/useOnClickOutside";
import Dropdown from "components/Dropdown/Dropdown";
import Wrapper from "components/common/Wrapper/Wrapper";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { toast } from "react-toastify";
const dropdownItems = [
  {
    label: "Usd",
  },
];
const BalanceContainer = ({ totalBalance }) => {
  const dropdownRef = useRef(null);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(dropdownItems[0].label);
  useOnClickOutside(dropdownRef, () => setIsDropdownActive(false));
  let Navigate = useNavigate();
  return (
    <Wrapper className={clsx(classes.wrapper)}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <h6 className={classes.heading}>Balance</h6>
          <div className={classes.tabs}>
            {/* <p className={classes.tab}>Wallets</p>
            <p className={classes.tab}>Orders</p> */}
          </div>
        </div>

        <div className={classes.amountContainer}>
          <h4 className={classes.amount}>
            {totalBalance.toFixed(2)} {`USD`}
          </h4>

          <div ref={dropdownRef} className={classes.dropdownWrapper}>
            {/* <Dropdown
              isActive={isDropdownActive}
              selectedValue={selectedValue}
              dropdownItems={dropdownItems}
              onClick={() => {
                if (isDropdownActive) {
                  setIsDropdownActive(false);
                } else {
                  setIsDropdownActive(true);
                }
              }}
              isDropdownActive={isDropdownActive}
              onSelect={(val) => {
                setSelectedValue(val);
                setIsDropdownActive(false);
              }}
            /> */}
          </div>
        </div>
        {/* <div className={classes.equvalentBalance}>
          <h6>≈</h6>
          <h6 className={classes.usdBalance}>${totalBalance.toFixed(2)}</h6>
        </div> */}
        <div className={classes.buttonContainer}>
          <Button
            size="md"
            btnGray
            onClick={() => {
              Navigate("/wallet/crypto/deposit");
            }}
            className={classes.button}
          >
            Deposit
          </Button>{" "}
          <Button
            size="md"
            btnGray
            onClick={() => {
              Navigate("/wallet/crypto/withdraw");
            }}
            className={classes.button}
          >
            Withdraw
          </Button>{" "}
          <Button
            size="md"
            btnGray
            onClick={() => {
              toast.info("Buying crypto with fiat is coming soon", {
                position: "top-center",
                theme: "dark",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }}
            className={classes.button}
          >
            Buy Crypto
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default BalanceContainer;
