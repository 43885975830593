import React from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

import classes from "./SingleRow.module.css";
import clsx from "clsx";
import { useTheme } from "ThemeContext/ThemeContext";

const SingleRow = ({ props }) => {
  console.log(props);
  let {
    date,
    pair,
    type,
    side,
    price,
    quantity,
    amount,
    filled,
    timestamp,
    total,
    tradeInUsdt,

    showDetails,
    setShowDetails,
    id,
  } = props;

  //   {
  //     "id": "17078674676584173314049379989997154",
  //     "pair": "UNSUSDT",
  //     "side": "Ask",
  //     "price": 120000000,
  //     "quantity": 100000000,
  //     "timestamp": 1707867467658417,
  //     "role": "maker",
  //     "fee": 0,
  //     "total": 1.2
  // }
  const { isDarkTheme } = useTheme();
  return (
    <>
      <div
        className={clsx(
          classes.row,
          showDetails && classes.active,
          !isDarkTheme && classes.lightTheme
        )}
      >
        <p className={classes.item}>{pair}</p>
        <p className={classes.item}>
          {new Date(timestamp / 1000).toLocaleString()}
        </p>
        <p className={clsx(classes.item, classes.tradeInUsdt)}>
          {(quantity / Math.pow(10, 8)).toFixed(2)}
        </p>

        {showDetails === id ? (
          <FaAngleUp
            className={classes.arrow}
            color="#fff"
            onClick={() => {
              if (showDetails) {
                setShowDetails(false);
              } else {
                setShowDetails(true);
              }
            }}
          />
        ) : (
          <FaAngleDown
            className={classes.arrow}
            onClick={() => {
              setShowDetails(id);
            }}
          />
        )}
      </div>
      {showDetails === id && (
        <div className={classes.infoContainer}>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Type</p>
            <p className={classes.value}>{"Spot"}</p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Side</p>
            <p
              className={clsx(
                classes.value,
                side.toLowerCase() === "buy" && classes.buy,
                side.toLowerCase() === "sell" && classes.sell
              )}
            >
              {side}
            </p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Price</p>
            <p className={classes.value}>{price / Math.pow(10, 8)}</p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Amount</p>
            <p className={classes.value}>{quantity / Math.pow(10, 8)}</p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Filled</p>
            <p className={classes.value}>{filled}</p>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Total</p>
            <p className={classes.value}>{total}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleRow;
