import Verification from "components/Verification/Verification";
import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from "../../redux/user";


const EmailVerification = ({ dispatchAll }) => {
  let dispatch = useDispatch();
  const [loginData, setLoginData] = useState({});
  const [otpInvalid, setOtpInvalid] = useState(false);
  const [OTP, setOTP] = useState("");

  const navigate = useNavigate();
  async function loginHandler(e) {
    e.preventDefault();
    let api = process.env.REACT_APP_API;
    let route = "/auth/login";
    let url = api + route;

    let body = {
      ...loginData,
      mfa: [
        {
          method: "Email",
          data: OTP
        }
      ]
    }


    try {
      let { data, status } = await axios.post(url, body);
      let token = data.token;

      if (status == 200) {
        if (token) {
          Cookies.set("auth-token", token);
          dispatch(getUserInfo());
          dispatchAll();
          navigate("/dashboard")
        }

      } else {
        setOtpInvalid(true);
      }

    } catch (e) {
      setOtpInvalid(true);
    }
  }

  async function getLoginData() {
    let loginData = localStorage.getItem("login-details");
    loginData = JSON.parse(loginData);
    setLoginData(loginData);
    return loginData;
  }

  useEffect(() => {
    getLoginData();
  }, []);
  return (
    <Verification
      data={loginData}
      handler={loginHandler}
      otp={OTP}
      setOTP={setOTP}
      setOtpInvalid={setOtpInvalid}
      subTitle="We sent a 6 digit verification code to your email. Please check your email." />
  );
};

export default EmailVerification;
