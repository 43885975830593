import React, { useState, useMemo } from "react";
import Pagination from "common/Pagination/Pagination";
import classes from "./ClaimedTransaction.module.css";

import clsx from "clsx";
import SingleRow from "./SingleRow/SingleRow";

const ClaimedTransaction = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, itemsPerPage]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <tbody>
            <tr className={classes.row}>
              <th className={classes.heading}>
                <p className={classes.tableHeading}> Date</p>
              </th>
              <th className={clsx(classes.heading, classes.textAlignCenter)}>
                <p className={classes.tableHeading}>Coin</p>
              </th>
              <th className={classes.heading}>
                <p className={classes.tableHeading}>Min. Staking</p>
              </th>
              <th className={classes.heading}>
                <p className={classes.tableHeading}>Max. Staking</p>
              </th>
              <th className={classes.heading}>
                <p className={classes.tableHeading}> APY</p>
              </th>
              <th className={classes.heading}>Duration </th>

              <th className={classes.heading}>
                <p className={clsx(classes.tableHeading, classes.lastHeading)}>
                  Status
                </p>
              </th>
            </tr>
            {currentTableData?.map((el, index) => (
              <SingleRow {...el} key={index} index={index} />
            ))}
          </tbody>
        </table>{" "}
        <div className={classes.pagination}>
          <Pagination
            currentPage={currentPage}
            totalCount={data?.length}
            pageSize={itemsPerPage}
            onPageChange={(page) => setCurrentPage(page)}
            siblingCount={0}
          />
        </div>
      </div>
    </div>
  );
};

export default ClaimedTransaction;
