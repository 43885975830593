import React from "react";

import classes from "./SingleRow.module.css";
import clsx from "clsx";

const SingleRow = ({
  userType,
  tradingVolumeUsdLast30Days,
  andOr,
  makerOff,
  takerOff,
  withDrawLimit24HourUsdt,
}) => {
  return (
    <>
      <tr className={clsx(classes.row, classes.valueContainer)}>
        <td className={classes.item}>{userType}</td>
        <td className={classes.item}>{tradingVolumeUsdLast30Days}</td>
        <td className={clsx(classes.item)}>{andOr}</td>
        <td className={clsx(classes.item)}>
          {makerOff.maker} | {makerOff.off}
        </td>
        <td className={clsx(classes.item)}>
          {takerOff.taker} | {takerOff.off}
        </td>{" "}
        <td className={clsx(classes.item, classes.lastItem)}>
          {withDrawLimit24HourUsdt}
        </td>{" "}
      </tr>
    </>
  );
};

export default SingleRow;
