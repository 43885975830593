import clsx from "clsx";
import React, { useEffect, useState } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";

import Button from "components/Button/Button";
import classes from "./WithdrawVerificationModal.module.css";
import Modal from "components/common/Modal/Modal";
import Heading from "components/common/Heading/Heading";
import { protectedIcon } from "assets";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../ThemeContext/ThemeContext";

const renderButton = (buttonProps) => {
  return (
    <button {...buttonProps} className={classes.resend}>
      {buttonProps.remainingTime !== 0
        ? `Resend Code (${buttonProps.remainingTime})s`
        : "Resend"}
    </button>
  );
};
const renderTime = () => React.Fragment;

const WithdrawVerificationModal = ({ isActive, onClose, action }) => {
  const { isDarkTheme } = useTheme();
  const [OTP, setOTP] = useState("");
  const [otpInvalid, setOtpInvalid] = useState(false);

  // useEffect(() => {
  //   if (OTP !== "" && OTP.length === 6 && OTP !== "123456") setOtpInvalid(true);
  //   else setOtpInvalid(false);
  // }, [OTP]);
  const navigate = useNavigate();
  return (
    <Modal
      className={clsx(
        classes.wrapper,
        "overflow",
        !isDarkTheme && classes.lightTheme
      )}
      isActive={isActive}
      onClose={onClose}
      styled
    >
      <div className={classes.headingContainer}>
        <Heading heading="Verify Your Identity" center lg />
        <p className={classes.text}>
          We sent a 6 digit code to your email ensure about your identity.
          Please check your email.
        </p>
      </div>
      <div className={classes.inputs}>
        <OTPInput
          inputClassName={clsx(classes.input, otpInvalid && classes.hasError)}
          value={OTP}
          onChange={setOTP}
          autoFocus
          OTPLength={6}
          otpType="number"
          disabled={false}
        />
      </div>

      {/* {otpInvalid && (
        <div className={classes.helperError}>
          Invalid code! Please enter valid verification code.
        </div>
      )} */}

      <div className={clsx(classes.actions)}>
        <ResendOTP renderButton={renderButton} renderTime={renderTime} />

        <Button
          onClick={() => {
            // onClose();
            // navigate("wallet/crypto/withdraw/withdraw-success");
            action(OTP);
          }}
          size="md"
          btnPrimary
        >
          Verify
        </Button>
      </div>
      <p className={classes.protected}>
        {" "}
        <img src={protectedIcon} alt="#" /> Secured By {process.env.REACT_APP_EXCHANGE_NAME}.
      </p>
    </Modal>
  );
};

export default WithdrawVerificationModal;
