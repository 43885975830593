import clsx from "clsx";
import classes from "./Input.module.css";
import { useEffect, useState } from "react";
import { eye, eyeOpen } from "assets";
import { useTheme } from "ThemeContext/ThemeContext";

const Input = ({
  label,
  type,
  hasError,
  value,
  onChange,
  helperError,
  rootClassName,
  className,
  wFull,
  ...rest
}) => {
  const { isDarkTheme } = useTheme();
  const [inputType, setInputType] = useState(type);

  if (type === "password") {
  }
  useEffect(() => {
    setInputType(type);
  }, [type]);

  return (
    <>
      <div
        className={clsx(
          rootClassName,
          classes.inputContainer,
          !isDarkTheme && classes.lightTheme
        )}
      >
        {label && <p className={classes.label}>{label}</p>}
        <input
          type={inputType}
          onChange={onChange}
          value={value}
          className={clsx(
            classes.input,
            className,
            hasError && classes.hasError
          )}
          {...rest}
        />
        {type === "password" && (
          <img
            className={classes.eye}
            src={inputType === "password" ? eye : eyeOpen}
            alt="eye"
            onClick={() => {
              if (inputType === "text") setInputType("password");
              else if (inputType === "password") setInputType("text");
            }}
          />
        )}
      </div>
      {helperError && <div className={classes.helperError}>{helperError}</div>}
    </>
  );
};

export default Input;
