import React, { useState } from "react";
import classes from "./MobileLayoutBuySell.module.css";
import Button from "components/Button/Button";
import BuySellModal from "../../../../modals/SpotMobileUi/BuySellModal/BuySellModal";

const MobileLayoutBuySell = () => {
  const [isBuyModalShow, setIsBuyModalShow] = useState(false);
  const [isSellModalShow, setIsSellModalShow] = useState(false);
  return (
    <>
      <div className={classes.buttonContainer}>
        <Button
          wFull
          btnGreen
          size="md"
          onClick={() => setIsBuyModalShow(true)}
        >
          Buy
        </Button>{" "}
        <Button
          wFull
          btnWarning
          size="md"
          onClick={() => setIsSellModalShow(true)}
        >
          Sell
        </Button>
      </div>

      <BuySellModal
        onlyBuyModal={isBuyModalShow}
        onlySellModal={isSellModalShow}
        isActive={isBuyModalShow || isSellModalShow}
        onClose={() => {
          if (isBuyModalShow) {
            setIsBuyModalShow(false);
          } else if (isSellModalShow) {
            setIsSellModalShow(false);
          }
        }}
      />
    </>
  );
};

export default MobileLayoutBuySell;
