import clsx from "clsx";

import classes from "./CancelPopup.module.css";
import { cancel } from "assets";
import Button from "components/Button/Button";
import axios from "axios";
import Cookies from "js-cookie";

const CancelPopup = ({ isActive, setCancel, setIsShowCancelPopup, order_id }) => {

  async function cancelOrder(orderId) {
    let api = process.env.REACT_APP_API
    let route = "/spot/order/" + orderId;
    let url = api + route;

    try {
      const { data, status } = await axios.delete(url,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("auth-token")}`,
          },
        })
      alert(status)
    } catch (e) {
      alert(e)
    }


  }
  return (
    <div className={clsx(classes.dropdown)}>
      <div className={clsx(classes.dropdownMain, isActive && classes.active)}>
        <div className={classes.container}>
          <img src={cancel} alt="#" className={classes.img} />
          <div className={classes.textContainer}>
            <h4 className={classes.heading}>Cancel Order</h4>

            <li className={classes.info}>
              Are you sure you want to cancel this order?
            </li>
            <div className={classes.buttonContainer}>
              <Button
                btnGray
                size="sm"
                onClick={() => setIsShowCancelPopup(false)}
              >
                No
              </Button>{" "}
              <Button
                btnWarning
                size="sm"
                onClick={() => {
                  // setCancel(true);
                  // setIsShowCancelPopup(false);
                  cancelOrder(order_id);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelPopup;
//     onClick={() => {
//      el.action();
//      onSelect(el.label);
//}}
