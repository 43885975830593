import React, { useEffect, useState } from "react";
import classes from "./RecentTransaction.module.css";
import Heading from "components/common/Heading/Heading";
import ViewMore from "components/common/ViewMore/ViewMore";
import DataTable from "./DataTable/DataTable";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { useTheme } from "ThemeContext/ThemeContext";
const RecentTransaction = () => {
  const { isDarkTheme } = useTheme();
  const [data, setData] = useState([]);
  let transactions = useSelector((state) => state.transactions);
  console.log(transactions);

  // useEffect(() => {
  //   sortTransactions();
  // }, [transactions])
  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <div className={classes.header}>
        <Heading heading="Recent Deposits" />
        <ViewMore onClick={() => {}} />
      </div>
      <DataTable data={transactions?.deposits} />
    </div>
  );
};

export default RecentTransaction;
