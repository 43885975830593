import React, { useMemo, useState } from "react";

import { parseTickers } from "../../../redux/tickers";
import { useSelector } from "react-redux";
import classes from "./MarketsTable.module.css";

import SingleRow from "./SingleRow";
import { binance, bitcoin, canadaUsd, ethereum, tether } from "assets";
import clsx from "clsx";
import Wrapper from "components/common/Wrapper/Wrapper";
import Pagination from "common/Pagination/Pagination";
import Heading from "components/common/Heading/Heading";
import ViewMore from "components/common/ViewMore/ViewMore";
import { useNavigate } from "react-router-dom";
import { useTheme } from "ThemeContext/ThemeContext";

const MarketsTable = ({ is_verified }) => {
  const { isDarkTheme } = useTheme();
  let parsedTickers = useSelector(parseTickers);

  let deposits = useSelector((state) => state.transactions.deposits);

  let currency = useSelector((state) => state.currencies.currencies);
  let Navigate = useNavigate();

  const tabs = [
    // "Super Hot",
    "Hot Markets",
    "New Listing",
    // "Top gainer",
    // "24H Volume",
  ];

  const [activeTab, setActiveTab] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return parsedTickers.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, parsedTickers, itemsPerPage]);

  return (
    <Wrapper padding="0">
      <div
        className={clsx(
          classes.tableContainer,
          !isDarkTheme && classes.lightTheme
        )}
      >
        <div className={classes.header}>
          <Heading heading="Recent Deposits" />
          <ViewMore
            onClick={() => {
              Navigate("/markets");
            }}
          />
        </div>
        {/* <div className={classes.tabs}>
          {tabs.map((el, i) => (
            <button
              onClick={() => setActiveTab(i)}
              className={[
                classes.tab,
                activeTab === i && classes.activeTab,
              ].join(" ")}
              key={i}
            >
              {el}
            </button>
          ))}
        </div> */}
        <div
          className={clsx(
            classes.marketTable,
            "overflow",
            is_verified > 0 && classes.extraHeight,
            is_verified == 0 && classes.moreExtraHeight
          )}
        >
          {" "}
          <table className={classes.table}>
            <tbody>
              <tr className={classes.row}>
                <th className={clsx(classes.heading, classes.textAlignCenter)}>
                  <p className={classes.tableHeading}>Time</p>
                </th>

                <th className={classes.heading}>
                  <p className={classes.tableHeading}>Network</p>
                </th>

                <th className={classes.heading}>
                  <p className={classes.tableHeading}>Coin</p>
                </th>

                <th className={classes.heading}>
                  <p
                    className={clsx(classes.tableHeading, classes.lastHeading)}
                  >
                    Action
                  </p>
                </th>
              </tr>
              {deposits?.map((el, index) => (
                <SingleRow
                  el={el}
                  key={index}
                  index={index}
                  currency={currency}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className={classes.pagination}>
        <Pagination
          currentPage={currentPage}
          totalCount={parsedTickers.length}
          pageSize={itemsPerPage}
          onPageChange={(page) => setCurrentPage(page)}
          siblingCount={0}
        />
      </div>
    </Wrapper>
  );
};

export default MarketsTable;
