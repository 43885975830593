import React, { useState, useMemo } from "react";
import Pagination from "common/Pagination/Pagination";
import classes from "./DepositOrWithDrawal.module.css";

import clsx from "clsx";
import SingleRow from "./SingleRow/SingleRow";
import { binance, ethereum } from "../../../assets";
const data = [
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    network: "BNB Smart Chain (BEP20)",
    minDeposit: "1.9",
    depositFee: "Free",
    minWithDrawal: "1.9",
    withDrawalFee: "0.003",
  },
  {
    coin: { logo: ethereum, name: "Litecoin", shortName: "LTC" },
    network: "Ethereum (ERC20)",
    minDeposit: "250000",
    depositFee: "Free",
    minWithDrawal: "250000",
    withDrawalFee: "0.003",
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    network: "BNB Smart Chain (BEP20)",
    minDeposit: "1.9",
    depositFee: "Free",
    minWithDrawal: "1.9",
    withDrawalFee: "0.003",
  },
  {
    coin: { logo: ethereum, name: "Litecoin", shortName: "LTC" },
    network: "Ethereum (ERC20)",
    minDeposit: "250000",
    depositFee: "Free",
    minWithDrawal: "250000",
    withDrawalFee: "0.003",
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    network: "BNB Smart Chain (BEP20)",
    minDeposit: "1.9",
    depositFee: "Free",
    minWithDrawal: "1.9",
    withDrawalFee: "0.003",
  },
  {
    coin: { logo: ethereum, name: "Litecoin", shortName: "LTC" },
    network: "Ethereum (ERC20)",
    minDeposit: "250000",
    depositFee: "Free",
    minWithDrawal: "250000",
    withDrawalFee: "0.003",
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    network: "BNB Smart Chain (BEP20)",
    minDeposit: "1.9",
    depositFee: "Free",
    minWithDrawal: "1.9",
    withDrawalFee: "0.003",
  },
  {
    coin: { logo: ethereum, name: "Litecoin", shortName: "LTC" },
    network: "Ethereum (ERC20)",
    minDeposit: "250000",
    depositFee: "Free",
    minWithDrawal: "250000",
    withDrawalFee: "0.003",
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    network: "BNB Smart Chain (BEP20)",
    minDeposit: "1.9",
    depositFee: "Free",
    minWithDrawal: "1.9",
    withDrawalFee: "0.003",
  },
  {
    coin: { logo: ethereum, name: "Litecoin", shortName: "LTC" },
    network: "Ethereum (ERC20)",
    minDeposit: "250000",
    depositFee: "Free",
    minWithDrawal: "250000",
    withDrawalFee: "0.003",
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    network: "BNB Smart Chain (BEP20)",
    minDeposit: "1.9",
    depositFee: "Free",
    minWithDrawal: "1.9",
    withDrawalFee: "0.003",
  },
  {
    coin: { logo: ethereum, name: "Litecoin", shortName: "LTC" },
    network: "Ethereum (ERC20)",
    minDeposit: "250000",
    depositFee: "Free",
    minWithDrawal: "250000",
    withDrawalFee: "0.003",
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    network: "BNB Smart Chain (BEP20)",
    minDeposit: "1.9",
    depositFee: "Free",
    minWithDrawal: "1.9",
    withDrawalFee: "0.003",
  },
  {
    coin: { logo: ethereum, name: "Litecoin", shortName: "LTC" },
    network: "Ethereum (ERC20)",
    minDeposit: "250000",
    depositFee: "Free",
    minWithDrawal: "250000",
    withDrawalFee: "0.003",
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    network: "BNB Smart Chain (BEP20)",
    minDeposit: "1.9",
    depositFee: "Free",
    minWithDrawal: "1.9",
    withDrawalFee: "0.003",
  },
  {
    coin: { logo: ethereum, name: "Litecoin", shortName: "LTC" },
    network: "Ethereum (ERC20)",
    minDeposit: "250000",
    depositFee: "Free",
    minWithDrawal: "250000",
    withDrawalFee: "0.003",
  },
];
const DepositOrWithDrawal = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, itemsPerPage]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <tbody>
            <tr className={classes.row}>
              <th className={classes.heading}>
                <p className={classes.tableHeading}> Coin</p>
              </th>
              <th className={clsx(classes.heading, classes.textAlignCenter)}>
                <p className={classes.tableHeading}>Network</p>
              </th>
              <th className={classes.heading}>
                <p className={classes.tableHeading}>Mini. Deposit</p>
              </th>
              <th className={classes.heading}>
                <p className={classes.tableHeading}>Deposit Fee</p>
              </th>
              <th className={classes.heading}>
                <p className={classes.tableHeading}>Min. Withdrawal</p>
              </th>
              <th className={classes.heading}>
                <p className={clsx(classes.tableHeading, classes.lastHeading)}>
                  Withdrawal Fee
                </p>
              </th>
            </tr>
            {currentTableData?.map((el, index) => (
              <SingleRow {...el} key={index} index={index} />
            ))}
          </tbody>
        </table>{" "}
        <div className={classes.pagination}>
          <Pagination
            currentPage={currentPage}
            totalCount={data?.length}
            pageSize={itemsPerPage}
            onPageChange={(page) => setCurrentPage(page)}
            siblingCount={0}
          />
        </div>
      </div>
    </div>
  );
};

export default DepositOrWithDrawal;
