import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa6";
import classes from "./CommonFaq.module.css";

import Heading from "components/common/Heading/Heading";
import clsx from "clsx";
import { useTheme } from "ThemeContext/ThemeContext";

const CommonFaq = ({ faqs }) => {
  const { isDarkTheme } = useTheme();
  const [activeFaq, setActiveFaq] = useState();

  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <Heading heading="Common FAQ" className={classes.heading} />

      <div className={classes.faqs}>
        {faqs.map((el, i) => (
          <div
            className={clsx(
              classes.faqContainer,
              activeFaq === i && classes.activeFaqContainer
            )}
            key={i}
          >
            <div
              className={classes.questionContainer}
              onClick={() => setActiveFaq(i)}
            >
              <h4 className={classes.question}>{el.question}</h4>
              {activeFaq === i ? (
                <FaMinus className={classes.icon} />
              ) : (
                <FaPlus className={classes.icon} />
              )}
            </div>
            {activeFaq === i && <p className={classes.answer}>{el.answer}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommonFaq;
