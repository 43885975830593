import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import tickers, { parseTickers } from './tickers';
import { createSelector } from 'reselect';
import { ReturnCurrencyPrices } from './currencies';

export const ParseCurrency = (state) => {
    let tickers = useSelector(parseTickers);
    let prices = useSelector(selectCurrencyPrices);
    let currencies = state.currencies.currencies;
    let parsedCurrencies = currencies.map((currency) => {
        let currency_markets = tickers.filter((ticker) => ticker?.base_asset?.toLowerCase() == currency.id || ticker?.quote_asset?.toLowerCase() == currency.id)
        let price = prices.find((price) => price.token == currency.id)
        return {
            ...currency,
            price: price ? price.price : 0,
            markets: currency_markets,
        }
    })


    return 0;
}

// Input selectors
const getMarkets = state => state.markets.markets;
const getBalances = state => state.balances.balances;
const getTickers = state => state.tickers.tickers;

// Memoized selector
export const selectCurrencyPrices = createSelector(
    [getMarkets, getBalances, getTickers], // Input selectors
    (markets, balances, tickers) => {

        const spotBalances = balances.filter(balance => balance.wallet_type == "Spot");
        let tickerMap = new Map(tickers.map(ticker => [ticker.market_id, ticker.last_price]));
        return spotBalances.map(balance => {

            if (balance.currency == "usdt") {
                return {
                    token: "usdt",
                    price: 1
                }
            }

            const currency = balance.currency.toUpperCase();
            const usdtMarket = markets.find(market => market.base_asset === currency && market.quote_asset === "USDT");
            // const ticker = usdtMarket ? tickers.find(ticker => ticker.market_id === usdtMarket.symbol) : null;
            const ticker = usdtMarket ? tickerMap.get(usdtMarket.symbol) : 0;

            return {
                token: balance.currency,
                price: ticker ? ticker : 0
            };
        });
    }
);

// export const ReturnCurrencyPrices = (state) => {
//     let markets = state.markets.markets;
//     let balances = state.balances.balances;
//     let tickers = state.tickers.tickers;
//    

//     const spotBalances = balances.filter(balance => balance.wallet_type === "Spot");

//     const parsedBalances = spotBalances.map(balance => {
//         const currency = balance.currency.toUpperCase();
//         const usdtMarket = markets.find(market => market.base_asset === currency && market.quote_asset === "USDT");

//         const price = usdtMarket ? tickerMap.get(usdtMarket.symbol) : 0;

//         return { token: balance.currency, price: price || 0 }; // Ensure price is a number even if undefined
//     });

//     return parsedBalances
// }

export const fetchCurrencies = createAsyncThunk("fetch-currencies", async (args, {
    rejectWithValue

}) => {

    try {
        let base_url = process.env.REACT_APP_API
        let route = "/currency/currencies"
        let data = await axios.get(base_url + route)
        let currencies = (data.data.data)
        return currencies
    } catch (error) {

        return rejectWithValue(error.response.data)
    }
})


const currenciesSlice = createSlice({
    name: 'tickers',
    initialState: {
        currencies: [],
        currenciesPrices: [],
        isLoading: false,
        isFailed: false
    },
    reducers: {
        updatePrices: (state, action) => {
            state.currenciesPrices = action.payload
        }
    },
    // extra reducer use builder callback
    extraReducers: (builder) => {
        builder.addCase(fetchCurrencies.pending, (state, action) => {
            state.isLoading = true
            state.isFailed = false
        })
        builder.addCase(fetchCurrencies.fulfilled, (state, action) => {
            state.isLoading = false
            state.isFailed = false
            state.currencies = action.payload
        })
        builder.addCase(fetchCurrencies.rejected, (state, action) => {
            state.isLoading = false
            state.isFailed = true
        })
    }

})

export default currenciesSlice.reducer
