import React from "react";

import classes from "./SingleRow.module.css";
import clsx from "clsx";
import { parseTickers } from "../../../../../redux/tickers";
import { useSelector } from "react-redux";
import { useTheme } from "ThemeContext/ThemeContext";
const SingleRow = ({
  pair,
  type,
  side,
  price,
  amount,
  quantity,
  filled,
  total,
  tradeInUsdt,
  timestamp,
  role,
  fee,
}) => {
  let markets = useSelector(parseTickers);
  let market = markets.find((market) => market.market_id === pair);

  let date = new Date(timestamp / 1000).toLocaleString();

  let trades_amount = quantity * price;
  // 0.05% fee
  const { isDarkTheme } = useTheme();
  return (
    <>
      <tr
        className={clsx(
          classes.row,
          classes.valueContainer,
          !isDarkTheme && classes.lightTheme
        )}
      >
        <td className={clsx(classes.item, classes.firstItem)}>{date}</td>
        <td className={clsx(classes.item, classes.pair)}>
          {role?.toUpperCase()}
        </td>
        <td className={classes.item}>{"SPOT"}</td>
        <td
          className={clsx(
            classes.item,
            side.toLowerCase() === "buy" && classes.buy,
            side.toLowerCase() === "sell" && classes.sell
          )}
        >
          {side}
        </td>
        <td className={classes.item}>
          {price} {market?.quote_asset}
        </td>
        <td className={classes.item}>
          {quantity} {market?.base_asset}
        </td>
        <td className={classes.item}>
          {fee} {side == "buy" ? market?.base_asset : market?.quote_asset}
        </td>
        <td className={classes.item}>
          {total} {market?.quote_asset}
        </td>

        {/* <td className={clsx(classes.item, classes.lastItem)}>{tradeInUsdt}</td> */}
      </tr>
    </>
  );
};

export default SingleRow;
