import React, { useEffect, useState } from "react";
import classes from "./Buy.module.css";
import { checkIfNumber } from "components/utils/isNumber";
import Button from "components/Button/Button";
import { useSelector } from "react-redux";
import { parseTickers } from "../../../../redux/tickers";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTheme } from "ThemeContext/ThemeContext";
import clsx from "clsx";

const Buy = ({ onClose, side, activeTab, pair, user, executeOrder }) => {
  const { isDarkTheme } = useTheme();
  const Navigate = useNavigate();
  const [amount, setAMount] = useState("");
  const [price, setPrice] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [activePercentage, setActivePercentage] = useState(0);
  const [quoteAssetBal, setQuoteAssetBal] = useState(0);
  const [baseAssetBal, setBaseAssetBal] = useState(0);

  let markets = useSelector(parseTickers);
  let balances = useSelector((state) => state.balances.balances);
  let market = markets.find((market) => market?.symbol == pair);
  async function ParseBal() {
    let quoteBal = balances.find(
      (bal) =>
        bal?.wallet_type == "Spot" &&
        bal.currency == market?.quote_asset?.toLowerCase()
    );
    setQuoteAssetBal(quoteBal);

    let baseBal = balances?.find(
      (bal) =>
        bal?.wallet_type == "Spot" &&
        bal.currency == market?.base_asset?.toLowerCase()
    );
    setBaseAssetBal(baseBal);
  }

  useEffect(() => {
    ParseBal();
  }, [balances, pair]);

  async function calculateTotal() {
    if (!price || !amount) {
      return;
    }
    let newTotal = amount * price;
    // Assuming you have a way to get the current total to compare
    if (newTotal !== totalAmount) {
      setTotalAmount(newTotal);
    }
  }

  useEffect(() => {
    calculateTotal();
  }, [amount, price]);

  // async function calculateAmount() {
  //   if (!price || !totalAmount) {
  //     return;
  //   }

  //   let amount = totalAmount / price;
  //   setAMount(amount)
  // }

  // useEffect(() => {
  //   calculateAmount()
  // }, [
  //   price, totalAmount
  // ])

  const percentages = [0, 25, 50, 75, 100];

  async function parsePercentage() {
    if (!quoteAssetBal?.balance) {
      return;
    }

    let perc = percentages[activePercentage];

    if (perc == 0) {
      setTotalAmount(0);
    }

    if (side == "buy") {
      let percentage = (perc / 100) * quoteAssetBal.balance;

      setTotalAmount(percentage.toFixed(2));
    }
  }

  useEffect(() => {
    parsePercentage();
  }, [activePercentage]);

  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <div className={classes.inputWrapper}>
        <div className={classes.spaceBetween}>
          <p className={classes.label}>Available</p>
          <p className={classes.value}>
            {user.platform_id
              ? side == "buy"
                ? quoteAssetBal?.balance + " " + market?.quote_asset
                : baseAssetBal?.balance + " " + market?.base_asset
              : "--"}{" "}
          </p>
        </div>
        {activeTab == "Limit" && (
          <div className={classes.inputContainer}>
            <input
              name="usdtAmount"
              id="setUsdtAmount"
              type="number"
              onKeyDown={checkIfNumber}
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder="Price"
              className={classes.input}
            />{" "}
            <p className={classes.currencyName}>{market?.quote_asset}</p>
          </div>
        )}
        <div className={classes.inputContainer}>
          <input
            name="btcAmount"
            id="btcAmount"
            type="number"
            onKeyDown={checkIfNumber}
            value={amount}
            onChange={(e) => setAMount(e.target.value)}
            placeholder="Amount"
            className={classes.input}
          />{" "}
          <p className={classes.currencyName}>{market?.base_asset}</p>
        </div>
      </div>{" "}
      <div className={classes.percentages}>
        {percentages.map((el, i) => (
          <p
            className={[
              classes.percentage,
              i === activePercentage && classes.activePercentage,
            ].join(" ")}
            key={i}
            onClick={() => setActivePercentage(i)}
          >
            {el + "%"}
          </p>
        ))}
      </div>
      <div className={classes.inputContainer}>
        <input
          name="totalAmount"
          id="totalAmount"
          type="number"
          onKeyDown={checkIfNumber}
          value={Number(totalAmount).toPrecision(8)}
          onChange={(e) => setTotalAmount(e.target.value)}
          placeholder="Total"
          className={classes.input}
        />{" "}
        <p className={classes.currencyName}>USDT</p>
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.spaceBetween}>
          <p className={classes.label}>Max. Buy</p>
          <p className={classes.value}>0.00 USDT</p>
        </div>{" "}
        <div className={classes.spaceBetween}>
          <p className={classes.label}>Fee</p>
          <p className={classes.value}>0.00 USD</p>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          btnGray
          size="md"
          wFull
          className={classes.cancelButton}
          onClick={onClose}
        >
          Cancel
        </Button>{" "}
        {side == "buy" && (
          <Button
            btnGreen
            size="md"
            wFull
            onClick={() => {
              if (!user.platform_id) {
                Navigate("/login");
              }

              let direction = "Bid";
              executeOrder(direction, amount, price, pair);
              // onClose();
            }}
          >
            {user.platform_id ? "Buy" : "Login to trade"}
          </Button>
        )}
        {side == "sell" && (
          <Button
            btnWarning
            size="md"
            wFull
            onClick={() => {
              if (!user.platform_id) {
                Navigate("/login");
              }
              let direction = "Ask";
              executeOrder(direction, amount, price, pair);
              // onClose();
            }}
          >
            {user.platform_id ? "Sell" : "Login to trade"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Buy;
