import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";
import { BsStarFill } from "react-icons/bs";
import classes from "./Pairs.module.css";
import Currencies from "./Currencies/Currencies";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { parseTickers } from "../../../redux/tickers";
import { useTheme } from "ThemeContext/ThemeContext";

const Pairs = ({ setPair }) => {
  const { isDarkTheme } = useTheme();
  const [currency, setCurrency] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);
  const [favorites, setFavorites] = useState(new Set());

  let markets = useSelector((state) => state.markets.markets);
  let tickers = useSelector(parseTickers);

  let market_pairs = new Set();
  markets.forEach((market) => {
    market_pairs.add(market.quote_asset);
  });

  async function changePair(e) {
    setPair(e);
    window.history.pushState({}, "", `/spot/${e}`);
  }

  const toggleFavorite = (pair) => {
    const updatedFavorites = new Set(favorites);
    if (favorites.has(pair)) {
      updatedFavorites.delete(pair);
    } else {
      updatedFavorites.add(pair);
    }
    setFavorites(updatedFavorites);
  };

  const isFavorite = (pair) => {
    return favorites.has(pair);
  };

  const filteredData = searchValue
    ? tickers?.filter((item) =>
        item?.market_id?.toLowerCase()?.includes(searchValue)
      )
    : currency
    ? tickers?.filter((item) => item?.market_id?.includes(currency))
    : showOnlyFavorites
    ? tickers?.filter((el) => isFavorite(el?.market_id))
    : tickers;

  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <div className={classes.inputContainer}>
        <FiSearch className={classes.searchIcon} />
        <input
          type="text"
          placeholder="Search"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          className={classes.input}
        />
      </div>
      <Currencies
        setShowOnlyFavorites={setShowOnlyFavorites}
        setSearchValue={setSearchValue}
        currency={currency}
        setCurrency={setCurrency}
      />
      <div className={classes.container}>
        <div className={clsx(classes.valueContainer, "overflow")}>
          {filteredData.map((el, i) => (
            <div className={classes.valueWrapper} key={i}>
              <div className={clsx(classes.text, classes.pairWithContainer)}>
                <BsStarFill
                  className={clsx(classes.star, {
                    [classes.favorite]: isFavorite(el.market_id),
                  })}
                  onClick={() => toggleFavorite(el.market_id)}
                />
                <p
                  onClick={() => {
                    changePair(el?.market_id);
                  }}
                >
                  <span className={classes.text}>{el?.base_asset}</span>
                  <span className={[classes.text, classes.pairWith].join(" ")}>
                    /{el?.quote_asset}
                  </span>
                </p>
                <span className={classes.growth}>{el?.growth}</span>
                {el?.new && <span className={classes.new}>NEW</span>}
              </div>
              <p
                onClick={() => {
                  changePair(el?.market_id);
                }}
                className={[
                  classes.text,
                  classes.price,
                  classes.textRight,
                ].join(" ")}
              >
                ${el?.last_price?.toFixed(2)}
              </p>
              <p
                onClick={() => {
                  changePair(el?.market_id);
                }}
                className={[
                  classes.text,
                  classes.price,
                  classes.textRight,
                  classes.green,
                  el?.change > 0
                    ? classes.green
                    : el.change < 0
                    ? classes.red
                    : "",
                ].join(" ")}
              >
                {el.change > 0 ? "+" : "-"}
                {Math.abs(el?.change?.toFixed(2))}%
                {el.change > 0 ? (
                  <BiSolidUpArrow
                    className={clsx(classes.upArrow, classes.positiveArrow)}
                  />
                ) : (
                  <BiSolidDownArrow
                    className={clsx(classes.downArrow, classes.negativeArrow)}
                  />
                )}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pairs;
