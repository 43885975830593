import React from "react";
import classes from "./FeeStructure.module.css";
import { feeStructure } from "assets";
import Button from "components/Button/Button";

const FeeStructure = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.textContainer}>
        <h2 className={classes.heading}>
          Brandname
          <span className={classes.highlight}> Fees Structure</span>
        </h2>
        <p className={classes.text}>
          Our platform offers a seamless and secure environment for users to
          buy, sell, and trade a wide range of digital assets, from popular
          cryptocurrencies like Bitcoin and Ethereum to emerging tokens and
          altcoins.
        </p>
        <Button
          size="md"
          btnPrimary
          onClick={() => {}}
          className={classes.button}
        >
          Get Started
        </Button>
      </div>
      <div className={classes.imgContainer}>
        <img src={feeStructure} alt="#" className={classes.img} />
      </div>
    </div>
  );
};

export default FeeStructure;
