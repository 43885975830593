import { useState } from "react";

import Hr from "components/common/Hr/Hr";
import Button from "components/Button/Button";
import { qr, AppStore, PlayStore } from "assets";
import classes from "./QrLogin.module.css";
import ScanCode from "modals/ScanCode/ScanCode";
import { useTheme } from "ThemeContext/ThemeContext";
import clsx from "clsx";
import { toast } from "react-toastify";

const QrLogin = () => {
  const [isQrModalActive, setIsQrModalActive] = useState(false);

  const { isDarkTheme } = useTheme();

  return (
    <>
      <ScanCode
        isActive={isQrModalActive}
        onClose={() => setIsQrModalActive(false)}
      />
      <div
        className={clsx(classes.qrLogin, !isDarkTheme && classes.lightTheme)}
      >
        <h2>Login with QR Code</h2>
        <p>
          Open your mobile app and go to settings &gt; login through app &gt;
          add new login
        </p>

        <Hr shortMargin />

        <div className={classes.download}>
          <div className={classes.left}>
            <img src={qr} alt="qr" />
            <button
              className={classes.btn}
              onClick={() => {
                // alert("QR code will be enabled on app launch");
                toast.error("QR code will be enabled on App launch", {
                  position: "top-center",
                  theme: "dark",
                });
                // setIsQrModalActive(true)
              }}
            >
              Generate QR Code
            </button>
          </div>
          <div className={classes.right}>
            <h5>Download app</h5>

            <div className={classes.buttons}>
              <Button
                onClick={() => {
                  alert("iOS app will be available soon");
                  // setIsQrModalActive(true)
                }}
                btnMoreBlack
                size="lg"
              >
                {/* <img src={appStore} alt="app store" /> */}
                <AppStore color={isDarkTheme ? "#fff" : "#000"} />
                <div>
                  <h6>Download from the</h6>
                  <h5>App Store</h5>
                </div>
              </Button>
              <Button
                onClick={() => {
                  alert("Android app will be available soon");
                  // setIsQrModalActive(true)
                }}
                btnMoreBlack
                size="lg"
              >
                {/* <img src={playStore} alt="play store" /> */}
                {<PlayStore color={isDarkTheme ? "#fff" : "#000"} />}
                <div>
                  <h6>GET IT ON</h6>
                  <h5>Google Play</h5>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QrLogin;
