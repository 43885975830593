import React, { useState } from "react";
import classes from "./SelectCoin.module.css";

import Dropdown from "../../Deposit/DepositCrypto/Dropdown/Dropdown";

const SelectCoin = ({ selectedCoin, setSelectedCoin, dropdownItems }) => {
  return (
    <div className={classes.wrapper}>
      <p className={classes.label}>Select Coin</p>

      <Dropdown
        borderNone
        selectedOption={selectedCoin}
        setSelectedOption={setSelectedCoin}
        uniqueKey="select-coin"
        pattern1
        placeholder="Select Coin"
        options={dropdownItems}
        onSelect={(val) => {
          setSelectedCoin(val);
        }}
      />
    </div>
  );
};

export default SelectCoin;
