import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa6";

import classes from "./Faqs.module.css";
import clsx from "clsx";
const faqs = [
  {
    question: "How do I buy cryptocurrency on the platform?",
    answer:
      "To buy cryptocurrency, navigate to the trading section of the platform, select the desired cryptocurrency pair, specify the amount you wish to purchase, and complete the transaction using your preferred payment method.",
  },
  {
    question: "What cryptocurrencies can I trade on the platform?",
    answer:
      "To buy cryptocurrency, navigate to the trading section of the platform, select the desired cryptocurrency pair, specify the amount you wish to purchase, and complete the transaction using your preferred payment method.",
  },
  {
    question: "How do I secure my account and digital assets?",
    answer:
      "To buy cryptocurrency, navigate to the trading section of the platform, select the desired cryptocurrency pair, specify the amount you wish to purchase, and complete the transaction using your preferred payment method.",
  },
  {
    question: "How can I learn more about cryptocurrency trading strategies?",
    answer:
      "To buy cryptocurrency, navigate to the trading section of the platform, select the desired cryptocurrency pair, specify the amount you wish to purchase, and complete the transaction using your preferred payment method.",
  },
  {
    question: "Can I access the platform from my mobile device?",
    answer:
      "To buy cryptocurrency, navigate to the trading section of the platform, select the desired cryptocurrency pair, specify the amount you wish to purchase, and complete the transaction using your preferred payment method.",
  },
];
const Faqs = () => {
  const [activeFaq, setActiveFaq] = useState();
  return (
    <div className={classes.wrapper}>
      <h2 className={classes.heading}>
        Frequently Asked
        <span className={classes.highlight}> Questions </span>
      </h2>
      <div className={classes.container}>
        {faqs.map((el, i) => (
          <div
            className={clsx(
              classes.faqContainer,
              activeFaq === i && classes.activeFaqContainer
            )}
            key={i}
          >
            <div
              className={classes.questionContainer}
              onClick={() => setActiveFaq(i)}
            >
              <h4 className={classes.question}>{el.question}</h4>
              {activeFaq === i ? (
                <FaMinus className={classes.icon} />
              ) : (
                <FaPlus className={classes.icon} />
              )}
            </div>
            {activeFaq === i && <p className={classes.answer}>{el.answer}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faqs;
