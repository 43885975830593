import React, { useRef, useState, useMemo } from "react";
import Pagination from "common/Pagination/Pagination";
import classes from "./OverviewTable.module.css";

import { magnifier, more, binance, bitcoin, ethereum, tether } from "assets";
import clsx from "clsx";
import SingleRow from "./SingleRow/SingleRow";
import { useTheme } from "ThemeContext/ThemeContext";
const markets = [
  {
    coin: { logo: bitcoin, name: "Bitcoin", shortName: "BTC" },

    price: "$41,845.8",

    change24h: -10.2,
    marketCap: "$2.64B",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: tether, name: "Tether", shortName: "USD" },

    price: "$67.43",

    change24h: 10.2,

    marketCap: "$969.16M",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: ethereum, name: "Ethereum", shortName: "ETH" },

    price: "$9.95",

    change24h: -10.2,

    marketCap: "$948.28M",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },

    price: "$303.47",

    change24h: -10.2,
    marketCap: "$867.03M",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: bitcoin, name: "Bitcoin", shortName: "BTC" },

    price: "$41,845.8",

    change24h: -10.2,
    marketCap: "$2.64B",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: tether, name: "Tether", shortName: "USD" },

    price: "$67.43",

    change24h: 10.2,

    marketCap: "$969.16M",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: ethereum, name: "Ethereum", shortName: "ETH" },

    price: "$9.95",

    change24h: -10.2,

    marketCap: "$948.28M",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },

    price: "$303.47",

    change24h: -10.2,
    marketCap: "$867.03M",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: bitcoin, name: "Bitcoin", shortName: "BTC" },

    price: "$41,845.8",

    change24h: -10.2,
    marketCap: "$2.64B",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: tether, name: "Tether", shortName: "USD" },

    price: "$67.43",

    change24h: 10.2,

    marketCap: "$969.16M",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: ethereum, name: "Ethereum", shortName: "ETH" },

    price: "$9.95",

    change24h: -10.2,

    marketCap: "$948.28M",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },

    price: "$303.47",

    change24h: -10.2,
    marketCap: "$867.03M",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: bitcoin, name: "Bitcoin", shortName: "BTC" },

    price: "$41,845.8",

    change24h: -10.2,
    marketCap: "$2.64B",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: tether, name: "Tether", shortName: "USD" },

    price: "$67.43",

    change24h: 10.2,

    marketCap: "$969.16M",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: ethereum, name: "Ethereum", shortName: "ETH" },

    price: "$9.95",

    change24h: -10.2,

    marketCap: "$948.28M",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },

    price: "$303.47",

    change24h: -10.2,
    marketCap: "$867.03M",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: bitcoin, name: "Bitcoin", shortName: "BTC" },

    price: "$41,845.8",

    change24h: -10.2,
    marketCap: "$2.64B",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: tether, name: "Tether", shortName: "USD" },

    price: "$67.43",

    change24h: 10.2,

    marketCap: "$969.16M",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: ethereum, name: "Ethereum", shortName: "ETH" },

    price: "$9.95",

    change24h: -10.2,

    marketCap: "$948.28M",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },

    price: "$303.47",

    change24h: -10.2,
    marketCap: "$867.03M",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: bitcoin, name: "Bitcoin", shortName: "BTC" },

    price: "$41,845.8",

    change24h: -10.2,
    marketCap: "$2.64B",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: tether, name: "Tether", shortName: "USD" },

    price: "$67.43",

    change24h: 10.2,

    marketCap: "$969.16M",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: ethereum, name: "Ethereum", shortName: "ETH" },

    price: "$9.95",

    change24h: -10.2,

    marketCap: "$948.28M",
    action: {
      detaisl: "",
      trade: "",
    },
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },

    price: "$303.47",

    change24h: -10.2,
    marketCap: "$867.03M",
    action: {
      detaisl: "",
      trade: "",
    },
  },
];
const OverviewTable = ({ parsedTickers, currency }) => {
  const { isDarkTheme } = useTheme();
  const tabs = ["Spot Markets"];
  const filteringItems = ["All"];
  const [activeTab, setActiveTab] = useState(1);
  const [activeFilterTab, setActiveFilterTab] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return markets.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, markets, itemsPerPage]);

  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <div className={classes.header}>
        <div className={classes.tabs}>
          {tabs.map((el, i) => (
            <p
              className={clsx(
                classes.tab,
                i === activeTab && classes.activeTab
              )}
              key={i}
              onClick={() => setActiveTab(i)}
            >
              {el}
            </p>
          ))}
        </div>

        <div className={classes.searchContainer}>
          <img src={magnifier} alt="magnifier" className={classes.icon} />
          <input
            type="search"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </div>

      <div className={classes.filteringContainer}>
        {filteringItems.map((el, i) => (
          <div
            key={i}
            className={clsx(
              classes.filterTab,
              i === activeFilterTab && classes.activeFilterTab
            )}
            onClick={() => setActiveFilterTab(i)}
          >
            <p>{el}</p>
          </div>
        ))}
        <div className={classes.more}>
          <img src={more} alt="" />
        </div>
      </div>

      <div className={classes.marketTable}>
        {" "}
        <table className={classes.table}>
          <tbody>
            <tr className={classes.row}>
              <th className={clsx(classes.heading, classes.textAlignCenter)}>
                <p className={classes.tableHeading}>Coin</p>
              </th>
              <th className={classes.heading}>
                <p className={classes.tableHeading}>Price</p>
              </th>
              <th className={classes.heading}>
                <p className={classes.tableHeading}>24H Changes</p>
              </th>
              <th className={classes.heading}>
                <p className={classes.tableHeading}> Market Cap</p>
              </th>

              <th className={classes.heading}>
                <p className={clsx(classes.tableHeading, classes.lastHeading)}>
                  Action
                </p>
              </th>
            </tr>
            {parsedTickers?.map((el, index) => (
              <SingleRow
                {...el}
                key={index}
                index={index}
                currency={currency}
              />
            ))}
          </tbody>
        </table>{" "}
        <div className={classes.pagination}>
          <Pagination
            currentPage={currentPage}
            totalCount={parsedTickers?.length}
            pageSize={itemsPerPage}
            onPageChange={(page) => setCurrentPage(page)}
            siblingCount={0}
          />
        </div>
      </div>
    </div>
  );
};

export default OverviewTable;
