import React, { useEffect, useState } from "react";
import classes from "./WalletOverview.module.css";
import Heading from "components/common/Heading/Heading";
import clsx from "clsx";
import { useTheme } from "../../../ThemeContext/ThemeContext";
import { bitcoin, nexo, spot, tether } from "assets";
import FilterButton from "components/Wallet/FilterButton/FilterButton";
import BuyCrypto from "components/Wallet/WalletOverview/BuyAndDepositCrypto/BuyCrypto";
import DepositCrypto from "components/Wallet/WalletOverview/BuyAndDepositCrypto/DepositCrypto";
import RecentTransaction from "components/Wallet/WalletOverview/RecentTransaction/RecentTransaction";
import WalletBalanceTable from "components/Wallet/WalletOverview/WalletBalance/WalletBalanceTable";
import CryptoAssetsTable from "components/Wallet/WalletOverview/CryptoAssets/CryptoAssetsTable";
import { useSelector } from "react-redux";
import { selectCurrencyPrices } from "../../../redux/currencies";
const data = [
  {
    wallet: { logo: spot, name: "Spot" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
  {
    wallet: { logo: spot, name: "Spot" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
  {
    wallet: { logo: spot, name: "Spot" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
  {
    wallet: { logo: spot, name: "Spot" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
];
const data2 = [
  {
    coin: { logo: nexo, symbol: "NEXO", name: "Nexo" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
  {
    coin: { logo: nexo, symbol: "NEXO", name: "Nexo" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
  {
    coin: { logo: nexo, symbol: "NEXO", name: "Nexo" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
  {
    coin: { logo: nexo, symbol: "NEXO", name: "Nexo" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
  {
    coin: { logo: nexo, symbol: "NEXO", name: "Nexo" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
  {
    coin: { logo: nexo, symbol: "NEXO", name: "Nexo" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
  {
    coin: { logo: nexo, symbol: "NEXO", name: "Nexo" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
  {
    coin: { logo: nexo, symbol: "NEXO", name: "Nexo" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
  {
    coin: { logo: nexo, symbol: "NEXO", name: "Nexo" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
  {
    coin: { logo: nexo, symbol: "NEXO", name: "Nexo" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
  {
    coin: { logo: nexo, symbol: "NEXO", name: "Nexo" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
  {
    coin: { logo: nexo, symbol: "NEXO", name: "Nexo" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
  {
    coin: { logo: nexo, symbol: "NEXO", name: "Nexo" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
  {
    coin: { logo: nexo, symbol: "NEXO", name: "Nexo" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
  {
    coin: { logo: nexo, symbol: "NEXO", name: "Nexo" },
    amount: 0.0,
    usdAmount: 0.0,
    available: 0.0,
    action: "",
  },
];
const WalletOverview = () => {
  const { isDarkTheme } = useTheme();
  const tabs = ["Wallet Overview"];
  let balances = useSelector((state) => state.balances.balances);
  let currency_prices = useSelector(selectCurrencyPrices);

  const [walletBalances, setWalletBalances] = useState([]);

  // {
  //   wallet: { logo: spot, name: "Spot" },
  //   amount: 0.0,
  //   usdAmount: 0.0,
  //   available: 0.0,
  //   action: "",
  // },
  async function sort_balances_by_wallet_type(balances) {
    let wallet_balances = {};
    for (let i = 0; i < balances.length; i++) {
      let balance = balances[i];
      let wallet_type = balance.wallet_type;
      if (wallet_type.toLowerCase() == "margin") {
        continue;
      }


      if (wallet_type in wallet_balances) {
        wallet_balances[wallet_type].push(balance);
      } else {
        wallet_balances[wallet_type] = [balance];
      }
    }
    let formatted_wallet_balances = [];

    for (let wallet_type in wallet_balances) {
      let wallet_type_balances = wallet_balances[wallet_type];
      let total_usd = 0;
      let total_amount = 0;
      let total_available = 0;
      for (let i = 0; i < wallet_type_balances.length; i++) {
        let balance = wallet_type_balances[i];
        let currency = balance.currency;
        let currency_price = currency_prices.find(
          (c) => c.token == currency
        ).price;

        let usd_amount =
          (balance.balance + balance.frozen_balance) * currency_price;
        total_usd += usd_amount;
        total_amount += balance.balance;
        total_available += balance.balance;
      }
      let formatted_wallet_type_balance = {
        wallet: { logo: spot, name: wallet_type },
        amount: total_amount,
        usdAmount: total_usd,
        available: total_available,
        action: "",
      };
      formatted_wallet_balances.push(formatted_wallet_type_balance);
    }

    setWalletBalances(formatted_wallet_balances);
  }

  useEffect(() => {
    sort_balances_by_wallet_type(balances);
  }, [balances]);

  const [activeTab, setActiveTab] = useState("Wallet Overview");
  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <Heading heading="Overview" border />
      <div className={classes.container}>
        <div className={classes.header}>
          <Heading heading="Wallet Overview" />

          <div className={classes.filterContainer}>
            {/* <div className={classes.tabs}>
              {tabs.map((tab, i) => (
                <p
                  className={clsx(
                    classes.tab,
                    tab.toLowerCase() === activeTab.toLowerCase() &&
                    classes.activeTab
                  )}
                  key={i}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </p>
              ))}
            </div>{" "} */}
            <div className={classes.buttonContainer}>
              {/* <FilterButton onClick={() => { }}>Fiat</FilterButton>{" "} */}
              {/* <FilterButton onClick={() => { }}>Hide 0 Balance</FilterButton> */}
            </div>
          </div>
        </div>
        {activeTab === "Wallet Overview" && (
          <WalletBalanceTable data={walletBalances} />
        )}
        {activeTab === "Crypto Assets" && <CryptoAssetsTable data={data2} />}
      </div>{" "}
      {/* <div className={classes.buyAndDepositCrypto}>
        <DepositCrypto />
        <BuyCrypto />
      </div> */}
      <RecentTransaction />
    </div>
  );
};

export default WalletOverview;
