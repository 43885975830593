import classes from "./Table.module.css";
import { magnifier, binance } from "assets";
import clsx from "clsx";

import FinishedStaking from "./ClaimedTransaction/ClaimedTransaction";
import LiveAndFinished from "./LiveAndFinished/LiveAndFinished";
import ClaimedTransaction from "./ClaimedTransaction/ClaimedTransaction";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "ThemeContext/ThemeContext";

const claimedTransaction = [
  {
    date: "02-04 13:45:54",
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    minStaking: "10",
    maxStaking: "10,000",
    apy: "5.86%",
    duration: "30 days",
    status: "Finished",
  },
  {
    date: "02-04 13:45:54",
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    minStaking: "10",
    maxStaking: "10,000",
    apy: "5.86%",
    duration: "30 days",
    status: "Live",
  },
  {
    date: "02-04 13:45:54",
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    minStaking: "10",
    maxStaking: "10,000",
    apy: "5.86%",
    duration: "30 days",
    status: "Finished",
  },
  {
    date: "02-04 13:45:54",
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    minStaking: "10",
    maxStaking: "10,000",
    apy: "5.86%",
    duration: "30 days",
    status: "Live",
  },
  {
    date: "02-04 13:45:54",
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    minStaking: "10",
    maxStaking: "10,000",
    apy: "5.86%",
    duration: "30 days",
    status: "Finished",
  },
  {
    date: "02-04 13:45:54",
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    minStaking: "10",
    maxStaking: "10,000",
    apy: "5.86%",
    duration: "30 days",
    status: "Live",
  },
  {
    date: "02-04 13:45:54",
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    minStaking: "10",
    maxStaking: "10,000",
    apy: "5.86%",
    duration: "30 days",
    status: "Finished",
  },
  {
    date: "02-04 13:45:54",
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    minStaking: "10",
    maxStaking: "10,000",
    apy: "5.86%",
    duration: "30 days",
    status: "Live",
  },
];
const Tables = () => {
  const { isDarkTheme } = useTheme();
  const tabs = [
    "Live",
    "Finished",
    // "Claimed Transaction"
  ];
  const [activeTab, setActiveTab] = useState("Live");

  let stakes = useSelector((state) => state.stakingInstances.mystakes);
  let stakeInstances = useSelector(
    (state) => state.stakingInstances.stakingInstances
  );
  console.log(stakes);

  const [searchValue, setSearchValue] = useState("");
  const liveStakes = stakes?.filter((item) => item.is_finished === false);

  const finishedStakes = stakes?.filter((item) => item.is_finished === true);

  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <div className={classes.header}>
        <div className={classes.tabs}>
          {tabs.map((tab, i) => (
            <p
              className={clsx(
                classes.tab,
                tab.toLowerCase() === activeTab.toLowerCase() &&
                  classes.activeTab
              )}
              key={i}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </p>
          ))}
        </div>

        <div className={classes.searchContainer}>
          <img src={magnifier} alt="magnifier" className={classes.icon} />
          <input
            type="search"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </div>
      {activeTab === "Live" && (
        <LiveAndFinished data={liveStakes} stakeInstances={stakeInstances} />
      )}
      {activeTab === "Finished" && (
        <LiveAndFinished
          finished
          data={finishedStakes}
          stakeInstances={stakeInstances}
        />
      )}
      {activeTab === "Claimed Transaction" && (
        <ClaimedTransaction data={claimedTransaction} />
      )}
    </div>
  );
};

export default Tables;
