import React from "react";
import clsx from "clsx";

import classes from "./NotificationDropdown.module.css";
import { loginedIcon, announcement, notification } from "assets";
import ViewMore from "components/common/ViewMore/ViewMore";
import { useTheme } from "../../ThemeContext/ThemeContext";

const NotificationDropdown = ({ isActive, children }) => {
  const { isDarkTheme } = useTheme();
  const items = [
    {
      icon: loginedIcon,
      label: "Account Login",
      info: "You have logged in your account from Windows Chrome 120.",
      date: "26 Apr",
    },
    {
      icon: announcement,
      label: "Announcement",
      info: "Complete Tasks in the Welcome Back Challenge & Join the Trading Competition",
      date: "10 Apr",
    },
    {
      icon: notification,
      label: "System Message",
      info: "Start your trading journey on Binance your account from Windows Chrome 120.",
      date: "04 Feb",
    },
    {
      icon: loginedIcon,
      label: "Account Login",
      info: "You have logged in your account from Windows Chrome 120.",
      date: "26 Apr",
    },
    {
      icon: announcement,
      label: "Announcement",
      info: "Complete Tasks in the Welcome Back Challenge & Join the Trading Competition",
      date: "10 Apr",
    },
    {
      icon: notification,
      label: "System Message",
      info: "Start your trading journey on Binance your account from Windows Chrome 120.",
      date: "04 Feb",
    },
    {
      icon: loginedIcon,
      label: "Account Login",
      info: "You have logged in your account from Windows Chrome 120.",
      date: "26 Apr",
    },
    {
      icon: announcement,
      label: "Announcement",
      info: "Complete Tasks in the Welcome Back Challenge & Join the Trading Competition",
      date: "10 Apr",
    },
    {
      icon: notification,
      label: "System Message",
      info: "Start your trading journey on Binance your account from Windows Chrome 120.",
      date: "04 Feb",
    },
  ];
  return (
    <div className={clsx(classes.dropdown, !isDarkTheme && classes.lightTheme)}>
      {children}
      <div className={clsx(classes.dropdownMain, isActive && classes.active)}>
        <div className={classes.header}>
          <h3 className={classes.heading}>Notifications</h3>
          <ViewMore onClick={() => {}} />
        </div>
        <div className={classes.listContainer}>
          {items.map((el, i) => (
            <div className={classes.list} key={i}>
              <div className={classes.iconContainer}>
                <img src={el.icon} alt="#" />
              </div>
              <div className={classes.labeAndInfo}>
                <p className={classes.label}>{el.label}</p>
                <p className={classes.info}>
                  {" "}
                  <span>{el.info.slice(0, 24)}...</span>
                </p>
              </div>
              <span className={classes.date}>{el.date}</span>
            </div>
          ))}
        </div>{" "}
      </div>
    </div>
  );
};

export default NotificationDropdown;
