import React from "react";
import classes from "./CryptoJourney.module.css";
import { cryptoJourney } from "assets";
import Button from "components/Button/Button";

const CryptoJourney = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.textContainer}>
        <h2 className={classes.heading}>
          Start Your <span className={classes.highlight}>Crypto Journey</span>{" "}
          Today
        </h2>
        <p className={classes.text}>
          Seamlessly Buy, Sell, and Trade Cryptocurrency with Confidence
        </p>
        <Button
          size="md"
          btnPrimary
          onClick={() => {
            window.location.href = "/login";
          }}
          className={classes.button}
        >
          Trade Now
        </Button>
      </div>
      <img src={cryptoJourney} alt="#" className={classes.img} />
    </div>
  );
};

export default CryptoJourney;
