import clsx from "clsx";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import classes from "./Dropdown.module.css";
import { useTheme } from "ThemeContext/ThemeContext";

const Dropdown = ({
  isActive,
  selectedValue,
  onSelect,
  isDropdownActive,
  children,
  dropdownItems,
  onClick,
}) => {
  const { isDarkTheme } = useTheme();
  return (
    <div
      className={clsx(classes.dropdown, !isDarkTheme && classes.lightTheme)}
      onClick={onClick}
    >
      <p className={classes.selectedValue}>
        <img
          src={
            selectedValue?.logo
              ? selectedValue.logo
              : "https://cdn-icons-png.flaticon.com/128/9347/9347217.png"
          }
          alt="#"
          className={classes.logo}
        />{" "}
        {selectedValue?.name ? selectedValue?.name : "Select currency"}
      </p>{" "}
      {children}
      {isDropdownActive ? (
        <FaAngleUp className={classes.icon} />
      ) : (
        <FaAngleDown className={classes.icon} />
      )}
      <div className={clsx(classes.dropdownMain, isActive && classes.active)}>
        <div className={classes.list}>
          {dropdownItems.map((el, idx) => {
            return (
              <div
                key={"lang-list-item-" + idx}
                className={clsx(
                  classes.listItem,
                  selectedValue === el?.name && classes.active
                )}
                onClick={() => {
                  onSelect(el);
                }}
              >
                <img src={el?.logo} alt="#" className={classes.logo} />
                {el?.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
