import React from "react";

import classes from "./SingleRow.module.css";
import clsx from "clsx";
import Button from "components/Button/Button";
import CopyToClipboard from "react-copy-to-clipboard";
import { copy } from "assets";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Cookies from "js-cookie";
import { fetchBalance } from "../../../../../redux/balance";
import { toast } from "react-toastify";
import { getMyStakes } from "../../../../../redux/stakingInstances";

const SingleRow = ({
  staking_instance_id,
  coin,
  id,
  amountStaked,
  amountEarned,
  amount,
  status,
  is_finished,
  actions,
  pending_rewards,
  stakeInstances,
}) => {




  let instance = stakeInstances?.find((instance) => instance?.id == staking_instance_id)
  let currencies = useSelector((state) => state.currencies.currencies);
  let currency = currencies.find((curr) => curr?.id == instance?.token);

  let dispatch = useDispatch();
  async function claimRewards() {
    let api = process.env.REACT_APP_API;
    let route = "/staking/claim/" + id
    toast.loading("Claiming rewards", {
      toastId: "claiming-rewards",
      theme: "dark",
      position: "top-center"
    });
    try {
      let { data, status } = await axios.get(api + route, {
        headers: {
          "Authorization": "Bearer " + Cookies.get("auth-token")
        }
      })


      if (status == 200) {
        toast.success("Rewards claimed successfully", {
          position: "top-center",
          theme: "dark",
          toastId: "rewards-claimed"

        })
        toast.dismiss("claiming-rewards");
        dispatch(fetchBalance());
        dispatch(getMyStakes())
      }
    } catch (e) {

    }
  }


  async function unstake() {
    let api = process.env.REACT_APP_API;
    let route = "/staking/unstake/" + id

    try {
      let { data, status } = await axios.get(api + route, {
        headers: {
          "Authorization": "Bearer " + Cookies.get("auth-token")
        }
      })

      console.log(data, status)
      if (status == 200) {
        dispatch(fetchBalance());

        toast.success("Unstaked successfully", {
          position: "top-center",
          theme: "dark",
          toastId: "unstaked-success"
        })

        setTimeout(() => {
          window.location.reload();
        }, 3000)
      }
    } catch (e) {
      toast.error("Error occured", {
        position: "top-center",
        theme: "dark",
        toastId: "error-occured"
      })
    }
  }
  return (
    <>
      <tr className={clsx(classes.row, classes.valueContainer)}>
        <td className={clsx(classes.item, classes.firstItem)}>
          <div className={classes.logoAndNameContainer}>
            <img src={currency?.logo} alt="#" className={classes.logo} />
            <div className={classes.nameContainer}>
              <p>{currency?.name?.toUpperCase()}</p>
              <span>{staking_instance_id}</span>
            </div>
          </div>
        </td>

        <td className={classes.item}>
          <div className={classes.stakeId}>
            {id}{" "}
            <CopyToClipboard text={staking_instance_id} >
              <img src={copy} alt="#" className={classes.copyIcon} />
            </CopyToClipboard>
          </div>
        </td>
        <td className={clsx(classes.item, classes.amount)}>{amount}  {currency?.symbol?.toUpperCase()}</td>
        {!is_finished && <td className={clsx(classes.item, classes.amount)}>{pending_rewards}</td>}
        <td className={clsx(classes.item)}>
          <p
            className={clsx(
              is_finished && classes.lastItem,
              // 1 == 1 && classes.live,
              !is_finished && classes.finished,
              is_finished && classes.live
            )}
          >
            {" "}
            {is_finished ? "Finished" : "Live"}
          </p>
        </td>


        {!is_finished && (
          <td className={classes.item}>
            <div className={classes.buttonContainer}>
              {pending_rewards > 0 && <Button btnGray size="sm" onClick={() => {
                claimRewards();
              }}>
                Claim
              </Button>}
              <Button btnGray size="sm" onClick={() => {
                unstake();
              }}>
                Unstake
              </Button>
            </div>
          </td>
        )}
      </tr>
    </>
  );
};

export default SingleRow;
