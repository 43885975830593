import React, { useState, useMemo, useRef, useEffect } from "react";

import clsx from "clsx";
import SingleRow from "./SingleRow/SingleRow";
import Pagination from "common/Pagination/Pagination";
import classes from "./TransactionHistory.module.css";
import Dropdown from "../Dropdown/Dropdown";
import { useSelector } from "react-redux";
import { useTheme } from "ThemeContext/ThemeContext";
const typeDropDownItems = [
  {
    label: "All",
  },
  {
    label: "Limit",
  },
  {
    label: "Market",
  },
  {
    label: "Trailing Stop",
  },
  {
    label: "Stop Limit",
  },
  {
    label: "Stop Market",
  },
  {
    label: "OCO",
  },
];
const coinDropDownItems = [
  { label: "All" },
  { label: "Buy" },
  { label: "Sell" },
];
const timeDropdownItems = [
  { label: "All" },
  { label: "Time" },
  { label: "Time" },
];
const walletDropdownItems = [{ label: "Spot" }, { label: "Funding" }];

const TransactionHistory = ({ data }) => {
  const { isDarkTheme } = useTheme();
  const [deposits, setDeposits] = useState([]);
  let d = useSelector((state) => state.transactions);
  let w = useSelector((state) => state.transactions);
  async function parse_transactions() {
    let deposits = d?.deposits;
    let withdrawals = w?.withdrawals;
    console.log(withdrawals, "withdrawals");
    console.log(deposits, "deposits");
    let b = deposits?.map((d) => {
      return {
        time: d.created_at,
        direction: "Deposit",
        status: d.deposit_state,
        deposit_network: d.deposit_network,
        amount: d.amount,
        type: d.deposit_type,
        transaction_hash: d.transaction_hash,
        currency: d.currency,
      };
    });


    let c = withdrawals.map((d) => {
      return {
        time: d.created_at,
        direction: "Withdrawal",
        status: d.withdrawal_state,
        deposit_network: d.withdrawal_network,
        amount: d.amount,
        type: d.withdrawal_type,
        transaction_hash: d.transaction_hash,
        currency: d.currency,
      };
    });

    let a = b.concat(c);

    let sorted = a.sort((a, b) => {
      return new Date(b.time) - new Date(a.time);
    });

    setDeposits(sorted);
  }

  useEffect(() => {
    parse_transactions();
  }, [d]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return deposits?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, deposits, itemsPerPage]);

  //dropdown

  //timeDropdwon

  const timeDropdownRef = useRef(null);
  const [isTimeDropdownActive, setIsTimeDropdownActive] = useState(false);
  const [selectedTime, setSelectedTime] = useState("All");

  //type dropdown
  const typeDropdownRef = useRef(null);
  const [isTypeDropdownActive, setIsTypeDropdownActive] = useState(false);
  const [selectedType, setSelectedType] = useState("All");

  //walletDropdown
  const walletDropdownRef = useRef(null);
  const [isWalletDropdownActive, setIsWalletDropdownActive] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState("All");
  //coin Dropdown

  const coinDropdownRef = useRef(null);
  const [isCoinDropdownActive, setIsCoinDropdownActive] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState("All");

  return (
    <div
      className={clsx(
        classes.tableContainer,
        !isDarkTheme && classes.lightTheme
      )}
    >
      <div className={classes.allDropdowns}>
        <div ref={timeDropdownRef}>
          <Dropdown
            placeholder="Time"
            className={clsx(classes.dropdownPlaceholder)}
            isActive={isTimeDropdownActive}
            selectedValue={selectedTime}
            dropdownItems={timeDropdownItems}
            setIsDropdownActive={setIsTimeDropdownActive}
            dropdownRef={timeDropdownRef}
            onClick={() => {
              if (isTimeDropdownActive) {
                setIsTimeDropdownActive(false);
              } else {
                setIsTimeDropdownActive(true);
              }
            }}
            isTimeDropdownActive={isTypeDropdownActive}
            onSelect={(val) => {
              setSelectedTime(val);
              setIsTimeDropdownActive(false);
            }}
            styled
          />
        </div>{" "}
        <div ref={typeDropdownRef}>
          <Dropdown
            placeholder="Type"
            className={clsx(classes.dropdownPlaceholder)}
            isActive={isTypeDropdownActive}
            selectedValue={selectedType}
            dropdownItems={typeDropDownItems}
            setIsDropdownActive={setIsTypeDropdownActive}
            dropdownRef={typeDropdownRef}
            onClick={() => {
              if (isTypeDropdownActive) {
                setIsTypeDropdownActive(false);
              } else {
                setIsTypeDropdownActive(true);
              }
            }}
            isDropdownActive={isTypeDropdownActive}
            onSelect={(val) => {
              setSelectedType(val);
              setIsTypeDropdownActive(false);
            }}
            styled
          />
        </div>{" "}
        <div ref={walletDropdownRef}>
          <Dropdown
            placeholder="wallet"
            className={clsx(classes.dropdownPlaceholder)}
            isActive={isWalletDropdownActive}
            selectedValue={selectedWallet}
            dropdownItems={walletDropdownItems}
            setIsDropdownActive={setIsWalletDropdownActive}
            dropdownRef={walletDropdownRef}
            onClick={() => {
              if (isWalletDropdownActive) {
                setIsWalletDropdownActive(false);
              } else {
                setIsWalletDropdownActive(true);
              }
            }}
            isTimeDropdownActive={isWalletDropdownActive}
            onSelect={(val) => {
              setSelectedWallet(val);
              setIsWalletDropdownActive(false);
            }}
            styled
          />
        </div>
        <div ref={coinDropdownRef}>
          <Dropdown
            placeholder="coin"
            className={clsx(classes.dropdownPlaceholder)}
            isActive={isCoinDropdownActive}
            selectedValue={selectedCoin}
            dropdownItems={coinDropDownItems}
            setIsDropdownActive={setIsCoinDropdownActive}
            dropdownRef={coinDropdownRef}
            onClick={() => {
              if (isCoinDropdownActive) {
                setIsCoinDropdownActive(false);
              } else {
                setIsCoinDropdownActive(true);
              }
            }}
            isDropdownActive={isCoinDropdownActive}
            onSelect={(val) => {
              setSelectedCoin(val);
              setIsCoinDropdownActive(false);
            }}
            styled
          />
        </div>
      </div>
      <table className={classes.table}>
        <tbody>
          <tr className={classes.row}>
            <th className={clsx(classes.heading, classes.textAlignCenter)}>
              <p className={classes.tableHeading}>Time</p>
            </th>

            <th className={classes.heading}>Type</th>
            <th className={classes.heading}>Network</th>
            <th className={classes.heading}>Coin</th>
            <th className={classes.heading}>Amount</th>
            {/* <th className={classes.heading}>Destination</th> */}
            <th className={classes.heading}>Transaction Hash</th>

            <th className={classes.heading}>
              <p
                className={clsx(
                  classes.tableHeading,

                  classes.lastHeading
                )}
              >
                Status
              </p>
            </th>
          </tr>
          {currentTableData?.map((el, index) => (
            <SingleRow {...el} key={index} index={index} />
          ))}
        </tbody>
      </table>
      <div className={classes.pagination}>
        <Pagination
          currentPage={currentPage}
          totalCount={deposits.length}
          pageSize={itemsPerPage}
          onPageChange={(page) => setCurrentPage(page)}
          siblingCount={0}
        />
      </div>
    </div>
  );
};

export default TransactionHistory;
