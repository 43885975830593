import { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

import classes from "./Dropdown.module.css";
import isEmpty from "components/utils/is-empty";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useTheme } from "ThemeContext/ThemeContext";
import { magnifier } from "assets";

const Dropdown = ({
  selectedOption,
  setSelectedOption,
  uniqueKey,
  smallIcon,
  pattern1,
  placeholder,
  options,
  noDesign,
  onSelect,
  defaultSelected,
  borderNone,
}) => {
  // const [selectedOption, setSelectedOption] = useState({});
  const { isDarkTheme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef();

  useOnClickOutside(dropdownRef, () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 200);
  });

  useEffect(() => {
    if (!isEmpty(defaultSelected)) {
      setSelectedOption(options[defaultSelected]);
    }
  }, [defaultSelected, options]);

  //search filtering
  const [filteredItems, setFilteredItems] = useState(options);
  const inputRef = useRef();

  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    if (searchValue === "") {
      setFilteredItems(options);
    } else {
      setFilteredItems(
        options.filter((el) =>
          el.name.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }
  }, [searchValue, options]);

  return (
    <div
      className={clsx(
        classes.dropdownWrap,
        noDesign && classes.noDesign,
        borderNone && classes.borderNone,
        !isDarkTheme && classes.lightTheme
      )}
      ref={dropdownRef}
    >
      <div className={classes.dropdown} onClick={() => setIsOpen(!isOpen)}>
        <div>
          {isEmpty(selectedOption) ? (
            <span className={classes.placeHolder}>{placeholder}</span>
          ) : pattern1 ? (
            <div
              className={clsx(
                classes.iconContainer,
                smallIcon ? classes.alignItemsStart : classes.alignItemsCenter
              )}
            >
              <img
                className={clsx(classes.logo, smallIcon && classes.small)}
                src={selectedOption?.logo}
                alt=""
              />
              <div>
                <div className={classes.nameContainer}>
                  <p className={classes.name}> {selectedOption?.name} </p>
                  {/* {noDesign && (
                    <>
                      {isOpen ? (
                        <BiChevronUp className={classes.arrow} />
                      ) : (
                        <BiChevronDown className={classes.arrow} />
                      )}
                    </>
                  )}{" "} */}
                </div>
                {selectedOption.subTitle && (
                  <p className={classes.subTitle}>{selectedOption?.subTitle}</p>
                )}
              </div>
            </div>
          ) : (
            <div className={classes.bold}>
              <span className={classes.title}>{selectedOption?.name}</span>{" "}
              <span className={classes.text}>
                ({selectedOption?.id?.toUpperCase()})
              </span>
            </div>
          )}
        </div>

        {isOpen ? (
          <BiChevronUp className={classes.arrow} />
        ) : (
          <BiChevronDown className={classes.arrow} />
        )}
      </div>

      <AnimatePresence>
        {isOpen && options && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            className={classes.options}
          >
            <div className={classes.searchContainer}>
              <img src={magnifier} alt="magnifier" className={classes.icon} />
              <input
                ref={inputRef}
                type="search"
                placeholder="Search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            {filteredItems?.map((el, idx) => {
              return (
                <div
                  className={classes.option}
                  key={uniqueKey + idx}
                  onClick={() => {
                    setIsOpen(false);
                    setSelectedOption(el);
                    onSelect(el);
                  }}
                >
                  {pattern1 ? (
                    <div
                      className={clsx(
                        classes.iconContainer,
                        smallIcon
                          ? classes.alignItemsStart
                          : classes.alignItemsCenter
                      )}
                    >
                      <img
                        className={clsx(
                          classes.logo,
                          smallIcon && classes.small
                        )}
                        src={el?.logo}
                        alt=""
                      />
                      <div>
                        <h4 className={classes.bold}>{el?.name}</h4>
                        <div className={classes.subTitle}>
                          {el?.symbol?.toUpperCase()}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.bold}>
                      <h4>{el.title}</h4>
                      {el?.name}
                      <div className={classes.subTitle}>
                        {el?.id?.toUpperCase()}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Dropdown;
