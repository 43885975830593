import React from "react";

import Verification from "components/Verification/Verification";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch } from 'react-redux';
import { getUserInfo } from "../../redux/user";

const VerifyAccount = ({ mode, dispatchAll }) => {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({});
  const [otpInvalid, setOtpInvalid] = useState(false);
  const [OTP, setOTP] = useState("");

  const dispatch = useDispatch();

  let Navigate = useNavigate();
  async function loginHandler(e) {


    if (mode == "login") {
      e.preventDefault();
      let api = process.env.REACT_APP_API;
      let route = "/auth/login";
      let url = api + route;

      let body = {
        ...loginData,
        mfa: [
          {
            method: "Email",
            data: OTP
          }
        ]
      }

      try {
        let { data, status } = await axios.post(url, body);
        let token = data.token;

        if (status == 200) {
          if (token) {
            Cookies.set("auth-token", token);
            dispatch(getUserInfo());
            dispatchAll();
            navigate("/dashboard")
          }

        } else {
          setOtpInvalid(true);
        }

      } catch (e) {
        setOtpInvalid(true);
      }
    }

    if (mode == "reset") {
      let data = localStorage.getItem("resetPassword");
      data = JSON.parse(data);

      let api = process.env.REACT_APP_API;
      let route = "/auth/reset-password/complete";
      let url = api + route;

      let body = {
        ...data,
        otp: OTP

      }

      try {
        let { data, status } = await axios.post(url, body);
        if (status == 200) {
          Navigate("/password-changed")
        }
      } catch (e) {
        setOtpInvalid(true);
      }


    }
  }

  async function getLoginData() {
    let loginData = localStorage.getItem("login-details");
    loginData = JSON.parse(loginData);
    setLoginData(loginData);
    return loginData;
  }

  useEffect(() => {
    getLoginData();
  }, []);
  return (
    <Verification
      data={loginData}
      handler={loginHandler}
      otp={OTP}
      setOTP={setOTP}
      setOtpInvalid={setOtpInvalid}
      title="Verify Account"
      subTitle="We send a 6 digit verification code to your email or phone."
      redirect="/set-new-password"
    />
  );
};

export default VerifyAccount;
