import Modal from "components/common/Modal/Modal";
import classes from "./ReferrelModal.module.css";
import { LuCopy } from "react-icons/lu";

import clsx from "clsx";
import CopyToClipboard from "react-copy-to-clipboard";
import { copy } from "assets";
import { useTheme } from "../../ThemeContext/ThemeContext";

const ReferreModal = ({ isActive, onClose, user }) => {
  const { isDarkTheme } = useTheme();
  return (
    <Modal
      className={clsx(
        classes.wrapper,
        "overflow",
        !isDarkTheme && classes.lightTheme
      )}
      isActive={isActive}
      onClose={onClose}
      styled
    >
      <h3 className={classes.heading}>Referral Statistics</h3>
      <div className={classes.infoContainer}>
        <div className={classes.spaceBetween}>
          <p className={classes.label}>Total Referrals</p>
          <p className={classes.value}> 2 Users</p>
        </div>{" "}
        <div className={classes.spaceBetween}>
          <p className={classes.label}>Total Earnings</p>
          <p className={classes.value}>0 USD</p>
        </div>{" "}
        <div className={classes.spaceBetween}>
          <p className={classes.label}>Bonus</p>
          <p className={classes.value}>0 USD</p>
        </div>{" "}
      </div>
      <div className={classes.refer}>
        <h4 className={classes.title}>Your Referral Link</h4>
        <div className={classes.linkWrapper}>
          {" "}
          <div className={classes.linkContainer}>
            <h3 className={classes.link}>
              {`${process.env.REACT_APP_FRONTEND_URL}/signup/${user?.platform_id}`}
            </h3>
          </div>
          <CopyToClipboard
            text={`${process.env.REACT_APP_FRONTEND_URL}/signup/${user?.platform_id}`}
          >
            <div className={classes.copyContainer}>
              {/* <img src={copy} alt="#" className={classes.copyIcon} /> */}
              <LuCopy className={classes.copyIcon} />
            </div>
          </CopyToClipboard>
        </div>

        <p className={classes.earningFrom}>
          Refer your friends to earn 10% of their trading fees
        </p>
      </div>
    </Modal>
  );
};

export default ReferreModal;
