import React from "react";

import classes from "./SingleRow.module.css";
import clsx from "clsx";

const SingleRow = ({
  date,
  coin,
  minStaking,
  maxStaking,
  apy,
  duration,
  status,
}) => {
  return (
    <>
      <tr className={clsx(classes.row, classes.valueContainer)}>
        <td className={clsx(classes.item, classes.firstItem)}>{date}</td>{" "}
        <td className={classes.item}>
          <div className={classes.logoAndNameContainer}>
            <img src={coin.logo} alt="#" className={classes.logo} />
            <div className={classes.nameContainer}>
              <p>{coin.shortName}</p>
              <span>{coin.name}</span>
            </div>
          </div>
        </td>
        <td className={classes.item}>{minStaking}</td>
        <td className={clsx(classes.item)}>{maxStaking}</td>
        <td className={clsx(classes.item, apy)}>{apy}</td>
        <td className={clsx(classes.item)}>{duration}</td>
        <td
          className={clsx(
            classes.item,
            classes.lastItem,
            status.toLowerCase() === "live" && classes.live,
            status.toLowerCase() === "finished" && classes.finished
          )}
        >
          {status}
        </td>
      </tr>
    </>
  );
};

export default SingleRow;
