import React from "react";

const PlayStore = ({ color }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.60972 2.31396L13.793 12.5L3.61082 22.6864C3.41776 22.6048 3.24866 22.462 3.13555 22.2667C3.0474 22.1144 3.00098 21.9416 3.00098 21.7656V3.23453C3.00098 2.82109 3.25188 2.46625 3.60972 2.31396ZM14.5 13.207L16.802 15.509L5.86498 21.842L14.5 13.207ZM17.699 10.009L20.5061 11.6347C20.9841 11.9114 21.1473 12.5232 20.8705 13.0011C20.783 13.1523 20.6574 13.278 20.5061 13.3655L17.698 14.991L15.207 12.5L17.699 10.009ZM5.86498 3.15796L16.803 9.48996L14.5 11.793L5.86498 3.15796Z"
        fill={color ? color : "#fff"}
      />
    </svg>
  );
};

export default PlayStore;
