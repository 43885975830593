import React, { useState } from "react";
import classes from "./EnterAmount.module.css";
import Input from "components/common/Input/Input";
import { checkIfNumber } from "components/utils/isNumber";

const EnterAmount = ({ amount, setAmount, balance }) => {
  return (
    <div className={classes.wrapper}>
      <p className={classes.label}> Enter Amount</p>
      <div className={classes.inputWrapper}>
        <Input
          rootClassName={classes.input}
          onKeyDown={(event) => checkIfNumber(event)}
          type="text"
          placeholder="0.00"
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />
        <button
          onClick={() => setAmount(balance)}
          className={classes.max}>Max</button>
      </div>
    </div>
  );
};

export default EnterAmount;
