import clsx from "clsx";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import classes from "./OptionDropDown.module.css";
import Toggle from "./Toggle/Toggle";
import { useTheme } from "ThemeContext/ThemeContext";

const OptionDropDown = ({
  isActive,
  // selectedValue,
  // onSelect,
  // isDropdownActive,
  // children,
  selectAmount,
  setSelectAmount,
  selectCumulative,
  setSelectCumulative,
  animation,
  setAnimation,
}) => {
  const { isDarkTheme } = useTheme();
  return (
    <div className={clsx(classes.dropdown, !isDarkTheme && classes.lightTheme)}>
      <div className={clsx(classes.dropdownMain, isActive && classes.active)}>
        <div className={classes.list}>
          <p className={classes.heading}>Depth Visualization</p>

          <div className={classes.item}>
            <div
              className={clsx(
                classes.outerCircle,
                selectAmount && classes.selected
              )}
              onClick={() => setSelectAmount((prev) => !prev)}
            >
              <div className={classes.innerCircle}></div>
            </div>
            <p className={classes.label}>Amount</p>
          </div>
          <div className={classes.item}>
            <div
              className={clsx(
                classes.outerCircle,
                selectCumulative && classes.selected
              )}
              onClick={() => setSelectCumulative((prev) => !prev)}
            >
              <div className={classes.innerCircle}></div>
            </div>
            <p className={classes.label}>Cumulative</p>
          </div>

          <div className={classes.toggleContainer}>
            <p className={classes.label}>Animation</p>
            <Toggle
              enable={animation}
              handleToggle={() => setAnimation((prev) => !prev)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptionDropDown;
//     onClick={() => {
//      el.action();
//      onSelect(el.label);
//}}
