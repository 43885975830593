import clsx from "clsx";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import classes from "./Dropdown.module.css";
import { useTheme } from "ThemeContext/ThemeContext";

const Dropdown = ({
  isActive,
  selectedValue,
  onSelect,
  isDropdownActive,
  children,
  dropdownItems,
  onClick,
}) => {
  const { isDarkTheme } = useTheme();
  return (
    <div className={clsx(classes.dropdown, !isDarkTheme && classes.lightTheme)}>
      <div className={clsx(classes.dropdownMain, isActive && classes.active)}>
        <div className={classes.list}>
          {dropdownItems.map((el, idx) => {
            return (
              <div
                key={"lang-list-item-" + idx}
                className={clsx(
                  classes.listItem,
                  selectedValue === el.label && classes.active
                )}
                onClick={() => {
                  el.action();
                  onSelect(el.label);
                }}
              >
                {el.label}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
