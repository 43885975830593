import React, { useEffect, useRef, useState } from "react";
import classes from "./EnterAmountModal.module.css";
import Button from "components/Button/Button";
import clsx from "clsx";
import Modal from "components/common/Modal/Modal";
import Heading from "components/common/Heading/Heading";
import Input from "components/common/Input/Input";
import { checkIfNumber } from "components/utils/isNumber";
import useOnClickOutside from "hooks/useOnClickOutside";
import Dropdown from "../Dropdown/Dropdown";
import { bitcoin, earn, spot, tether } from "assets";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import Cookies from "js-cookie";
import { useTheme } from "ThemeContext/ThemeContext";
const dropdownItems = [
  {
    logo: bitcoin,
    label: "BTC",
  },
  {
    logo: tether,
    label: "USDT",
  },
];
const wallets = [
  {
    logo: earn,
    label: "Funding",
  },
  {
    logo: spot,
    label: "Spot Wallet",
  },
];
const EnterAmountModal = ({ isActive, onClose, setIsConfirmationModal }) => {
  const { isDarkTheme } = useTheme();
  const [amount, setAmount] = useState("");
  const currencyDropdownRef = useRef(null);
  const [isCurrencyDropdownActive, setIsCurrencyDropdownActive] =
    useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [balance, setBalance] = useState(0);

  let balances = useSelector((state) => state.balances.balances);

  async function pay() {
    let api = process.env.REACT_APP_API + "/balance/internal-transfer";
    let pay_to = localStorage.getItem("pay_to");

    if (pay_to == null) {
      toast.error("Invalid UID", {
        position: "top-center",
        theme: "dark",
        toastId: "invalid-uid",
      });
      return;
    }

    if (amount == "") {
      toast.error("Please enter an amount", {
        position: "top-center",
        theme: "dark",
        toastId: "invalid-amount",
      });
      return;
    }

    if (balance < amount) {
      toast.error("Insufficient balance", {
        position: "top-center",
        theme: "dark",
        toastId: "insufficient-balance",
      });
      return;
    }

    let data = {
      platform_id: pay_to,
      amount: Number(amount),
      currency: selectedValue.id,
      wallet_type: "Funding",
    };

    try {
      let { data: res, status } = await axios.post(api, data, {
        headers: {
          Authorization: `Bearer ${Cookies.get("auth-token")}`,
        },
      });

      if (status == 200) {
        toast.success("Transfer successful", {
          toastId: "transfer-success",
          theme: "dark",
          position: "top-center",
        });
        onClose();
        setIsConfirmationModal(false);
      } else {
        toast.error("Transfer failed", {
          position: "top-center",
          theme: "dark",
          toastId: "transfer-failed",
        });
      }
    } catch (e) {
      toast.error("Transfer failed", {
        position: "top-center",
        theme: "dark",
        toastId: "transfer-failed",
      });
    }
  }

  async function fetchBalance() {
    if (!selectedValue) return;
    let balance = balances.find(
      (b) => b.currency == selectedValue.id && b.wallet_type == "Funding"
    );
    console.log(balance);
    setBalance(balance?.balance);
  }

  useEffect(() => {
    fetchBalance();
  }, [selectedValue]);

  let currencies = useSelector((state) => state.currencies.currencies);

  useOnClickOutside(currencyDropdownRef, () =>
    setIsCurrencyDropdownActive(false)
  );
  return (
    <Modal
      className={clsx(
        classes.wrapper,
        "overflow",
        !isDarkTheme && classes.lightTheme
      )}
      isActive={isActive}
      onClose={onClose}
      styled
    >
      <Heading heading="Funding Send" wFull />
      <div className={classes.headingContainer}>
        <Heading heading="Amount" center lg />
        <p className={classes.text}>
          Enter your an amount that you want to send.
        </p>{" "}
      </div>

      <div className={classes.inputContainer}>
        <p className={classes.label}>Amount</p>
        <Input
          rootClassName={classes.input}
          onKeyDown={(event) => checkIfNumber(event)}
          type="number"
          placeholder="0.00"
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />
      </div>
      {selectedValue && (
        <p className={classes.balance}>
          Balance: {balance?.toFixed(2)} {selectedValue?.symbol?.toUpperCase()}
        </p>
      )}
      <div className={classes.inputContainer}>
        <p className={classes.label}>Currency</p>
        <div ref={currencyDropdownRef} className={classes.dropdownContainer}>
          <Dropdown
            isActive={isCurrencyDropdownActive}
            selectedValue={selectedValue}
            dropdownItems={currencies}
            onClick={() => {
              if (isCurrencyDropdownActive) {
                setIsCurrencyDropdownActive(false);
              } else {
                setIsCurrencyDropdownActive(true);
              }
            }}
            isDropdownActive={isCurrencyDropdownActive}
            onSelect={(val) => {
              setSelectedValue(val);
              setIsCurrencyDropdownActive(false);
            }}
          />
        </div>
      </div>
      <div className={classes.buttonContainer}>
        {" "}
        <Button btnGray onClick={onClose} size="md">
          Cancel
        </Button>
        <Button
          btnPrimary
          onClick={() => {
            //
            // onClose();
            pay();
          }}
          size="md"
        >
          Continue
        </Button>
      </div>
    </Modal>
  );
};

export default EnterAmountModal;
