import React from "react";

const AppStore = ({ color }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.5C17.5228 2.5 22 6.97715 22 12.5C22 18.0228 17.5228 22.5 12 22.5C6.47715 22.5 2 18.0228 2 12.5C2 6.97715 6.47715 2.5 12 2.5ZM8.82258 15.8427C8.42804 15.3663 7.9373 15.1957 7.34401 15.334L7.19355 15.375L6.60484 16.3911C6.37903 16.7863 6.5121 17.2903 6.90726 17.5161C7.26949 17.7231 7.7232 17.6286 7.97023 17.307L8.03226 17.2137L8.82258 15.8427ZM13.2097 9.16129C12.7218 9.56452 12.2298 10.7581 12.9194 11.9476L15.9597 17.2137C16.1895 17.6089 16.6895 17.7419 17.0847 17.5161C17.4469 17.3054 17.5889 16.8677 17.4361 16.4919L17.3871 16.3911L16.5847 15H17.7742C18.2298 15 18.5968 14.6331 18.5968 14.1774C18.5968 13.7568 18.2841 13.4118 17.8776 13.3612L17.7742 13.3548H15.6331L13.44 9.55741L13.2097 9.16129ZM13.4879 6.11694C13.1257 5.90995 12.672 6.00451 12.4249 6.32608L12.3629 6.41935L11.996 7.05242L11.6371 6.41935C11.4073 6.02419 10.9073 5.89113 10.5121 6.11694C10.1499 6.32762 10.0079 6.76532 10.1606 7.14118L10.2097 7.24194L11.0484 8.69758L8.3629 13.3508H6.26613C5.81048 13.3508 5.44355 13.7177 5.44355 14.1734C5.44355 14.594 5.7562 14.939 6.16268 14.9896L6.26613 14.996H13.746C14.0869 14.3562 13.6854 13.4472 12.9357 13.3579L12.8145 13.3508H10.2621L13.7903 7.24194C14.0161 6.84677 13.8831 6.34274 13.4879 6.11694Z"
        fill={color ? color : "#fff"}
      />
    </svg>
  );
};

export default AppStore;
