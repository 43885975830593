import React, { useState } from "react";
import { TbCopy } from "react-icons/tb";
import CopyToClipboard from "react-copy-to-clipboard";
import classes from "./TopBar.module.css";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { avater, verified } from "assets";
import clsx from "clsx";
import { useTheme } from "ThemeContext/ThemeContext";
const TopBar = ({ user }) => {
  const { isDarkTheme } = useTheme();
  const [showAll, setShowAll] = useState(false);
  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <div
        className={classes.userContainer}
        onClick={() => setShowAll((prev) => !prev)}
      >
        <img
          src={`https://api.dicebear.com/7.x/adventurer/svg?seed=${user?.platform_id}`}
          alt="#"
          className={classes.user}
        />
        <div className={classes.userInfo}>
          <p className={classes.userTitle}>
            Hello, {user?.firstname ? user.firstname : "user"}
          </p>
          <p className={classes.id}>
            <span>UID:</span> {user?.platform_id}
            <CopyToClipboard text="834076255">
              <TbCopy className={classes.copy} />
            </CopyToClipboard>
          </p>
        </div>
        {showAll ? (
          <FaAngleUp className={classes.arrow} />
        ) : (
          <FaAngleDown className={classes.arrow} />
        )}
      </div>
      <div className={clsx(classes.container, showAll && classes.showAll)}>
        <div className={classes.box}>
          <p>KYC Status</p>

          {user.kyc_level > 0 && <p className={classes.verified}>
            <img src={verified} alt="#" className={classes.verified} />{" "}
            Verified

          </p>}

          {user.kyc_level == 0 && <p className={classes.unverified}>
            <img width={15} src={"https://cdn-icons-png.flaticon.com/128/14035/14035561.png"} alt="#" className={classes.unverified} />{" "}
            Unverified

          </p>}
        </div>{" "}
        <div className={classes.box}>
          <p className={classes.heading}>Account Type</p>
          <p className={classes.info}>{user.account_type}</p>
        </div>{" "}
        <div className={classes.box}>
          <p className={classes.heading}>VIP Status</p>
          <p className={classes.info}>Regular</p>
        </div>{" "}
        <div className={classes.box}>
          <p className={classes.heading}>Account Security</p>
          <p className={classes.info}>
            {user?.login_mfa?.length > 0
              ? "Moderate"
              : user?.login_mfa?.length >= 2
                ? "High"
                : "Low"}
          </p>
        </div>{" "}
        <div className={classes.box}>
          <p className={classes.heading}>Soul Bound Token</p>
          <p className={classes.info}>
            null
            <CopyToClipboard text="null">
              <TbCopy className={classes.copy} />
            </CopyToClipboard>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
