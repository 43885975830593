import React, { useState, useRef, useEffect } from "react";
import classes from "./Currencies.module.css";
import { BiChevronRight, BiChevronLeft } from "react-icons/bi";
import { BsStarFill } from "react-icons/bs";
import clsx from "clsx";
import { useTheme } from "ThemeContext/ThemeContext";

const TabSlider = ({
  setShowOnlyFavorites,
  setSearchValue,
  currency,
  setCurrency,
}) => {
  const { isDarkTheme } = useTheme();
  const [scrollPosition, setScrollPosition] = useState(0);
  const sliderRef = useRef(null);
  const [activeFavourite, setActiveFavourite] = useState(false);
  const [tabs, setTabs] = useState(["USDT", "BTC", "BNB"]);

  const handleScroll = (direction) => {
    const scrollAmount = 150;
    if (direction === "left") {
      sliderRef.current.scrollLeft -= scrollAmount;
      setScrollPosition(sliderRef.current.scrollLeft);
    } else {
      sliderRef.current.scrollLeft += scrollAmount;
      setScrollPosition(sliderRef.current.scrollLeft);
    }
  };

  const handleScrollEnd = () => {
    const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;
    if (scrollLeft === scrollWidth - clientWidth) {
      // Add more tabs here
      const newTabs = [...tabs, ...tabs]; // Duplicate the tabs for demonstration
      setTabs(newTabs);
    }
  };

  useEffect(() => {
    sliderRef.current?.addEventListener("scroll", handleScrollEnd);
    return () => {
      sliderRef.current?.removeEventListener("scroll", handleScrollEnd);
    };
  }, [tabs]); // Adding tabs to the dependency array

  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <BiChevronLeft
        onClick={() => handleScroll("left")}
        className={classes.arrow}
      />
      <div className={classes.tabContainer}>
        <p
          className={clsx(classes.tab, !activeFavourite && classes.activeTab)}
          onClick={() => {
            setShowOnlyFavorites(false);
            setActiveFavourite(false);
            setCurrency(null);
            setSearchValue("");
          }}
        >
          ALL
        </p>
        <BsStarFill
          className={clsx(
            classes.star,
            activeFavourite && classes.activeFavourite
          )}
          onClick={() => {
            setShowOnlyFavorites(true);
            setActiveFavourite(true);
          }}
        />
      </div>
      <div className={classes.tabs} ref={sliderRef}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={clsx(classes.text, currency === tab && classes.active)}
            onClick={() => setCurrency(tab)}
          >
            {tab}
          </div>
        ))}
      </div>

      <BiChevronRight
        onClick={() => handleScroll("right")}
        className={classes.arrow}
      />
    </div>
  );
};

export default TabSlider;
