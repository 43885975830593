import React from "react";
import Sidebar from "components/Wallet/Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import classes from "./WalletLayout.module.css";
import clsx from "clsx";
import Footer from "components/Footer/Footer";
const WalletLayout = () => {
  return (
    <>
      <div className={clsx("container", classes.container)}>
        <Sidebar />
        <div className={classes.wrapper}>
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WalletLayout;
