import React from "react";
import classes from "./Features.module.css";
import {
  decentralized,
  protectedIcon,
  rewards,
  securityAndConsessus,
} from "assets";
import { useTheme } from "ThemeContext/ThemeContext";
import clsx from "clsx";

const Features = () => {
  const { isDarkTheme } = useTheme();
  const data = [
    {
      img: rewards,
      title: "Rewards Distribution",
      info: "Staking allows participants to earn rewards in the form of additional cryptocurrency tokens.",
    },
    {
      img: decentralized,
      title: "Decentralized Governance",
      info: "Staking networks often integrate decentralized governance, empowering token holders.",
    },
    {
      img: securityAndConsessus,
      title: "Security and Consensus",
      info: "Staking provides a means for achieving consensus in a decentralized network.",
    },
  ];
  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <div className={classes.textContainer}>
        <h2 className={classes.heading}>
          <span className={classes.highlight}>{process.env.REACT_APP_EXCHANGE_NAME}</span> Staking Features
        </h2>
        <p className={classes.text}>
          <img src={protectedIcon} alt="#" className={classes.protectedIcon} />
          Your information on {process.env.REACT_APP_EXCHANGE_NAME} is encrypted
          using AES 256 Bit Aglorithm.
        </p>
      </div>

      <div className={classes.container}>
        {data.map((el, i) => (
          <div className={classes.card} key={i}>
            <div className={classes.imgAndId}>
              <img src={el.img} alt="#" className={classes.img} />
              <h3 className={classes.id}>{i + 1}</h3>
            </div>
            <div className={classes.titleAndInfo}>
              <h4 className={classes.title}>{el.title}</h4>
              <p className={classes.info}>{el.info}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
