import React, { useRef, useState } from "react";

import classes from "./SingleRow.module.css";
import clsx from "clsx";

import useOnClickOutside from "hooks/useOnClickOutside";

const SingleRow = ({
  time,
  pair,
  type,
  side,
  price,
  amount,
  filled,
  unFilled,
  triggerCondition,

  total,
  timestamp,
  tradeInUsdt,
}) => {
  return (
    <>
      <tr className={clsx(classes.row, classes.valueContainer)}>
        <td className={clsx(classes.item, classes.firstItem)}>{time}</td>
        <td
          className={clsx(
            classes.item,
            classes.pair,
            side.toLowerCase() === "buy" && classes.buy,
            side.toLowerCase() === "sell" && classes.sell
          )}
        >
          {pair}
        </td>
        <td className={classes.item}>{type}</td>
        <td
          className={clsx(
            classes.item,
            side.toLowerCase() === "buy" && classes.buy,
            side.toLowerCase() === "sell" && classes.sell
          )}
        >
          {side}
        </td>
        <td className={classes.item}>{price}</td>
        <td className={classes.item}>{amount}</td>
        <td className={classes.item}>{filled}</td>
        <td className={classes.item}>{unFilled}</td>
        <td className={classes.item}>{"--"}</td>
        <td className={classes.item}>{total}</td>

        <td className={clsx(classes.item, classes.lastItem)}>{tradeInUsdt}</td>
      </tr>
    </>
  );
};

export default SingleRow;
