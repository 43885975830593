import clsx from "clsx";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import classes from "./Dropdown.module.css";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useTheme } from "ThemeContext/ThemeContext";
const Dropdown = ({
  dropdownRef,
  isActive,
  selectedValue,
  onSelect,
  isDropdownActive,
  setIsDropdownActive,
  children,
  dropdownItems,
  onClick,
  className,
  placeholder,
  dropArrow,
  left,
  tab,
  styled,
}) => {
  const { isDarkTheme } = useTheme();
  useOnClickOutside(dropdownRef, () => setIsDropdownActive(false));
  return (
    <div
      className={clsx(
        classes.dropdown,
        tab && classes.tabContainer,
        styled && classes.styledDropdown,
        isDarkTheme,
        classes.lightTheme
      )}
      onClick={onClick}
    >
      {!placeholder && (
        <>
          <p className={classes.selectedValue}>
            {selectedValue?.duration > 0
              ? selectedValue?.duration + " Days"
              : "Flexible"}
          </p>{" "}
          {children}
          {isDropdownActive ? (
            tab ? (
              <FaCaretUp className={classes.icon} />
            ) : (
              <FaAngleUp className={classes.icon} />
            )
          ) : tab ? (
            <FaCaretDown className={classes.icon} />
          ) : (
            <FaAngleDown className={classes.icon} />
          )}
        </>
      )}
      {placeholder && (
        <p
          className={clsx(classes.placeholder, className, tab && classes.tab)}
          style={{ color: isDropdownActive && isDarkTheme && "#fff" }}
        >
          {placeholder}
          {isDropdownActive ? (
            dropArrow ? (
              <FaCaretUp className={classes.icon} />
            ) : (
              <FaAngleUp className={classes.icon} />
            )
          ) : dropArrow ? (
            <FaCaretDown className={classes.icon} />
          ) : (
            <FaAngleDown className={classes.icon} />
          )}
        </p>
      )}
      <div
        className={clsx(
          classes.dropdownMain,
          isActive && classes.active,
          placeholder && classes.placeholderType,
          left && classes.left
        )}
      >
        <div className={classes.list}>
          {dropdownItems.map((el, idx) => {
            return (
              <div
                key={"lang-list-item-" + idx}
                className={clsx(
                  classes.listItem,
                  selectedValue === el.label && classes.active
                )}
                onClick={() => {
                  onSelect(el.label);
                }}
              >
                {el.label}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
