import clsx from "clsx";
import React, { useState } from "react";
import classes from "./OrderBookRecentTrades.module.css";
import OrderBook from "./OrderBook/OrderBook";
import RecentTrades from "./RecentTrades/RecentTrades";
import { useTheme } from "ThemeContext/ThemeContext";

const OrderBookRecentTrades = ({ bids, asks, tickers }) => {
  const { isDarkTheme } = useTheme();
  const tabs = ["Order Book", "Recent Trades"];

  const [activeTab, setActiveTab] = useState("Order Book");
  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      {/* <div className={classes.tabs}>
        {tabs.map((tab, i) => (
          <p
            className={clsx(
              classes.tab,
              tab.toLowerCase() === activeTab.toLowerCase() && classes.activeTab
            )}
            key={i}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </p>
        ))}
      </div> */}

      {activeTab === "Order Book" && (
        <OrderBook bids={bids} asks={asks} tickers={tickers} />
      )}
      {activeTab === "Recent Trades" && <RecentTrades />}
    </div>
  );
};

export default OrderBookRecentTrades;
