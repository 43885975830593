import Button from "components/Button/Button";
import { successIcon } from "assets";
import classes from "./StakeCreateSuccessFully.module.css";
import Heading from "components/common/Heading/Heading";
import clsx from "clsx";

const StakeCreateSuccessFully = () => {
  return (
    <div className="container">
      <div className={classes.wrapper}>
        <img className={classes.changed} src={successIcon} alt="tick" />
        <div className={classes.textContainer}>
          <Heading heading="Staking Created Successfully!" success lg center />
        </div>
        <h4 className={classes.amountContainer}>
          <p className={classes.label}>Staking Amount</p>
          <p className={classes.amount}>
            0.029 <span className={classes.currency}>BTC</span>
          </p>
        </h4>{" "}
        <div className={classes.infoContainer}>
          <div className={classes.spaceBetween}>
            <p className={classes.label}>Duration</p>
            <p className={classes.value}>30 Days</p>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <p className={classes.label}>APY</p>
            <p className={clsx(classes.value, classes.apy)}>3.7%</p>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <p className={classes.label}>Stake ID</p>
            <p className={classes.value}>9876234kds8734j987fsk</p>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <p className={classes.label}>Date & Time</p>
            <p className={classes.value}>05-24-2024 15:36:29</p>
          </div>
        </div>
        <Button to="/staking" className={classes.btn} btnGray size="lg">
          Back to Staking
        </Button>
      </div>
    </div>
  );
};

export default StakeCreateSuccessFully;
