import Button from "components/Button/Button";
import { successIcon } from "assets";
import classes from "./WithDrawSuccess.module.css";
import Heading from "components/common/Heading/Heading";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const WithDrawSuccess = () => {
  let [details, setDetails] = useState({});
  let Navigate = useNavigate();
  async function getDetailsfromLocal() {
    let withdrawDetails = localStorage.getItem("withdrawal-details");
    let data = JSON.parse(withdrawDetails);
    // if (!data) {
    //   window.location.href = "/wallet/spot"
    // }
    setDetails(data);
  }

  useEffect(() => {
    getDetailsfromLocal();
  }, []);
  return (
    <div className="container">
      <div className={classes.wrapper}>
        <img className={classes.changed} src={successIcon} alt="tick" />
        <div className={classes.textContainer}>
          <Heading
            heading="Withdrawal Completed"
            success
            lg
            center
            className={classes.heading}
          />
          <p className={classes.text}>
            Your withdrawal application is successfully complete. Please check
            your account.
          </p>
        </div>
        <h4 className={classes.amountContainer}>
          <p className={classes.label}>You withdrew</p>
          <p className={classes.amount}>
            {details?.amount}
            <span className={classes.currency}>
              {details?.currency?.toUpperCase()}
            </span>
          </p>
        </h4>{" "}
        <div className={classes.infoContainer}>
          <div className={classes.spaceBetween}>
            <p className={classes.label}>Amount</p>
            <p className={classes.value}>
              {" "}
              {details?.amount} {details?.currency?.toUpperCase()}
            </p>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <p className={classes.label}>Network</p>
            <p className={classes.value}>{details?.network?.toUpperCase()}</p>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <p className={classes.label}>Address</p>
            <p className={classes.value}>{details?.address}</p>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <p className={classes.label}>Date & Time</p>
            <p className={classes.value}>{new Date().toLocaleString()}</p>
          </div>
        </div>
        <Button
          onClick={() => {
            localStorage.clear();
            Navigate("/wallet/spot");
          }}
          className={classes.btn}
          btnGray
          size="lg"
        >
          Back to Wallet
        </Button>
      </div>
    </div>
  );
};

export default WithDrawSuccess;





export const IPWarnings = () => {
  let Navigate = useNavigate();

  return (
    <div className="container">
      <div className={classes.wrapper}>
        <img className={classes.changed} src={"https://cdn-icons-png.flaticon.com/128/4344/4344882.png"} alt="tick" />
        <div className={classes.textContainer}>
          <Heading heading="Alert" success lg center />
          <p className={classes.text}>
            We're truly excited to see your interest in UNSXchange! We might offer our services to you in future. Thanks for your interest 🙏
          </p>
        </div>

        {/* <Button
          onClick={
            () => {
              localStorage.clear();
              // Navigate("/wallet/spot")
              // window.location.href = ""
            }
          }
          className={classes.btn} btnGray size="lg">
          Contact us
        </Button> */}
      </div>
    </div>
  );
};