import React from "react";
import classes from "./HeroSection.module.css";

import Button from "components/Button/Button";
import {
  sendOffer,
  peopleJoin,
  receivePoint,
  claimCrypto,
  stepsArrow,
} from "assets";
import InviteFriends from "./InviteFriends/InviteFriends";
import clsx from "clsx";
import { useSelector } from "react-redux";

const HeroSection = ({ user }) => {

  let user_data = useSelector((state) => state.user.userInfo);
  const steps = [
    { icon: sendOffer, info: "Send Refer Link/Code" },
    { icon: peopleJoin, info: "People Join & Trade" },
    { icon: receivePoint, info: "Receive Points" },
    { icon: claimCrypto, info: "Claim crypto rewards" },
  ];
  return (
    <div className={classes.wrapper}>
      <div className={classes.textContainer}>
        <h2 className={classes.heading}>
          Refer and
          <span className={classes.highlight}> Earn</span>
        </h2>
        <p className={classes.text}>
          Refer and Earn is a promotional program where individuals can earn
          rewards or benefits by referring others.
        </p>
        <div className={classes.stepsContainer}>
          {steps.map((el, i) => (
            <div className={classes.stepsWithArrow}>
              <div className={classes.step} key={i}>
                <img src={el.icon} alt="#" className={classes.icon} />
                <p className={classes.info}>{el.info}</p>
              </div>
              {i + 1 !== steps.length && (
                <img
                  src={stepsArrow}
                  alt="#"
                  className={(clsx(classes.arrow), classes[`arrow${i + 1}`])}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <InviteFriends user_data={user_data} />
    </div>
  );
};

export default HeroSection;
