import React from "react";
import classes from "./ReferAndEarn.module.css";
import HeroSection from "components/ReferAndEarn/HeroSection/HeroSection";
import clsx from "clsx";
import ReferList from "components/ReferAndEarn/ReferList/ReferList";
import Overview from "components/ReferAndEarn/Overview/Overview";
import { useTheme } from "ThemeContext/ThemeContext";
import Footer from "components/Footer/Footer";
import { useSelector } from "react-redux";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";


const ReferAndEarn = () => {
  const [refData, setRef] = React.useState(null);

  async function get_ref_data() {
    let base_url = process.env.REACT_APP_API
    let route = "/user/user/ref-data"

    toast.loading("Fetching Referral Data...", {
      position: "top-center",
      autoClose: 2000,
      theme: "colored",
      toastId: "fetching-referral-data"
    })
    let data = await axios.get(base_url + route, {
      headers: {
        "Authorization": "Bearer " + Cookies.get("auth-token")
      }
    })


    console.log(data.data, "data")
    setRef(data.data)
    toast.dismiss("fetching-referral-data")

  }
  React.useEffect(() => {
    get_ref_data()
  }, []);
  const { isDarkTheme } = useTheme();
  return (
    <>
      <div
        className={clsx(
          "container",
          classes.wrapper,
          !isDarkTheme && classes.lightTheme
        )}
      >
        <HeroSection refData={refData} />
        <div className={classes.container}>
          <Overview refData={refData} /> <ReferList refData={refData} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ReferAndEarn;
