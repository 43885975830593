import React from "react";
import classes from "./CryptoVenture.module.css";
import { educationalResource, secureWallet, tradingTools } from "assets";
import { useTheme } from "ThemeContext/ThemeContext";
import clsx from "clsx";

const NextCryptoVenture = () => {
  const { isDarkTheme } = useTheme();
  const data = [
    {
      img: tradingTools,
      title: "Advanced Trading Tools",
      info: "Participate in DeFi protocols on the exchange for passive income by providing liquidity and yield farming with your crypto assets.",
    },
    {
      img: secureWallet,
      title: "Secure Wallet Integration",
      info: "Integrate secure wallet features for peace of mind, enabling smooth asset management and transactions within the exchange.",
    },
    {
      img: educationalResource,
      title: "Educational Resources",
      info: "Empower users with educational resources like tutorials, articles, and market analysis for  enhanced trading experience.",
    },
  ];
  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <div className={classes.textContainer}>
        <h2 className={classes.heading}>
          <span className={classes.highlight}>Crypto Venture</span>
        </h2>
        <p className={classes.text}>
          Navigating the Dynamic World of Cryptocurrency Investments
        </p>
      </div>

      <div className={classes.container}>
        {data.map((el, i) => (
          <div className={classes.card} key={i}>
            <div className={classes.imgAndId}>
              <img src={el.img} alt="#" className={classes.img} />
              <h3 className={classes.id}>{i + 1}</h3>
            </div>
            <div className={classes.titleAndInfo}>
              <h4 className={classes.title}>{el.title}</h4>
              <p className={classes.info}>{el.info}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NextCryptoVenture;
