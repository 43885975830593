import React from "react";
import classes from "./MarketsAndSpotTrading.module.css";
import clsx from "clsx";
import OverView from "components/MarketAndSpotTrading/OverView/OverView";
import OverviewTable from "components/MarketAndSpotTrading/OverviewTable/OverviewTable";
import { useSelector } from 'react-redux';
import { parseTickers } from "../../redux/tickers";

const MarketsAndSpotTrading = () => {

  let parsedTickers = useSelector(parseTickers);
  let currency = useSelector((state) => state.currencies.currencies);

  return (
    <div className={clsx("container", classes.container)}>
      <h2 className={classes.heading}>Market Overview</h2>
      <OverView parsedTickers={parsedTickers} currency={currency} />
      <OverviewTable parsedTickers={parsedTickers} currency={currency} />
    </div>
  );
};

export default MarketsAndSpotTrading;
