import React from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

import classes from "./SingleRow.module.css";
import clsx from "clsx";
import { useTheme } from "ThemeContext/ThemeContext";

const SingleRow = ({
  time,
  pair,
  type,
  side,
  price,
  amount,
  filled,
  unFilled,
  triggerCondition,
  total,
  status,

  showDetails,
  setShowDetails,
  id,
}) => {
  const { isDarkTheme } = useTheme();
  return (
    <>
      <div
        className={clsx(
          classes.row,
          showDetails && classes.active,
          !isDarkTheme && classes.lightTheme
        )}
      >
        <p className={classes.item}>{pair}</p>
        <p className={classes.item}>{time}</p>
        <p
          className={clsx(
            classes.item,
            classes.status,
            status.toLowerCase() === "completed" && classes.completed,
            status.toLowerCase() === "expired" && classes.expired,
            status.toLowerCase() === "canceled" && classes.canceled
          )}
        >
          {status}
        </p>

        {showDetails === id ? (
          <FaAngleUp
            className={classes.arrow}
            color="#fff"
            onClick={() => {
              if (showDetails) {
                setShowDetails(false);
              } else {
                setShowDetails(true);
              }
            }}
          />
        ) : (
          <FaAngleDown
            className={classes.arrow}
            onClick={() => {
              setShowDetails(id);
            }}
          />
        )}
      </div>
      {showDetails === id && (
        <div className={classes.infoContainer}>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Type</p>
            <p className={classes.value}>{type}</p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Side</p>
            <p
              className={clsx(
                classes.value,
                side.toLowerCase() === "buy" && classes.buy,
                side.toLowerCase() === "sell" && classes.sell
              )}
            >
              {side}
            </p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Price</p>
            <p className={classes.value}>{price}</p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Amount</p>
            <p className={classes.value}>{amount}</p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Filled</p>
            <p className={classes.value}>{filled}</p>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Unfilled</p>
            <p className={classes.value}>{unFilled}</p>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Trigger Condition</p>
            <p className={classes.value}>{triggerCondition}</p>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Total</p>
            <p className={classes.value}>{total}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleRow;
