import React from "react";

import classes from "./SingleRow.module.css";
import clsx from "clsx";

const SingleRow = ({ created_at, transaction_hash, deposit_type, deposit_network, currency, amount, deposit_state }) => {
  return (
    <>
      <tr className={clsx(classes.row, classes.valueContainer)}>
        <td className={clsx(classes.item, classes.firstItem)}>{new Date(created_at).toLocaleString()}</td>
        <td className={classes.item}>{deposit_type}</td>
        <td className={classes.item}>{deposit_network?.toUpperCase()}</td>
        <td className={classes.item}>{amount?.toLocaleString()} {currency?.toUpperCase()}</td>
        <td className={classes.item}>
          <p className={classes.amount}>{deposit_state}</p>
        </td>
      </tr>
    </>
  );
};

export default SingleRow;
