import React from "react";
import classes from "./Wrapper.module.css";
import clsx from "clsx";
import { useTheme } from "ThemeContext/ThemeContext";

const Wrapper = ({ children, gap, padding }) => {
  const { isDarkTheme } = useTheme();
  return (
    <div
      className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}
      style={{ gap: gap, padding: padding }}
    >
      {children}
    </div>
  );
};

export default Wrapper;
