import React from "react";

const DownLoad = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_320_19782)">
        <path
          d="M3.33325 14.1667V15.8334C3.33325 16.2754 3.50885 16.6994 3.82141 17.0119C4.13397 17.3245 4.55789 17.5001 4.99992 17.5001H14.9999C15.4419 17.5001 15.8659 17.3245 16.1784 17.0119C16.491 16.6994 16.6666 16.2754 16.6666 15.8334V14.1667"
          stroke={color ? color : "#C2C4C4"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.83325 9.16675L9.99992 13.3334L14.1666 9.16675"
          stroke={color ? color : "#C2C4C4"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 3.33325V13.3333"
          stroke={color ? color : "#C2C4C4"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_320_19782">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DownLoad;
