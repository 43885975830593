import React from "react";

import classes from "./MarketsTable.module.css";
import clsx from "clsx";
import Button from "components/Button/Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const SingleRow = ({
  el,
  currency,
}) => {

  let Navigate = useNavigate()
  let { quote_asset, base_asset, symbol, last_price, change } = el;


  let quote_currency = currency?.find(
    (il) => il.id == el?.currency?.toLowerCase()
  );
  let base_currency = currency?.find(
    (el) => el.id == base_asset?.toLowerCase()
  );

  let base_logo = base_currency?.logo;
  let quote_logo = quote_currency?.logo;

  return (
    <>
      <tr className={clsx(classes.row, classes.valueContainer)}>
        <td className={clsx(classes.item, classes.firstItem)}>
          <div className={classes.logoAndNameContainer}>
            <img
              src={quote_logo}
              alt="#"
              className={classes.logo}
            />
            <div className={classes.nameContainer}>
              <p>{new Date(el.created_at).toLocaleString()}</p>
              <span>{symbol}</span>
            </div>
          </div>
        </td>

        <td className={classes.item}>{el?.deposit_network ? el.deposit_network.toUpperCase() : "Internal"}</td>
        <td
          className={[
            classes.item,

            2 > 0
              ? clsx(classes.positiveAmount, classes.amount)
              : clsx(classes.negativeAmount, classes.amount),
          ].join(" ")}
        >
          {el?.currency?.toUpperCase()}
        </td>
        <td className={classes.item}>
          <div className={classes.buttonContainer}>
            <Button btnTransparent onClick={() => {
              Navigate("/history")
            }}>
              View More
            </Button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default SingleRow;
