import { close, CloseIcon } from "assets";
import classes from "./Modal.module.css";
import clsx from "clsx";
import { useTheme } from "ThemeContext/ThemeContext";

const Modal = ({
  isActive,
  onClose,
  className,
  styled,
  xShort,
  children,
  ...rest
}) => {
  const { isDarkTheme } = useTheme();
  return (
    <>
      {isActive && (
        <div
          className={clsx(
            isActive && classes.active,
            classes.modalOverlay,
            !isDarkTheme && classes.lightTheme
          )}
          onClick={onClose}
        />
      )}
      <div
        className={clsx(
          className,
          isActive && classes.active,
          xShort && classes.xShort,
          styled && classes.styled,
          classes.modal
        )}
        {...rest}
      >
        <div onClick={onClose} className={classes.close}>
          {/* <img src={close} alt="close" /> */}
          <CloseIcon
            color={isDarkTheme ? "#fff" : "#000"}
            bg={isDarkTheme ? "#222327" : "transparent"}
          />
        </div>
        {children}
      </div>
    </>
  );
};

export default Modal;
