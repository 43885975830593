import React, { useEffect, useState } from "react";
import classes from "./OrdersPage.module.css";
import clsx from "clsx";
import { magnifier } from "assets";
import OpenOrders from "components/Orders/OpenOrders/OpenOrders";
import OrderHistory from "components/Orders/OrderHistory/OrderHistory";
import TradeHistory from "components/Orders/TradeHistory/TradeHistory";
import TransactionHistory from "components/Orders/TransactionHistory/TransactionHistory";
import { useDispatch } from "react-redux";
import { getTransactions } from "../../redux/external_transactions";
const openOrders = [
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  // Add more data objects as needed
];
const orderHistory = [
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    status: "Completed",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    status: "Expired",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    status: "Canceled",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    status: "Completed",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    status: "Expired",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    status: "Canceled",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    status: "Completed",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    status: "Expired",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    status: "Canceled",
  },
  // Add more data objects as needed
];
const tradeHistory = [
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  // Add more data objects as needed
];
const transactionHistory = [
  {
    time: "02-04 13:45:54",
    type: "Withdraw",
    wallet: "Spot",
    coin: "BTC",
    amount: "4,729.9288 USDT",
    destination: "B3F0725999B7a16516235A709D8b1c871370eb42",
    trxId: "0x063834efe214f4199b1ad7181ce8c5ced3e15d271c8e866da7c89e86ee629cfb",
    status: "Completed",
  },
  {
    time: "02-04 13:45:54",
    type: "Transfer",
    wallet: "Funding",
    coin: "ETH",
    amount: "4,729.9288 USDT",
    destination: "B3F0725999B7a16516235A709D8b1c871370eb42",
    trxId: "0x063834efe214f4199b1ad7181ce8c5ced3e15d271c8e866da7c89e86ee629cfb",
    status: "Canceled",
  },
  {
    time: "02-04 13:45:54",
    type: "Deposit",
    wallet: "Funding",
    coin: "ORDI",
    amount: "4,729.9288 USDT",
    destination: "B3F0725999B7a16516235A709D8b1c871370eb42",
    trxId: "0x063834efe214f4199b1ad7181ce8c5ced3e15d271c8e866da7c89e86ee629cfb",
    status: "Completed",
  },
  {
    time: "02-04 13:45:54",
    type: "Withdraw",
    wallet: "Spot",
    coin: "BTC",
    amount: "4,729.9288 USDT",
    destination: "B3F0725999B7a16516235A709D8b1c871370eb42",
    trxId: "0x063834efe214f4199b1ad7181ce8c5ced3e15d271c8e866da7c89e86ee629cfb",
    status: "Completed",
  },
  {
    time: "02-04 13:45:54",
    type: "Transfer",
    wallet: "Funding",
    coin: "ETH",
    amount: "4,729.9288 USDT",
    destination: "B3F0725999B7a16516235A709D8b1c871370eb42",
    trxId: "0x063834efe214f4199b1ad7181ce8c5ced3e15d271c8e866da7c89e86ee629cfb",
    status: "Canceled",
  },
  {
    time: "02-04 13:45:54",
    type: "Deposit",
    wallet: "Funding",
    coin: "ORDI",
    amount: "4,729.9288 USDT",
    destination: "B3F0725999B7a16516235A709D8b1c871370eb42",
    trxId: "0x063834efe214f4199b1ad7181ce8c5ced3e15d271c8e866da7c89e86ee629cfb",
    status: "Completed",
  },
  {
    time: "02-04 13:45:54",
    type: "Withdraw",
    wallet: "Spot",
    coin: "BTC",
    amount: "4,729.9288 USDT",
    destination: "B3F0725999B7a16516235A709D8b1c871370eb42",
    trxId: "0x063834efe214f4199b1ad7181ce8c5ced3e15d271c8e866da7c89e86ee629cfb",
    status: "Completed",
  },
  {
    time: "02-04 13:45:54",
    type: "Transfer",
    wallet: "Funding",
    coin: "ETH",
    amount: "4,729.9288 USDT",
    destination: "B3F0725999B7a16516235A709D8b1c871370eb42",
    trxId: "0x063834efe214f4199b1ad7181ce8c5ced3e15d271c8e866da7c89e86ee629cfb",
    status: "Canceled",
  },
  {
    time: "02-04 13:45:54",
    type: "Deposit",
    wallet: "Funding",
    coin: "ORDI",
    amount: "4,729.9288 USDT",
    destination: "B3F0725999B7a16516235A709D8b1c871370eb42",
    trxId: "0x063834efe214f4199b1ad7181ce8c5ced3e15d271c8e866da7c89e86ee629cfb",
    status: "Completed",
  },
  {
    time: "02-04 13:45:54",
    type: "Withdraw",
    wallet: "Spot",
    coin: "BTC",
    amount: "4,729.9288 USDT",
    destination: "B3F0725999B7a16516235A709D8b1c871370eb42",
    trxId: "0x063834efe214f4199b1ad7181ce8c5ced3e15d271c8e866da7c89e86ee629cfb",
    status: "Completed",
  },
  {
    time: "02-04 13:45:54",
    type: "Transfer",
    wallet: "Funding",
    coin: "ETH",
    amount: "4,729.9288 USDT",
    destination: "B3F0725999B7a16516235A709D8b1c871370eb42",
    trxId: "0x063834efe214f4199b1ad7181ce8c5ced3e15d271c8e866da7c89e86ee629cfb",
    status: "Canceled",
  },
  {
    time: "02-04 13:45:54",
    type: "Deposit",
    wallet: "Funding",
    coin: "ORDI",
    amount: "4,729.9288 USDT",
    destination: "B3F0725999B7a16516235A709D8b1c871370eb42",
    trxId: "0x063834efe214f4199b1ad7181ce8c5ced3e15d271c8e866da7c89e86ee629cfb",
    status: "Completed",
  },

  // Add more data objects as needed
];
const OrdersPage = () => {
  let dispatch = useDispatch();
  async function dispatchAll() {
    console.log("dispatching txns")
    dispatch(getTransactions());
  }

  // useEffect with 3 seconds interval to fetch data from the server

  useEffect(() => {
    const interval = setInterval(() => {
      dispatchAll();
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  const [activeTab, setActiveTab] = useState("Transaction History");
  const [searchValue, setSearchValue] = useState("");
  return (
    <div className={clsx("container", classes.wrapper)}>
      <h2 className={classes.heading}>Account History</h2>
      <div className={classes.header}>
        <div className={classes.tabs}>
          {/* {tabs.map((el, i) => (
            <p
              className={clsx(
                classes.tab,
                i === activeTab && classes.activeTab
              )}
              key={i}
              onClick={() => setActiveTab(i)}
            >
              {el}
            </p>
          ))} */}
          {/* <p
            className={clsx(
              classes.tab,
              activeTab === "Open Orders" && classes.activeTab
            )}
            onClick={() => setActiveTab("Open Orders")}
          >
            Open Orders({openOrders.length})
          </p>
          <p
            className={clsx(
              classes.tab,
              activeTab === "Order History" && classes.activeTab
            )}
            onClick={() => setActiveTab("Order History")}
          >
            Order History
          </p>
          <p
            className={clsx(
              classes.tab,
              activeTab === "Trade History" && classes.activeTab
            )}
            onClick={() => setActiveTab("Trade History")}
          >
            Trade History
          </p>{" "} */}
          <p
            className={clsx(
              classes.tab,
              activeTab === "Transaction History" && classes.activeTab
            )}
            onClick={() => setActiveTab("Transaction History")}
          >
            Transaction History
          </p>
        </div>

        <div className={classes.searchContainer}>
          <img src={magnifier} alt="magnifier" className={classes.icon} />
          <input
            type="search"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </div>
      {activeTab === "Open Orders" && <OpenOrders data={openOrders} />}
      {activeTab === "Order History" && <OrderHistory data={orderHistory} />}
      {activeTab === "Trade History" && <TradeHistory data={tradeHistory} />}
      {activeTab === "Transaction History" && (
        <TransactionHistory data={transactionHistory} />
      )}
    </div>
  );
};

export default OrdersPage;
