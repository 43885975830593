import React from "./BuySellModal.module.css";

import BuySell from "components/Spot/BuySell/BuySell";
import clsx from "clsx";
import classes from "./BuySellModal.module.css";
import { useTheme } from "ThemeContext/ThemeContext";

const BuySellModal = ({ isActive, onClose, onlyBuyModal, onlySellModal }) => {
  const { isDarkTheme } = useTheme();

  return (
    <>
      <div
        className={clsx(
          isActive && classes.active,
          classes.modalOverlay,
          !isDarkTheme && classes.lightTheme
        )}
        onClick={onClose}
      />
      <div
        className={clsx(
          isActive && classes.active,

          classes.modal
        )}
      >
        <BuySell
          onClose={onClose}
          onlyBuyModal={onlyBuyModal}
          onlySellModal={onlySellModal}
        />
      </div>
    </>
  );
};

export default BuySellModal;
