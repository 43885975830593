import React, { useEffect } from "react";
import classes from "./BalanceFeesWithdrawalLimit.module.css";
import { useState } from "react";

const BalanceFeesWithdrwalLimit = ({ selectedNetwork, tokenBalance, selectedCoin, networkConfig }) => {

  let [feeCurrency, setFeeCurrency] = useState(null);


  function getFee() {
    if (networkConfig?.withdraw_fee_currency == "WithdrawCurrency") {
      setFeeCurrency(selectedCoin?.symbol)

      return 2.75;
    } else {
      setFeeCurrency(selectedNetwork?.native_currency_id?.toUpperCase())
      return 0;
    }
  }


  useEffect(() => {
    getFee()
  }, [
    selectedCoin, networkConfig
  ])

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.wrapper}>
        <p className={classes.label}>Available Balance</p>

        <p className={classes.value}>
          {tokenBalance?.toFixed(2) ? tokenBalance.toFixed(2) : 0.0}
          <span className={classes.currency}>{selectedCoin?.symbol?.toUpperCase()}</span>
        </p>
      </div>{" "}
      <div className={classes.wrapper}>
        <p className={classes.label}>Minimum Withdrawal</p>

        <p className={classes.value}>
          {networkConfig?.withdraw_fee_currency == "WithdrawCurrency" ? networkConfig.withdrawal_fee : "--"}
          <span className={classes.currency}>{selectedCoin?.symbol?.toUpperCase()}</span>
        </p>
      </div>{" "}
      <div className={classes.wrapper}>
        <p className={classes.label}>Fees</p>
        <p className={classes.value}>
          {networkConfig?.withdrawal_fee}
          <span className={classes.currency}>{feeCurrency}</span>
        </p>
      </div>{" "}
      <div className={classes.wrapper}>
        <p className={classes.label}>24h Withdrawal Limit</p>
        <p className={classes.value}>
          {(8000000).toFixed(2)}
          <span className={classes.currency}>USD</span>
        </p>
      </div>
    </div>
  );
};

export default BalanceFeesWithdrwalLimit;
