import Section from "components/common/Section/Section";
import PageHeading from "components/common/PageHeading/PageHeading";
import Navigator from "components/common/Navigator/Navigator";
import Input from "components/common/Input/Input";
import Button from "components/Button/Button";
import classes from "./ForgotPassword.module.css";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [user, setUser] = useState({});

  const Navigate = useNavigate();

  async function forgotPasswordHandler(e) {
    e.preventDefault();
    let api = process.env.REACT_APP_API;
    let route = "/auth/reset-password";
    let url = api + route;


    if (!user.includes("@")) {
      // then check if it is a phone number
      let phone = user;
      let phoneRegex = /^[0-9]{15}$/;
      // if (!phoneRegex.test(phone)) {
      //   alert("Invalid Phone Number");
      //   return;
      // }

    }

    let method = {
      method: user.includes("@") ? "Email" : "Phone",
    }



    let body = {
      method: method.method,
      data: user
    }

    try {
      let { data, status } = await axios.post(url, body);

      if (status === 200) {
        localStorage.setItem("resetPassword", JSON.stringify(body));
        Navigate("/set-new-password")
      }
    } catch (e) {

    }

  }


  return (
    <Section withPadding xShort className={classes.forgotPassword}>
      <PageHeading
        heading="Forgot Password"
        subHeading="Don’t worry about forgetting password. Fill up your credential to recover your account."
      />

      <form action="" className={classes.form}>
        <Input rootClassName={classes.input}
          onChange={(e) => setUser(e.target.value)}

          placeholder="Email / Phone" />

        <Button
          // to="/verify-account"
          onClick={forgotPasswordHandler}
          className={classes.btn}
          size="md"
          btnPrimary
        >
          Continue
        </Button>
      </form>

      <Navigator to="/login">Back to Login</Navigator>
    </Section>
  );
};

export default ForgotPassword;
