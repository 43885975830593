import React, { useState } from "react";
import clsx from "clsx";
import Modal from "components/common/Modal/Modal";
import classes from "./FundingSendModal.module.css";
import Heading from "components/common/Heading/Heading";
import Input from "components/common/Input/Input";
import Button from "components/Button/Button";
import { checkIfNumber } from "components/utils/isNumber";
import { toast } from "react-toastify";
import { useTheme } from "ThemeContext/ThemeContext";
import { useSelector } from "react-redux";

const FundingSendModal = ({ isActive, onClose, setEnterAmountModalActive }) => {
  const tabs = [`${process.env.REACT_APP_SHORTNAME} ID`, "Email", "Phone"];

  let user = useSelector((state) => state.user.userInfo);
  const [activeTab, setActiveTab] = useState(
    `${process.env.REACT_APP_SHORTNAME} ID`
  );
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [brandNameUid, setBrandNameUid] = useState("");
  const emailHandler = (e) => {
    setEmail(e.target.value);
  };
  const { isDarkTheme } = useTheme();
  return (
    <Modal
      className={clsx(
        classes.wrapper,
        "overflow",
        !isDarkTheme && classes.lightTheme
      )}
      isActive={isActive}
      onClose={onClose}
      xShort
      styled
    >
      <Heading heading={`${process.env.REACT_APP_SHORTNAME} Pay`} wFull />
      <div className={classes.headingContainer}>
        <Heading heading="Send To" center lg />
        <p className={classes.text}>
          Send digital asssets to {process.env.REACT_APP_SHORTNAME} users with 0
          fees.
        </p>
      </div>
      <div className={classes.tabs}>
        {tabs.map((tab, i) => (
          <p
            className={clsx(
              classes.tab,
              tab.toLowerCase() === activeTab.toLowerCase() && classes.activeTab
            )}
            key={i}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </p>
        ))}
      </div>
      {activeTab === "Email" && (
        <Input
          rootClassName={classes.input}
          label="Email"
          type="email"
          value={email}
          placeholder="Email"
          onChange={emailHandler}
        />
      )}
      {activeTab === "Phone" && (
        <Input
          rootClassName={classes.input}
          onKeyDown={(event) => checkIfNumber(event)}
          type="number"
          placeholder="Phone number"
          label="Phone Number"
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
        />
      )}{" "}
      {activeTab === `${process.env.REACT_APP_SHORTNAME} ID` && (
        <Input
          rootClassName={classes.input}
          label={`${process.env.REACT_APP_SHORTNAME} ID`}
          type="text"
          placeholder={`${process.env.REACT_APP_SHORTNAME} ID`}
          value={brandNameUid}
          onChange={(e) => setBrandNameUid(e.target.value)}
        />
      )}
      <div className={classes.buttonContainer}>
        {" "}
        <Button btnGray onClick={onClose} size="md">
          Cancel
        </Button>
        <Button
          btnPrimary
          onClick={() => {
            if (activeTab === "Email") {
              toast.error("Email is not supported yet", {
                toastId: "email-not-supported",
                theme: "dark",
                position: "top-center",
              });
              return;
            }

            if (activeTab === "Phone") {
              toast.error("Phone is not supported yet", {
                toastId: "phone-not-supported",
                theme: "dark",
                position: "top-center",
              });
              return;
            }
            console.log("brandNameUid", brandNameUid);
            console.log("user.platform_uid", user.platform_uid);
            if (user.platform_id === brandNameUid) {
              toast.error("Self transfer is not allowed", {
                toastId: "self-transfer-not-allowed",
                theme: "dark",
                position: "top-center",
              });
              return;
            }

            localStorage.setItem("pay_method", activeTab);
            localStorage.setItem("pay_to", brandNameUid);


            setEnterAmountModalActive(true);

            // onClose();
          }}
          size="md"
        >
          Continue
        </Button>
      </div>
    </Modal>
  );
};

export default FundingSendModal;
