import React from "react";
import classes from "./DowloadMobileApp.module.css";
import Modal from "components/common/Modal/Modal";
import Button from "components/Button/Button";
import { AppStore, PlayStore, appStore, playStore, qrCode } from "assets";
import Hr from "components/common/Hr/Hr";
import { useTheme } from "ThemeContext/ThemeContext";

const DownloadMobileAppModal = ({ isActive, onClose }) => {
  const { isDarkTheme } = useTheme();
  return (
    <Modal
      className={classes.downloadModal}
      isActive={isActive}
      onClose={onClose}
      styled
    >
      <h3>Download Mobile App</h3>
      <p>
        Scan QR Code with your phone. It will redirect you to app installation
        page.
      </p>
      <Hr shortMargin />
      <div className={classes.container}>
        <div className={classes.left}>
          <img src={qrCode} alt="#" />
          <Button
            className={classes.button}
            onClick={() => {
              alert("iOS app will be available soon");
              // setIsQrModalActive(true)
            }}
            btnBlack
            size="lg"
          >
            {" "}
            <AppStore color={isDarkTheme ? "#fff" : "#000"} />
            {/* <img src={appStore} alt="app store" className={classes.logo} /> */}
            <div className={classes.box}>
              <h5>Download from the</h5>
              <h6>App Store</h6>
            </div>
          </Button>
        </div>
        <div className={classes.or}>
          <div className={classes.orInner}>or</div>
        </div>
        <div className={classes.left}>
          <img src={qrCode} alt="#" />
          <Button
            className={classes.button}
            onClick={() => {
              alert("Android app will be available soon");
              // setIsQrModalActive(true)
            }}
            btnBlack
            size="lg"
          >
            <PlayStore color={isDarkTheme ? "#fff" : "#000"} />
            {/* <img src={playStore} alt="play store" className={classes.logo} /> */}
            <div className={classes.box}>
              <h5>GET IT ON</h5>
              <h6>Google Play</h6>
            </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadMobileAppModal;
