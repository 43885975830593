import React from "react";

import classes from "./SingleRow.module.css";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { parseTickers } from "../../../../../redux/tickers";
import { useTheme } from "ThemeContext/ThemeContext";
const SingleRow = ({
  time,
  pair,
  type,
  side,
  price,
  amount,
  initial_quantity,
  executed_quantity,
  unFilled,
  triggerCondition,
  total,
  status,
  timestamp,
}) => {
  const { isDarkTheme } = useTheme();
  let date = new Date(timestamp / 1000).toLocaleString();
  let markets = useSelector(parseTickers);
  let market = markets.find((market) => market.market_id === pair);
  return (
    <>
      <tr
        className={clsx(
          classes.row,
          classes.valueContainer,
          !isDarkTheme && classes.lightTheme
        )}
      >
        <td className={clsx(classes.item, classes.firstItem)}>{date}</td>
        <td className={clsx(classes.item, classes.pair)}>{pair}</td>
        <td className={classes.item}>{type}</td>
        <td
          className={clsx(
            classes.item,
            side.toLowerCase() === "buy" && classes.buy,
            side.toLowerCase() === "sell" && classes.sell
          )}
        >
          {side}
        </td>
        <td className={classes.item}>
          {price} {market?.quote_asset}
        </td>
        <td className={classes.item}>
          {initial_quantity?.toFixed(2)} {market?.base_asset}
        </td>
        <td className={classes.item}>
          {executed_quantity?.toFixed(2)} {market?.base_asset}
        </td>
        <td className={classes.item}>
          {(initial_quantity - executed_quantity)?.toFixed(2)}{" "}
          {market?.base_asset}
        </td>
        {/* <td className={classes.item}>{"--"}</td> */}
        <td className={classes.item}>
          {total.toFixed(2)} {market?.quote_asset}
        </td>

        <td
          className={clsx(
            classes.item,
            classes.lastItem,
            status.toLowerCase() === "filled" && classes.completed,
            status.toLowerCase() === "expired" && classes.expired,
            status.toLowerCase() === "canceled" && classes.canceled
          )}
        >
          {status}
        </td>
      </tr>
    </>
  );
};

export default SingleRow;
