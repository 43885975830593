import { useEffect, useRef, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import clsx from "clsx";

import { magnifier } from "assets";
import classes from "./CurrencyDropdown.module.css";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useTheme } from "ThemeContext/ThemeContext";

const listItems = [
  {
    label: "English",
  },
  {
    label: "العربية",
  },
  {
    label: "العربية (البحرين)",
  },
  {
    label: "български",
  },
  {
    label: "Deutsch (Schweiz)",
  },
  {
    label: "Español (España)",
  },
  {
    label: "Bahasa Indonesia",
  },
];

const CurrencyDropdown = ({
  isActive,
  selectedValue,
  setIsActive,
  onSelect,
  children,
  className,
  dropdownRef,
  dropdownItems,
}) => {
  const { isDarkTheme } = useTheme();
  //   const [filteredItems, setFilteredItems] = useState(listItems);
  //   const inputRef = useRef();

  //   const [searchValue, setSearchValue] = useState("");

  //   useEffect(() => {
  //     inputRef.current.focus();
  //   }, [isActive]);

  //   useEffect(() => {
  //     setFilteredItems(
  //       listItems.filter((el) =>
  //         el.label.toLowerCase().includes(searchValue.toLowerCase())
  //       )
  //     );
  //   }, [searchValue]);
  useOnClickOutside(dropdownRef, () => setIsActive(false));
  return (
    <div
      className={clsx(
        classes.dropdown,
        className,
        !isDarkTheme && classes.lightTheme
      )}
    >
      <div
        className={classes.selectedValue}
        onClick={() => {
          if (isActive) {
            setIsActive(false);
          } else {
            setIsActive(true);
          }
        }}
      >
        <div className={classes.nameAndlogo}>
          <img
            src={
              selectedValue?.logo
                ? selectedValue?.logo
                : "https://cdn-icons-png.flaticon.com/128/4047/4047250.png"
            }
            alt="#"
            className={classes.logo}
          />
          <p className={classes.name}>
            {selectedValue?.name ? selectedValue?.name : "Select a token"}
          </p>
        </div>
        {isActive ? (
          <FaAngleUp className={classes.arrow} />
        ) : (
          <FaAngleDown className={classes.arrow} />
        )}
      </div>
      <div className={clsx(classes.dropdownMain, isActive && classes.active)}>
        {/* <div className={classes.searchContainer}>
          <img src={magnifier} alt="magnifier" className={classes.icon} />
          <input
            ref={inputRef}
            type="search"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div> */}

        <div className={classes.list}>
          {/* {filteredItems.map((el, idx) => {
            return (
              <div
                key={"lang-list-item-" + idx}
                className={clsx(
                  classes.listItem,
                  selectedValue === el.label && classes.active
                )}
                onClick={() => {
                  setSearchValue("");
                  onSelect(el.label);
                }}
              >
                {el.label}
              </div>
            );
          })} */}

          {dropdownItems.map((coin, i) => (
            <div
              className={classes.listItem}
              key={i}
              onClick={() => onSelect(coin)}
            >
              <img src={coin.logo} alt="#" className={classes.logo} />
              <p className={classes.name}>{coin?.symbol?.toUpperCase()}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CurrencyDropdown;
