import React from "react";
import classes from "./Overview.module.css";
import Heading from "components/common/Heading/Heading";
import Button from "components/Button/Button";
import { useTheme } from "ThemeContext/ThemeContext";
import clsx from "clsx";
import { useSelector } from "react-redux";

const Overview = ({ refData }) => {


  let refs = refData?.referrers;
  console.log(refData)
  //combine all ref.earnings

  let total_earnings = refs?.reduce((acc, ref) => {
    return acc + ref.earnings;
  }, 0);

  let success_fully_traded = refs?.filter((ref) => ref.is_traded == true).length;
  let successfully_deposited = refs?.filter((ref) => ref.is_deposited == true).length;
  const { isDarkTheme } = useTheme();
  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <Heading heading="Overview" lg />

      <div className={classes.container}>
        <div className={classes.boxWithButton}>
          <div className={classes.labelAndValue}>
            <p className={classes.label}>Total Earning (USDT)</p>
            <p className={classes.value}>~ ${total_earnings}</p>
          </div>
          <Button
            size="sm"
            btnPrimary
            onClick={() => { }}
            className={classes.button}
          >
            Wallets
          </Button>
        </div>{" "}

        <div className={classes.box}>
          <p className={classes.label}>Successfully Joined</p>
          <p className={classes.value}>{refs?.length}</p>
        </div>{" "}
        <div className={classes.box}>
          <p className={classes.label}>Successfully Traded</p>
          <p className={classes.value}>{success_fully_traded}</p>
        </div>
        <div className={classes.box}>
          <p className={classes.label}>Successfully Deposited</p>
          <p className={classes.value}>{successfully_deposited}</p>
        </div>{" "}
      </div>
    </div>
  );
};

export default Overview;
