import React from "react";

import classes from "./SingleRow.module.css";
import clsx from "clsx";
import { useState, useEffect } from "react";

const SingleRow = ({ no, uuid, earnings, is_deposited, is_kyc_verified, is_traded }) => {

  const [completedTasks, setCompletedTasks] = useState([]);

  useEffect(() => {
    let tasks = [];
    if (is_deposited) {
      tasks.push("Deposited");
    }
    if (is_kyc_verified) {
      tasks.push("KYC Verified");
    }
    if (is_traded) {
      tasks.push("Traded");
    }
    tasks.push("Joined");
    setCompletedTasks(tasks);
  }, [is_deposited, is_kyc_verified, is_traded]);
  return (
    <>
      <tr className={clsx(classes.row, classes.valueContainer)}>
        {/* <td className={classes.item}>{no}</td> */}
        <td className={clsx(classes.item)}>{uuid}</td>
        <td className={clsx(classes.item)}>~ {earnings} USD</td>{" "}
        <td className={clsx(classes.item, classes.lastItem)}>
          <div className={classes.statusContainer}>
            {completedTasks?.map((status, i) => (
              <p
                className={clsx(
                  classes.lastItem,
                  classes.status,
                  status.toLowerCase() === "joined"
                    ? classes.joined
                    : status.toLowerCase() === "traded"
                      ? classes.trade
                      : status.toLowerCase() === "deposited"
                        ? classes.trade
                        : status.toLowerCase() === "kyc verified"
                          ? classes.trade
                          : null
                )}
                key={i}
              >
                {" "}
                {status ? status : "Joined"}
              </p>
            ))}
          </div>
        </td>{" "}
      </tr>
    </>
  );
};

export default SingleRow;
