import React, { useState, useMemo } from "react";
import Pagination from "common/Pagination/Pagination";
import classes from "./DataTable.module.css";

import clsx from "clsx";
import SingleRow from "./SingleRow/SingleRow";

const DataTable = ({ data }) => {
  console.log(data)
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return data?.Ok?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, itemsPerPage]);

  return (
    <div className={classes.tableContainer}>
      <table className={classes.table}>
        <tbody>
          <tr className={classes.row}>
            <th className={clsx(classes.heading, classes.textAlignCenter)}>
              <p className={classes.tableHeading}>Time</p>
            </th>
            <th className={classes.heading}>
              <p className={classes.tableHeading}>Type</p>
            </th>
            <th className={classes.heading}>
              <p className={classes.tableHeading}>Network</p>
            </th>
            <th className={classes.heading}>
              <p className={classes.tableHeading}>Amount</p>
            </th>
            <th className={classes.heading}>
              <p className={clsx(classes.tableHeading, classes.lastHeading)}>
                Status
              </p>
            </th>
          </tr>
          {currentTableData?.sort((a, b) => b.timestamp - a.timestamp)
            .map((el, index) => (
              <SingleRow {...el} key={index} index={index} />
            ))}
        </tbody>
      </table>{" "}
      <div className={classes.pagination}>
        {/* <Pagination
          currentPage={currentPage}
          totalCount={data.length}
          pageSize={itemsPerPage}
          onPageChange={(page) => setCurrentPage(page)}
          siblingCount={0}
        /> */}
      </div>
    </div>
  );
};

export default DataTable;
