import React, { useEffect, useState } from "react";

import classes from "./OverView.module.css";
import {
  binance,
  bitcoin,
  ethereum,
  hotListingIcon,
  newListingIcon,
  topGainerIcon,
  tether,
} from "assets";
import clsx from "clsx";
import SingleRow from "./SingleRow";
import ViewMore from "components/common/ViewMore/ViewMore";
import { useTheme } from "ThemeContext/ThemeContext";

const OverView = ({ parsedTickers, currency }) => {
  const { isDarkTheme } = useTheme();
  const [topGainers, setTopGainers] = useState([]);


  // let num_of_tickers_neeeded = 6;
  // parsedTickers = parsedTickers?.slice(0, num_of_tickers_neeeded);

  const hotListing = {
    title: "Hot Markets",
    icon: hotListingIcon,
    coins: [
      {
        coin: { logo: bitcoin, name: "BTC" },
        price: "$41,845.8",
        change24h: -10.2,
      },
      {
        coin: { logo: tether, name: "LTC" },
        price: "$67.43",
        change24h: 10.2,
      },
      {
        coin: { logo: ethereum, name: "ALT" },
        price: "$9.95",
        change24h: -10.2,
      },
    ],
  };
  const topGainer = {
    title: "Top Gainers",
    icon: topGainerIcon,
    coins: [
      {
        coin: { logo: bitcoin, name: "BTC" },
        price: "$41,845.8",
        change24h: -10.2,
      },
      {
        coin: { logo: tether, name: "LTC" },
        price: "$67.43",
        change24h: 10.2,
      },
      {
        coin: { logo: ethereum, name: "ALT" },
        price: "$9.95",
        change24h: -10.2,
      },
    ],
  };
  const newListing = {
    title: "Top Losers",
    icon: newListingIcon,
    coins: [
      {
        coin: { logo: bitcoin, name: "BTC" },
        price: "$41,845.8",
        change24h: -10.2,
      },
      {
        coin: { logo: tether, name: "LTC" },
        price: "$67.43",
        change24h: 10.2,
      },
      {
        coin: { logo: ethereum, name: "ALT" },
        price: "$9.95",
        change24h: -10.2,
      },
    ],
  };
  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <div className={classes.box}>
        <div className={classes.header}>
          <h6>
            <img src={hotListing.icon} alt="#" /> {hotListing.title}
          </h6>

          {/* <ViewMore onClick={() => { }} /> */}
        </div>
        <div className={classes.coins}>
          {parsedTickers?.slice(0, 10).map((el, i) => (
            <SingleRow el={el} key={i} currency={currency} />
          ))}
        </div>
      </div>
      <div className={classes.box}>
        <div className={classes.header}>
          <h6>
            <img src={topGainer.icon} alt="#" /> {topGainer.title}
          </h6>

          {/* <ViewMore onClick={() => { }} /> */}
        </div>
        <div className={classes.coins}>
          {parsedTickers?.sort((a, b) => b.price_change_24hr - a.price_change_24hr).slice(0, 10)
            .map((el, i) => (
              <SingleRow el={el} key={i} currency={currency} />
            ))}
        </div>
      </div>

      <div className={classes.box}>
        <div className={classes.header}>
          <h6>
            <img src={newListing.icon} alt="#" />
            {newListing.title}
          </h6>

          {/* <ViewMore onClick={() => { }} /> */}
        </div>
        <div className={classes.coins}>
          {parsedTickers?.sort((a, b) => a.price_change_24hr - b.price_change_24hr).slice(0, 10).map((el, i) => (
            <SingleRow el={el} key={i} currency={currency} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OverView;
