import clsx from "clsx";

import classes from "./CancelPopup.module.css";
import { cancel } from "assets";
import Button from "components/Button/Button";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const CancelPopup = ({ isActive, setCancel, setIsShowCancelPopup, orderID, pair, setOpenOrders }) => {
  async function cancelOrder(orderID) {
    let api = process.env.REACT_APP_API
    let route = "/spot/order/" + pair + "/" + orderID;
    let url = api + route;

    try {
      const { data, status } = await axios.delete(url,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("auth-token")}`,
          },
        })
      if (status === 200) {
        toast.success("Order Cancelled Successfully", {
          position: "top-center",
          theme: "dark",
          toastId: "order-cancelled"
        });

        setOpenOrders((prev) => {
          console.log(prev, "prev");
          return prev.filter((el) => el.id !== orderID);
        });
      }
    } catch (e) {
      alert(e)
    }


  }
  return (
    <div className={clsx(classes.dropdown)}>
      <div className={clsx(classes.dropdownMain, isActive && classes.active)}>
        <div className={classes.container}>
          <img src={cancel} alt="#" className={classes.img} />
          <div className={classes.textContainer}>
            <h4 className={classes.heading}>Cancel Order</h4>

            <li className={classes.info}>
              Are you sure you want to cancel this order?
            </li>
            <div className={classes.buttonContainer}>
              <Button
                btnGray
                size="sm"
                onClick={() => setIsShowCancelPopup(false)}
              >
                No
              </Button>{" "}
              <Button
                btnWarning
                size="sm"
                onClick={() => {
                  // setCancel(true);
                  // setIsShowCancelPopup(false);
                  cancelOrder(orderID);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelPopup;
//     onClick={() => {
//      el.action();
//      onSelect(el.label);
//}}
