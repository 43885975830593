import React, { useRef, useState } from "react";

import classes from "./SingleRow.module.css";
import clsx from "clsx";

import { more2 } from "assets";
import useOnClickOutside from "hooks/useOnClickOutside";
import Dropdown from "../../../Dropdown/Dropdown";
import { useNavigate } from "react-router-dom";
const dropdownItems = [
  {
    label: "Deposit",
    to: "/wallet/crypto/deposit",
  },
];

const SingleRow = ({ wallet, amount, usdAmount, available, action }) => {
  const Navigate = useNavigate()
  const dropdownRef = useRef(null);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(dropdownItems[0].label);
  useOnClickOutside(dropdownRef, () => setIsDropdownActive(false));
  return (
    <>
      <tr
        className={clsx(classes.row, classes.valueContainer)}>
        <td
          onClick={() => {
            Navigate(`/wallet/${wallet.name}`)
          }}
          className={clsx(classes.item, classes.firstItem)}>
          <div className={classes.logoAndNameContainer}>
            <img src={wallet.logo} alt="#" className={classes.logo} />

            <p className={classes.name}>{wallet.name}</p>
          </div>
        </td>

        <td className={classes.item}>
          <div className={classes.priceContainer}>
            <p className={classes.price}>{amount.toFixed(2) + " USDT"}</p>
            <span>~ ${usdAmount.toFixed(2)}</span>
          </div>
        </td>

        <td className={classes.item}>{"~" + available.toFixed(2)} USDT</td>
        <td className={classes.item}>
          <div className={classes.buttonContainer}>
            <div
              className={classes.moreContainer}
              onClick={() => {
                if (isDropdownActive) {
                  setIsDropdownActive(false);
                } else {
                  setIsDropdownActive(true);
                }
              }}
            >
              <img src={more2} alt="#" className={classes.more} />
              {isDropdownActive && (
                <div ref={dropdownRef}>
                  <Dropdown
                    dontShowCurrenValue
                    isActive={isDropdownActive}
                    selectedValue={selectedValue}
                    dropdownItems={dropdownItems}
                    isDropdownActive={isDropdownActive}
                    onSelect={(val) => {
                      setSelectedValue(val);
                      setIsDropdownActive(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default SingleRow;
