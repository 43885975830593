import Button from "components/Button/Button";
import { successIcon } from "assets";
import classes from "./CreateStakeModal.module.css";

import clsx from "clsx";
import Modal from "components/common/Modal/Modal";
import Heading from "components/common/Heading/Heading";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const CreateStakeModal = ({
  isActive,
  amount,
  onClose,
  selectedCurrency,
  selectedDuration,
  stakingInstance,
  fn,
}) => {
  let stakingInstances = useSelector(
    (state) => state.stakingInstances.stakingInstances
  );

  return (
    <Modal
      className={classes.wrapper}
      isActive={isActive}
      onClose={onClose}
      styled
    >
      <div className={classes.container}>
        <div className={classes.textContainer}>
          <Heading wFull center lg heading="Staking Summary" />
          <p className={classes.text}>
            This is the summary about your staking info. Please check again
            before confirming.
          </p>
        </div>

        <div className={classes.infoContainer}>
          <div className={classes.spaceBetween}>
            <p className={classes.label}>Stake Amount</p>
            <p className={classes.value}>
              {amount} {selectedCurrency?.symbol.toUpperCase()}
            </p>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <p className={classes.label}>Duration</p>
            <p className={classes.value}>{selectedDuration?.duration == 0 ? "Flexible" : selectedDuration?.duration + " Days"}</p>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <p className={classes.label}>APY</p>
            <p className={clsx(classes.value, classes.apy)}>
              {stakingInstance?.apy}%
            </p>
          </div>{" "}
          {/* <div className={classes.spaceBetween}>
            <p className={classes.label}>Fee</p>
            <p className={classes.value}>0.60 USD</p>
          </div>{" "} */}
        </div>
        <div className={classes.buttonContainer}>
          <Button onClick={onClose} className={classes.btn} btnGray size="md">
            Cancel
          </Button>
          <Button
            onClick={() => {
              fn();
            }}
            className={classes.btn}
            btnPrimary
            size="md"
          >
            Stake Now
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateStakeModal;
