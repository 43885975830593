import { makeApiRequest, generateSymbol, parseFullSymbol } from './helper';
import axios from 'axios'
// DatafeedConfiguration implementation
const configurationData = {
    // Represents the resolutions for bars supported by your datafeed
    supported_resolutions: ['1D', '1W', '1M'],
    // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
    exchanges: [
        { value: 'UNSXchange', name: 'UNSXchange', desc: 'UNSXchange' },
        // { value: 'Kraken', name: 'Kraken', desc: 'Kraken bitcoin exchange' },
    ],
    // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
    symbols_types: [
        { name: 'crypto', value: 'crypto' }
    ]
};

// Obtains all symbols for all exchanges supported by CryptoCompare API
async function getAllSymbols() {
    let parsedTickers = JSON.parse(localStorage.getItem("parsedTickers"))
    // const data = await makeApiRequest('data/v3/all/exchanges');
    // {
    //     "symbol": "ETHUSDT",
    //     "status": "Trading",
    //     "base_asset": "ETH",
    //     "base_asset_precision": 8,
    //     "quote_asset": "USDT",
    //     "quote_asset_precision": 8,
    //     "allowed_order_types": [
    //         "Market",
    //         "Limit"
    //     ]
    // },

    let base_url = process.env.REACT_APP_API
    let route = "/market/markets"
    let data = await axios.get(base_url + route)
    let markets = (data.data.markets)

    console.log(markets)

    let symbols = markets.map((ticker) => {
        return {
            "symbol": `${ticker.base_asset}/${ticker.quote_asset}`,
            "ticker": `${ticker.base_asset}${ticker.quote_asset}`,
            "description": `${ticker.base_asset}/${ticker.quote_asset}`,
            "exchange": "UNSXchange",
            "type": "crypto"
        }
    })
    return symbols;
}

export default {
    onReady: (callback) => {
        // console.log('[onReady]: Method call');
        setTimeout(() => callback(configurationData));
    },

    searchSymbols: async (
        userInput,
        exchange,
        symbolType,
        onResultReadyCallback
    ) => {
        // console.log('[searchSymbols]: Method call');
        const symbols = await getAllSymbols();
        const newSymbols = symbols.filter(symbol => {
            const isExchangeValid = exchange === '' || symbol.exchange === exchange;
            const isFullSymbolContainsInput = symbol.ticker
                .toLowerCase()
                .indexOf(userInput.toLowerCase()) !== -1;
            return isExchangeValid && isFullSymbolContainsInput;
        });
        onResultReadyCallback(newSymbols);
    },

    resolveSymbol: async (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback,
        extension
    ) => {
        // console.log('[resolveSymbol]: Method call', symbolName);
        const symbols = await getAllSymbols();
        // console.log(symbolName)
        // console.log("vvvvvvvm", symbols)
        const symbolItem = symbols.find(({ ticker }) => ticker === symbolName);
        // console.log("vvvvvvvm", symbolItem)
        if (!symbolItem) {
            // console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
            onResolveErrorCallback('Cannot resolve symbol');
            return;
        }
        // Symbol information object
        const symbolInfo = {
            ticker: symbolItem.ticker,
            name: symbolItem.symbol,
            description: symbolItem.description,
            type: symbolItem.type,
            session: '24x7',
            timezone: 'Etc/UTC',
            exchange: symbolItem.exchange,
            minmov: 1,
            pricescale: 100,
            has_intraday: false,
            visible_plots_set: 'ohlc',
            has_weekly_and_monthly: false,
            supported_resolutions: configurationData.supported_resolutions,
            volume_precision: 2,
            data_status: 'streaming',
        };

        const UNS = {
            ticker: "UNS/USDT",
            name: "UNS/USDT",
            description: "UNS/USDT",
            type: "crypto",
            session: '24x7',
            timezone: 'Etc/UTC',
            exchange: "UNSXchange",
            minmov: 1,
            pricescale: 100,
            has_intraday: false,
            visible_plots_set: 'ohlc',
            has_weekly_and_monthly: false,
            supported_resolutions: configurationData.supported_resolutions,
            volume_precision: 2,
            data_status: 'streaming',
        };
        // console.log('[resolveSymbol]: Symbol resolved', symbolInfo);
        onSymbolResolvedCallback(symbolInfo);
    },

    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        console.log(periodParams)

        const { from, to, firstDataRequest } = periodParams;
        console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
        const parsedSymbol = (symbolInfo.ticker);
        console.log(parsedSymbol)
        // const urlParameters = {
        //     e: parsedSymbol.exchange,
        //     fsym: parsedSymbol.fromSymbol,
        //     tsym: parsedSymbol.toSymbol,
        //     toTs: to,
        //     limit: 2000,
        // };
        // const query = Object.keys(urlParameters)
        //     .map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
        //     .join('&');
        try {
            // const data = await makeApiRequest(`data/histoday?${query}`);
            // if (data.Response && data.Response === 'Error' || data.Data.length === 0) {
            //     // "noData" should be set if there is no data in the requested period
            //     onHistoryCallback([], { noData: true });
            //     return;
            // }

            let api = process.env.REACT_APP_API;
            // https://api.unsxchange.com/spot/kline?symbol=UNSUSDT&interval=1d&start_time=0&end_time=1000000000000000&limit=10000
            let { data } = await axios.get(`${api}/spot/kline?symbol=${parsedSymbol}&interval=1d&start_time=0&end_time=1000000000000000&limit=10000`)


            let bars = [];
            data.klines.forEach(bar => {
                bars = [...bars, {
                    time: bar.open_time,
                    low: bar.low,
                    high: bar.high,
                    open: bar.open,
                    close: bar.close,

                }];
            });



            let bar2 = [
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681776000000,
                    "low": 0.1139,
                    "high": 0.116,
                    "open": 0.1151,
                    "close": 0.1155,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681862400000,
                    "low": 0.106,
                    "high": 0.1155,
                    "open": 0.1155,
                    "close": 0.1071,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1681948800000,
                    "low": 0.1011,
                    "high": 0.1078,
                    "open": 0.1071,
                    "close": 0.1031,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1682035200000,
                    "low": 0.09802,
                    "high": 0.1031,
                    "open": 0.1031,
                    "close": 0.09893,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1682121600000,
                    "low": 0.09767,
                    "high": 0.1039,
                    "open": 0.09893,
                    "close": 0.1039,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1682208000000,
                    "low": 0.09841,
                    "high": 0.1039,
                    "open": 0.1039,
                    "close": 0.09845,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1682294400000,
                    "low": 0.00001674,
                    "high": 0.1031,
                    "open": 0.09845,
                    "close": 0.00001674,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1682380800000,
                    "low": 0.00001668,
                    "high": 0.117,
                    "open": 0.00001674,
                    "close": 0.1111,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1682467200000,
                    "low": 0.1058,
                    "high": 0.1124,
                    "open": 0.1111,
                    "close": 0.1074,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1682553600000,
                    "low": 0.1074,
                    "high": 0.1184,
                    "open": 0.1074,
                    "close": 0.1094,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1682640000000,
                    "low": 0.1066,
                    "high": 0.1106,
                    "open": 0.1094,
                    "close": 0.1076,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1682726400000,
                    "low": 0.1072,
                    "high": 0.1098,
                    "open": 0.1076,
                    "close": 0.1077,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1682812800000,
                    "low": 0.1063,
                    "high": 0.1092,
                    "open": 0.1077,
                    "close": 0.1084,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1682899200000,
                    "low": 0.1025,
                    "high": 0.1116,
                    "open": 0.1084,
                    "close": 0.104,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1682985600000,
                    "low": 0.1013,
                    "high": 0.104,
                    "open": 0.104,
                    "close": 0.1033,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1683072000000,
                    "low": 0.09866,
                    "high": 0.1044,
                    "open": 0.1033,
                    "close": 0.1038,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1683158400000,
                    "low": 0.102,
                    "high": 0.1052,
                    "open": 0.1038,
                    "close": 0.1027,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1683244800000,
                    "low": 0.09948,
                    "high": 0.1029,
                    "open": 0.1027,
                    "close": 0.09948,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1683331200000,
                    "low": 0.09596,
                    "high": 0.1006,
                    "open": 0.09948,
                    "close": 0.09843,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1683417600000,
                    "low": 0.09786,
                    "high": 0.09982,
                    "open": 0.09843,
                    "close": 0.09786,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1683504000000,
                    "low": 0.08904,
                    "high": 0.09787,
                    "open": 0.09786,
                    "close": 0.09117,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1683590400000,
                    "low": 0.09012,
                    "high": 0.09327,
                    "open": 0.09117,
                    "close": 0.09203,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1683676800000,
                    "low": 0.09046,
                    "high": 0.0946,
                    "open": 0.09203,
                    "close": 0.09251,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1683763200000,
                    "low": 0.08715,
                    "high": 0.0931,
                    "open": 0.09251,
                    "close": 0.08906,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1683849600000,
                    "low": 0.08467,
                    "high": 0.0898,
                    "open": 0.08906,
                    "close": 0.08866,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1683936000000,
                    "low": 0.08811,
                    "high": 0.0946,
                    "open": 0.08866,
                    "close": 0.09148,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1684022400000,
                    "low": 0.08985,
                    "high": 0.09438,
                    "open": 0.09148,
                    "close": 0.09306,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1684108800000,
                    "low": 0.09185,
                    "high": 0.09814,
                    "open": 0.09306,
                    "close": 0.09654,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1684195200000,
                    "low": 0.09604,
                    "high": 0.1021,
                    "open": 0.09654,
                    "close": 0.101,
                    "isLastBar": false,
                    "isBarClosed": true
                },

                {
                    "time": 1684368000000,
                    "low": 0.09704,
                    "high": 0.1022,
                    "open": 0.09972,
                    "close": 0.09953,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1684454400000,
                    "low": 0.09853,
                    "high": 0.1081,
                    "open": 0.09953,
                    "close": 0.1072,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1684540800000,
                    "low": 0.1064,
                    "high": 0.1275,
                    "open": 0.1072,
                    "close": 0.1187,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1684627200000,
                    "low": 0.1123,
                    "high": 0.1252,
                    "open": 0.1187,
                    "close": 0.1141,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1684713600000,
                    "low": 0.1126,
                    "high": 0.126,
                    "open": 0.1141,
                    "close": 0.122,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1684800000000,
                    "low": 0.1196,
                    "high": 0.1254,
                    "open": 0.122,
                    "close": 0.1201,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1684886400000,
                    "low": 0.001865,
                    "high": 0.1201,
                    "open": 0.1201,
                    "close": 0.1175,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1684972800000,
                    "low": 0.09264,
                    "high": 0.1223,
                    "open": 0.1175,
                    "close": 0.09688,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1685059200000,
                    "low": 0.09185,
                    "high": 0.09688,
                    "open": 0.09688,
                    "close": 0.09256,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1685145600000,
                    "low": 0.08932,
                    "high": 0.09376,
                    "open": 0.09256,
                    "close": 0.09151,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1685232000000,
                    "low": 0.09036,
                    "high": 0.09531,
                    "open": 0.09151,
                    "close": 0.09317,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1685318400000,
                    "low": 0.09025,
                    "high": 0.09351,
                    "open": 0.09317,
                    "close": 0.09064,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1685404800000,
                    "low": 0.08858,
                    "high": 0.09144,
                    "open": 0.09064,
                    "close": 0.0902,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1685491200000,
                    "low": 0.08727,
                    "high": 0.09287,
                    "open": 0.0902,
                    "close": 0.09287,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1685577600000,
                    "low": 0.08979,
                    "high": 0.09378,
                    "open": 0.09287,
                    "close": 0.08979,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1685664000000,
                    "low": 0.0892,
                    "high": 0.09191,
                    "open": 0.08979,
                    "close": 0.09077,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1685750400000,
                    "low": 0.0885,
                    "high": 0.09116,
                    "open": 0.09077,
                    "close": 0.08927,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1685836800000,
                    "low": 0.08897,
                    "high": 0.09183,
                    "open": 0.08927,
                    "close": 0.08897,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1685923200000,
                    "low": 0.07941,
                    "high": 0.09,
                    "open": 0.08897,
                    "close": 0.08032,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1686009600000,
                    "low": 0.07921,
                    "high": 0.08456,
                    "open": 0.08032,
                    "close": 0.08411,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1686096000000,
                    "low": 0.07698,
                    "high": 0.08411,
                    "open": 0.08411,
                    "close": 0.07781,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1686182400000,
                    "low": 0.07611,
                    "high": 0.08001,
                    "open": 0.07781,
                    "close": 0.07827,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1686268800000,
                    "low": 0.07689,
                    "high": 0.07898,
                    "open": 0.07827,
                    "close": 0.07724,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1686355200000,
                    "low": 0.06407,
                    "high": 0.07748,
                    "open": 0.07724,
                    "close": 0.06811,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1686441600000,
                    "low": 0.06708,
                    "high": 0.0694,
                    "open": 0.06811,
                    "close": 0.06708,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1686528000000,
                    "low": 0.066,
                    "high": 0.06989,
                    "open": 0.06708,
                    "close": 0.06847,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1686614400000,
                    "low": 0.06828,
                    "high": 0.07116,
                    "open": 0.06847,
                    "close": 0.06969,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1686700800000,
                    "low": 0.0675,
                    "high": 0.07327,
                    "open": 0.06969,
                    "close": 0.06764,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1686787200000,
                    "low": 0.06625,
                    "high": 0.0699,
                    "open": 0.06764,
                    "close": 0.06869,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1686873600000,
                    "low": 0.06761,
                    "high": 0.07062,
                    "open": 0.06869,
                    "close": 0.06944,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1686960000000,
                    "low": 0.06888,
                    "high": 0.07153,
                    "open": 0.06944,
                    "close": 0.06939,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1687046400000,
                    "low": 0.06853,
                    "high": 0.07019,
                    "open": 0.06939,
                    "close": 0.06887,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1687132800000,
                    "low": 0.06858,
                    "high": 0.07223,
                    "open": 0.06887,
                    "close": 0.06925,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1687219200000,
                    "low": 0.06711,
                    "high": 0.07125,
                    "open": 0.06925,
                    "close": 0.07073,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1687305600000,
                    "low": 0.06993,
                    "high": 0.07344,
                    "open": 0.07073,
                    "close": 0.07248,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1687392000000,
                    "low": 0.07087,
                    "high": 0.07506,
                    "open": 0.07248,
                    "close": 0.0723,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1687478400000,
                    "low": 0.07186,
                    "high": 0.08099,
                    "open": 0.0723,
                    "close": 0.07633,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1687564800000,
                    "low": 0.07429,
                    "high": 0.07957,
                    "open": 0.07633,
                    "close": 0.07664,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1687651200000,
                    "low": 0.07574,
                    "high": 0.07854,
                    "open": 0.07664,
                    "close": 0.07686,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1687737600000,
                    "low": 0.07447,
                    "high": 0.07779,
                    "open": 0.07686,
                    "close": 0.07555,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1687824000000,
                    "low": 0.07493,
                    "high": 0.08093,
                    "open": 0.07555,
                    "close": 0.07763,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1687910400000,
                    "low": 0.07085,
                    "high": 0.07763,
                    "open": 0.07763,
                    "close": 0.07085,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1687996800000,
                    "low": 0.07075,
                    "high": 0.07344,
                    "open": 0.07085,
                    "close": 0.07199,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1688083200000,
                    "low": 0.07091,
                    "high": 0.07535,
                    "open": 0.07199,
                    "close": 0.07344,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1688169600000,
                    "low": 0.07222,
                    "high": 0.07459,
                    "open": 0.07344,
                    "close": 0.07328,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1688256000000,
                    "low": 0.07173,
                    "high": 0.07373,
                    "open": 0.07328,
                    "close": 0.07277,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1688342400000,
                    "low": 0.07262,
                    "high": 0.07701,
                    "open": 0.07277,
                    "close": 0.07701,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1688428800000,
                    "low": 0.07539,
                    "high": 0.08064,
                    "open": 0.07701,
                    "close": 0.07725,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1688515200000,
                    "low": 0.07218,
                    "high": 0.07835,
                    "open": 0.07725,
                    "close": 0.07341,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1688601600000,
                    "low": 0.07071,
                    "high": 0.07546,
                    "open": 0.07341,
                    "close": 0.07071,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1688688000000,
                    "low": 0.07054,
                    "high": 0.0783,
                    "open": 0.07071,
                    "close": 0.07257,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1688774400000,
                    "low": 0.06819,
                    "high": 0.07363,
                    "open": 0.07257,
                    "close": 0.07134,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1688860800000,
                    "low": 0.07132,
                    "high": 0.07298,
                    "open": 0.07134,
                    "close": 0.07272,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1688947200000,
                    "low": 0.06827,
                    "high": 0.07303,
                    "open": 0.07272,
                    "close": 0.07125,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1689033600000,
                    "low": 0.07081,
                    "high": 0.07314,
                    "open": 0.07125,
                    "close": 0.07217,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1689120000000,
                    "low": 0.07082,
                    "high": 0.07259,
                    "open": 0.07217,
                    "close": 0.07137,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1689206400000,
                    "low": 0.07094,
                    "high": 0.07522,
                    "open": 0.07137,
                    "close": 0.07483,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1689292800000,
                    "low": 0.07192,
                    "high": 0.07653,
                    "open": 0.07483,
                    "close": 0.07425,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1689379200000,
                    "low": 0.07295,
                    "high": 0.07696,
                    "open": 0.07425,
                    "close": 0.07357,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1689465600000,
                    "low": 0.0708,
                    "high": 0.07401,
                    "open": 0.07357,
                    "close": 0.0708,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1689552000000,
                    "low": 0.06827,
                    "high": 0.07156,
                    "open": 0.0708,
                    "close": 0.06966,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1689638400000,
                    "low": 0.06724,
                    "high": 0.07037,
                    "open": 0.06966,
                    "close": 0.06776,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1689724800000,
                    "low": 0.06741,
                    "high": 0.06939,
                    "open": 0.06776,
                    "close": 0.06761,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1689811200000,
                    "low": 0.06277,
                    "high": 0.07567,
                    "open": 0.06761,
                    "close": 0.0677,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1689897600000,
                    "low": 0.06697,
                    "high": 0.06838,
                    "open": 0.0677,
                    "close": 0.06707,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1689984000000,
                    "low": 0.06496,
                    "high": 0.06839,
                    "open": 0.06707,
                    "close": 0.06527,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1690070400000,
                    "low": 0.06506,
                    "high": 0.06978,
                    "open": 0.06527,
                    "close": 0.06978,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1690156800000,
                    "low": 0.06369,
                    "high": 0.06998,
                    "open": 0.06978,
                    "close": 0.06462,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1690243200000,
                    "low": 0.06134,
                    "high": 0.0717,
                    "open": 0.06462,
                    "close": 0.0653,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1690329600000,
                    "low": 0.06365,
                    "high": 0.0657,
                    "open": 0.0653,
                    "close": 0.0657,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1690416000000,
                    "low": 0.06399,
                    "high": 0.06625,
                    "open": 0.0657,
                    "close": 0.06465,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1690502400000,
                    "low": 0.06436,
                    "high": 0.06672,
                    "open": 0.06465,
                    "close": 0.06555,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1690588800000,
                    "low": 0.06543,
                    "high": 0.06871,
                    "open": 0.06555,
                    "close": 0.06757,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1690675200000,
                    "low": 0.065,
                    "high": 0.06879,
                    "open": 0.06757,
                    "close": 0.065,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1690761600000,
                    "low": 0.06369,
                    "high": 0.06621,
                    "open": 0.065,
                    "close": 0.06377,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1690848000000,
                    "low": 0.06179,
                    "high": 0.06499,
                    "open": 0.06377,
                    "close": 0.06499,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1690934400000,
                    "low": 0.06186,
                    "high": 0.06508,
                    "open": 0.06499,
                    "close": 0.06202,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1691020800000,
                    "low": 0.06108,
                    "high": 0.06291,
                    "open": 0.06202,
                    "close": 0.06138,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1691107200000,
                    "low": 0.06029,
                    "high": 0.06349,
                    "open": 0.06138,
                    "close": 0.06307,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1691193600000,
                    "low": 0.06223,
                    "high": 0.06788,
                    "open": 0.06307,
                    "close": 0.06312,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1691280000000,
                    "low": 0.06241,
                    "high": 0.06453,
                    "open": 0.06312,
                    "close": 0.06322,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1691366400000,
                    "low": 0.06255,
                    "high": 0.06565,
                    "open": 0.06322,
                    "close": 0.06406,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1691452800000,
                    "low": 0.06406,
                    "high": 0.07457,
                    "open": 0.06406,
                    "close": 0.06732,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1691539200000,
                    "low": 0.06554,
                    "high": 0.0685,
                    "open": 0.06732,
                    "close": 0.06694,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1691625600000,
                    "low": 0.06631,
                    "high": 0.06791,
                    "open": 0.06694,
                    "close": 0.06714,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1691712000000,
                    "low": 0.06714,
                    "high": 0.07037,
                    "open": 0.06714,
                    "close": 0.06775,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1691798400000,
                    "low": 0.0662,
                    "high": 0.06821,
                    "open": 0.06775,
                    "close": 0.06643,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1691884800000,
                    "low": 0.06634,
                    "high": 0.06813,
                    "open": 0.06643,
                    "close": 0.06719,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1691971200000,
                    "low": 0.06678,
                    "high": 0.0682,
                    "open": 0.06719,
                    "close": 0.0677,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1692057600000,
                    "low": 0.06234,
                    "high": 0.06831,
                    "open": 0.0677,
                    "close": 0.06329,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1692144000000,
                    "low": 0.05791,
                    "high": 0.06381,
                    "open": 0.06329,
                    "close": 0.05933,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1692230400000,
                    "low": 0.05363,
                    "high": 0.06247,
                    "open": 0.05933,
                    "close": 0.05642,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1692316800000,
                    "low": 0.05495,
                    "high": 0.05713,
                    "open": 0.05642,
                    "close": 0.05703,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1692403200000,
                    "low": 0.05597,
                    "high": 0.05805,
                    "open": 0.05703,
                    "close": 0.05778,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1692489600000,
                    "low": 0.05716,
                    "high": 0.05928,
                    "open": 0.05778,
                    "close": 0.05867,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1692576000000,
                    "low": 0.0553,
                    "high": 0.05928,
                    "open": 0.05867,
                    "close": 0.05648,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1692662400000,
                    "low": 0.05476,
                    "high": 0.05804,
                    "open": 0.05648,
                    "close": 0.05742,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1692748800000,
                    "low": 0.05618,
                    "high": 0.05876,
                    "open": 0.05742,
                    "close": 0.05688,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1692835200000,
                    "low": 0.05555,
                    "high": 0.05759,
                    "open": 0.05688,
                    "close": 0.05616,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1692921600000,
                    "low": 0.05469,
                    "high": 0.05638,
                    "open": 0.05616,
                    "close": 0.05621,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1693008000000,
                    "low": 0.05456,
                    "high": 0.0565,
                    "open": 0.05621,
                    "close": 0.05456,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1693094400000,
                    "low": 0.05454,
                    "high": 0.05595,
                    "open": 0.05456,
                    "close": 0.05552,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1693180800000,
                    "low": 0.05427,
                    "high": 0.05571,
                    "open": 0.05552,
                    "close": 0.05537,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1693267200000,
                    "low": 0.05463,
                    "high": 0.05773,
                    "open": 0.05537,
                    "close": 0.0573,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1693353600000,
                    "low": 0.05509,
                    "high": 0.0573,
                    "open": 0.0573,
                    "close": 0.05685,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1693440000000,
                    "low": 0.05489,
                    "high": 0.0601,
                    "open": 0.05685,
                    "close": 0.05563,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1693526400000,
                    "low": 0.05328,
                    "high": 0.05593,
                    "open": 0.05563,
                    "close": 0.05424,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1693612800000,
                    "low": 0.05329,
                    "high": 0.0547,
                    "open": 0.05424,
                    "close": 0.05459,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1693699200000,
                    "low": 0.05289,
                    "high": 0.05467,
                    "open": 0.05459,
                    "close": 0.05354,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1693785600000,
                    "low": 0.05318,
                    "high": 0.05487,
                    "open": 0.05354,
                    "close": 0.05414,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1693872000000,
                    "low": 0.05386,
                    "high": 0.05718,
                    "open": 0.05414,
                    "close": 0.05639,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1693958400000,
                    "low": 0.0541,
                    "high": 0.05639,
                    "open": 0.05639,
                    "close": 0.05467,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1694044800000,
                    "low": 0.05385,
                    "high": 0.056,
                    "open": 0.05467,
                    "close": 0.05524,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1694131200000,
                    "low": 0.05505,
                    "high": 0.05645,
                    "open": 0.05524,
                    "close": 0.05505,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1694217600000,
                    "low": 0.05495,
                    "high": 0.05758,
                    "open": 0.05505,
                    "close": 0.05665,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1694304000000,
                    "low": 0.05297,
                    "high": 0.05738,
                    "open": 0.05665,
                    "close": 0.05392,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1694390400000,
                    "low": 0.05048,
                    "high": 0.05428,
                    "open": 0.05392,
                    "close": 0.0506,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1694476800000,
                    "low": 0.05035,
                    "high": 0.05338,
                    "open": 0.0506,
                    "close": 0.05035,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1694563200000,
                    "low": 0.05035,
                    "high": 0.05317,
                    "open": 0.05035,
                    "close": 0.05307,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1694649600000,
                    "low": 0.05169,
                    "high": 0.05431,
                    "open": 0.05307,
                    "close": 0.05377,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1694736000000,
                    "low": 0.05227,
                    "high": 0.05433,
                    "open": 0.05377,
                    "close": 0.05385,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1694822400000,
                    "low": 0.05348,
                    "high": 0.05537,
                    "open": 0.05385,
                    "close": 0.05458,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1694908800000,
                    "low": 0.05393,
                    "high": 0.05785,
                    "open": 0.05458,
                    "close": 0.05527,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1694995200000,
                    "low": 0.05353,
                    "high": 0.05591,
                    "open": 0.05527,
                    "close": 0.05451,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1695081600000,
                    "low": 0.05325,
                    "high": 0.05529,
                    "open": 0.05451,
                    "close": 0.05345,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1695168000000,
                    "low": 0.053,
                    "high": 0.05436,
                    "open": 0.05345,
                    "close": 0.0536,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1695254400000,
                    "low": 0.052,
                    "high": 0.05508,
                    "open": 0.0536,
                    "close": 0.05255,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1695340800000,
                    "low": 0.05202,
                    "high": 0.05378,
                    "open": 0.05255,
                    "close": 0.05376,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1695427200000,
                    "low": 0.05279,
                    "high": 0.05499,
                    "open": 0.05376,
                    "close": 0.05487,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1695513600000,
                    "low": 0.05312,
                    "high": 0.056,
                    "open": 0.05487,
                    "close": 0.05312,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1695600000000,
                    "low": 0.05253,
                    "high": 0.05723,
                    "open": 0.05312,
                    "close": 0.05643,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1695686400000,
                    "low": 0.05408,
                    "high": 0.05646,
                    "open": 0.05643,
                    "close": 0.0552,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1695772800000,
                    "low": 0.05329,
                    "high": 0.05576,
                    "open": 0.0552,
                    "close": 0.05343,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1695859200000,
                    "low": 0.05343,
                    "high": 0.05749,
                    "open": 0.05343,
                    "close": 0.05608,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1695945600000,
                    "low": 0.0557,
                    "high": 0.05749,
                    "open": 0.05608,
                    "close": 0.05652,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1696032000000,
                    "low": 0.05599,
                    "high": 0.05735,
                    "open": 0.05652,
                    "close": 0.05697,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1696118400000,
                    "low": 0.0564,
                    "high": 0.05834,
                    "open": 0.05697,
                    "close": 0.05754,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1696204800000,
                    "low": 0.05589,
                    "high": 0.05825,
                    "open": 0.05754,
                    "close": 0.0561,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1696291200000,
                    "low": 0.05503,
                    "high": 0.05668,
                    "open": 0.0561,
                    "close": 0.05522,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1696377600000,
                    "low": 0.05437,
                    "high": 0.05936,
                    "open": 0.05522,
                    "close": 0.05504,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1696464000000,
                    "low": 0.05329,
                    "high": 0.05545,
                    "open": 0.05504,
                    "close": 0.05383,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1696550400000,
                    "low": 0.05308,
                    "high": 0.05472,
                    "open": 0.05383,
                    "close": 0.05401,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1696636800000,
                    "low": 0.05377,
                    "high": 0.05496,
                    "open": 0.05401,
                    "close": 0.05443,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1696723200000,
                    "low": 0.05403,
                    "high": 0.0562,
                    "open": 0.05443,
                    "close": 0.05441,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1696809600000,
                    "low": 0.0512,
                    "high": 0.05445,
                    "open": 0.05441,
                    "close": 0.05194,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1696896000000,
                    "low": 0.05135,
                    "high": 0.05281,
                    "open": 0.05194,
                    "close": 0.0523,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1696982400000,
                    "low": 0.05061,
                    "high": 0.05336,
                    "open": 0.0523,
                    "close": 0.05158,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1697068800000,
                    "low": 0.0506,
                    "high": 0.05237,
                    "open": 0.05158,
                    "close": 0.05168,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1697155200000,
                    "low": 0.05121,
                    "high": 0.05231,
                    "open": 0.05168,
                    "close": 0.05138,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1697241600000,
                    "low": 0.05137,
                    "high": 0.05264,
                    "open": 0.05138,
                    "close": 0.05223,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1697328000000,
                    "low": 0.05184,
                    "high": 0.05336,
                    "open": 0.05223,
                    "close": 0.0531,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1697414400000,
                    "low": 0.05248,
                    "high": 0.05586,
                    "open": 0.0531,
                    "close": 0.05375,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1697500800000,
                    "low": 0.05282,
                    "high": 0.05919,
                    "open": 0.05375,
                    "close": 0.05375,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1697587200000,
                    "low": 0.05154,
                    "high": 0.05525,
                    "open": 0.05375,
                    "close": 0.05207,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1697673600000,
                    "low": 0.05063,
                    "high": 0.05225,
                    "open": 0.05207,
                    "close": 0.05095,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1697760000000,
                    "low": 0.05095,
                    "high": 0.05337,
                    "open": 0.05095,
                    "close": 0.05256,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1697846400000,
                    "low": 0.05212,
                    "high": 0.05432,
                    "open": 0.05256,
                    "close": 0.05397,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1697932800000,
                    "low": 0.05248,
                    "high": 0.05492,
                    "open": 0.05397,
                    "close": 0.0533,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1698019200000,
                    "low": 0.0533,
                    "high": 0.05592,
                    "open": 0.0533,
                    "close": 0.05589,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1698105600000,
                    "low": 0.05397,
                    "high": 0.05732,
                    "open": 0.05589,
                    "close": 0.05663,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1698192000000,
                    "low": 0.05492,
                    "high": 0.05865,
                    "open": 0.05663,
                    "close": 0.05686,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1698278400000,
                    "low": 0.05588,
                    "high": 0.06102,
                    "open": 0.05686,
                    "close": 0.05979,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1698364800000,
                    "low": 0.05715,
                    "high": 0.05979,
                    "open": 0.05979,
                    "close": 0.05836,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1698451200000,
                    "low": 0.0574,
                    "high": 0.05845,
                    "open": 0.05836,
                    "close": 0.0574,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1698537600000,
                    "low": 0.05717,
                    "high": 0.05814,
                    "open": 0.0574,
                    "close": 0.05717,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1698624000000,
                    "low": 0.05717,
                    "high": 0.06119,
                    "open": 0.05717,
                    "close": 0.05928,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1698710400000,
                    "low": 0.05928,
                    "high": 0.05981,
                    "open": 0.05928,
                    "close": 0.05981,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1698796800000,
                    "low": 0.05727,
                    "high": 0.05981,
                    "open": 0.05981,
                    "close": 0.05795,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1698883200000,
                    "low": 0.05795,
                    "high": 0.06094,
                    "open": 0.05795,
                    "close": 0.06094,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1698969600000,
                    "low": 0.06045,
                    "high": 0.06094,
                    "open": 0.06094,
                    "close": 0.06045,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1699056000000,
                    "low": 0.06045,
                    "high": 0.0639,
                    "open": 0.06045,
                    "close": 0.06241,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1699142400000,
                    "low": 0.06241,
                    "high": 0.06919,
                    "open": 0.06241,
                    "close": 0.06849,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1699228800000,
                    "low": 0.06535,
                    "high": 0.06849,
                    "open": 0.06849,
                    "close": 0.06692,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1699315200000,
                    "low": 0.06535,
                    "high": 0.06869,
                    "open": 0.06692,
                    "close": 0.06869,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1699401600000,
                    "low": 0.06741,
                    "high": 0.06877,
                    "open": 0.06869,
                    "close": 0.06853,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1699488000000,
                    "low": 0.06853,
                    "high": 0.06877,
                    "open": 0.06853,
                    "close": 0.06877,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1699574400000,
                    "low": 0.06877,
                    "high": 0.07161,
                    "open": 0.06877,
                    "close": 0.07042,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1699660800000,
                    "low": 0.07042,
                    "high": 0.07165,
                    "open": 0.07042,
                    "close": 0.07165,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1699747200000,
                    "low": 0.07084,
                    "high": 0.07165,
                    "open": 0.07165,
                    "close": 0.07115,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1699833600000,
                    "low": 0.07024,
                    "high": 0.07352,
                    "open": 0.07115,
                    "close": 0.0729,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1699920000000,
                    "low": 0.07169,
                    "high": 0.07376,
                    "open": 0.0729,
                    "close": 0.07241,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1700006400000,
                    "low": 0.07241,
                    "high": 0.07348,
                    "open": 0.07241,
                    "close": 0.07289,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1700092800000,
                    "low": 0.07289,
                    "high": 0.07425,
                    "open": 0.07289,
                    "close": 0.07425,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1700179200000,
                    "low": 0.07063,
                    "high": 0.07425,
                    "open": 0.07425,
                    "close": 0.07205,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1700265600000,
                    "low": 0.06941,
                    "high": 0.07205,
                    "open": 0.07205,
                    "close": 0.07112,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1700352000000,
                    "low": 0.07043,
                    "high": 0.07116,
                    "open": 0.07112,
                    "close": 0.07043,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1700438400000,
                    "low": 0.07043,
                    "high": 0.07136,
                    "open": 0.07043,
                    "close": 0.0708,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1700524800000,
                    "low": 0.06425,
                    "high": 0.0708,
                    "open": 0.0708,
                    "close": 0.06425,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1700611200000,
                    "low": 0.06425,
                    "high": 0.06931,
                    "open": 0.06425,
                    "close": 0.06931,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1700697600000,
                    "low": 0.06919,
                    "high": 0.06931,
                    "open": 0.06931,
                    "close": 0.06919,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1700784000000,
                    "low": 0.06919,
                    "high": 0.07195,
                    "open": 0.06919,
                    "close": 0.07157,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1700870400000,
                    "low": 0.07157,
                    "high": 0.07932,
                    "open": 0.07157,
                    "close": 0.07735,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1700956800000,
                    "low": 0.0766,
                    "high": 0.07735,
                    "open": 0.07735,
                    "close": 0.07665,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1701043200000,
                    "low": 0.0756,
                    "high": 0.08169,
                    "open": 0.07665,
                    "close": 0.08169,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1701129600000,
                    "low": 0.07695,
                    "high": 0.08169,
                    "open": 0.08169,
                    "close": 0.07695,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1701216000000,
                    "low": 0.07695,
                    "high": 0.07695,
                    "open": 0.07695,
                    "close": 0.07695,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1701302400000,
                    "low": 0.07384,
                    "high": 0.08335,
                    "open": 0.07695,
                    "close": 0.0817,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1701388800000,
                    "low": 0.0748,
                    "high": 0.08867,
                    "open": 0.0817,
                    "close": 0.08227,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1701475200000,
                    "low": 0.08017,
                    "high": 0.08386,
                    "open": 0.08227,
                    "close": 0.0808,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1701561600000,
                    "low": 0.07894,
                    "high": 0.08137,
                    "open": 0.0808,
                    "close": 0.08049,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1701648000000,
                    "low": 0.07817,
                    "high": 0.08295,
                    "open": 0.08049,
                    "close": 0.08021,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1701734400000,
                    "low": 0.07877,
                    "high": 0.08343,
                    "open": 0.08021,
                    "close": 0.08224,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1701820800000,
                    "low": 0.08174,
                    "high": 0.08706,
                    "open": 0.08224,
                    "close": 0.08407,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1701907200000,
                    "low": 0.08333,
                    "high": 0.0864,
                    "open": 0.08407,
                    "close": 0.08557,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1701993600000,
                    "low": 0.08433,
                    "high": 0.09171,
                    "open": 0.08557,
                    "close": 0.08889,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1702080000000,
                    "low": 0.08736,
                    "high": 0.093,
                    "open": 0.08889,
                    "close": 0.09195,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1702166400000,
                    "low": 0.09023,
                    "high": 0.1017,
                    "open": 0.09195,
                    "close": 0.09241,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1702252800000,
                    "low": 0.08694,
                    "high": 0.09478,
                    "open": 0.09241,
                    "close": 0.09179,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1702339200000,
                    "low": 0.0897,
                    "high": 0.09702,
                    "open": 0.09179,
                    "close": 0.09142,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1702425600000,
                    "low": 0.08668,
                    "high": 0.09368,
                    "open": 0.09142,
                    "close": 0.09093,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1702512000000,
                    "low": 0.08929,
                    "high": 0.09341,
                    "open": 0.09093,
                    "close": 0.09065,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1702598400000,
                    "low": 0.08575,
                    "high": 0.09065,
                    "open": 0.09065,
                    "close": 0.0865,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1702684800000,
                    "low": 0.08624,
                    "high": 0.09063,
                    "open": 0.0865,
                    "close": 0.08655,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1702771200000,
                    "low": 0.08577,
                    "high": 0.08852,
                    "open": 0.08655,
                    "close": 0.08644,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1702857600000,
                    "low": 0.08002,
                    "high": 0.08651,
                    "open": 0.08644,
                    "close": 0.08309,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1702944000000,
                    "low": 0.08095,
                    "high": 0.08447,
                    "open": 0.08309,
                    "close": 0.08148,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1703030400000,
                    "low": 0.08113,
                    "high": 0.08674,
                    "open": 0.08148,
                    "close": 0.08479,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1703116800000,
                    "low": 0.08321,
                    "high": 0.08659,
                    "open": 0.08479,
                    "close": 0.08489,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1703203200000,
                    "low": 0.08286,
                    "high": 0.08626,
                    "open": 0.08489,
                    "close": 0.08626,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1703289600000,
                    "low": 0.08312,
                    "high": 0.08751,
                    "open": 0.08626,
                    "close": 0.08655,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1703376000000,
                    "low": 0.08531,
                    "high": 0.08992,
                    "open": 0.08655,
                    "close": 0.0865,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1703462400000,
                    "low": 0.08533,
                    "high": 0.0914,
                    "open": 0.0865,
                    "close": 0.09059,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1703548800000,
                    "low": 0.0868,
                    "high": 0.09207,
                    "open": 0.09059,
                    "close": 0.09058,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1703635200000,
                    "low": 0.089,
                    "high": 0.09125,
                    "open": 0.09058,
                    "close": 0.09032,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1703721600000,
                    "low": 0.08733,
                    "high": 0.09316,
                    "open": 0.09032,
                    "close": 0.08784,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1703808000000,
                    "low": 0.08488,
                    "high": 0.08965,
                    "open": 0.08784,
                    "close": 0.08704,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1703894400000,
                    "low": 0.08323,
                    "high": 0.08739,
                    "open": 0.08704,
                    "close": 0.08557,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1703980800000,
                    "low": 0.08148,
                    "high": 0.08619,
                    "open": 0.08557,
                    "close": 0.08258,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1704067200000,
                    "low": 0.08152,
                    "high": 0.08565,
                    "open": 0.08258,
                    "close": 0.08484,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1704153600000,
                    "low": 0.084,
                    "high": 0.08798,
                    "open": 0.08484,
                    "close": 0.08517,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1704240000000,
                    "low": 0.07523,
                    "high": 0.08649,
                    "open": 0.08517,
                    "close": 0.07616,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1704326400000,
                    "low": 0.07439,
                    "high": 0.07888,
                    "open": 0.07616,
                    "close": 0.07785,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1704412800000,
                    "low": 0.07242,
                    "high": 0.0787,
                    "open": 0.07785,
                    "close": 0.07363,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1704499200000,
                    "low": 0.06971,
                    "high": 0.0745,
                    "open": 0.07363,
                    "close": 0.07364,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1704585600000,
                    "low": 0.06977,
                    "high": 0.07544,
                    "open": 0.07364,
                    "close": 0.06977,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1704672000000,
                    "low": 0.06663,
                    "high": 0.07239,
                    "open": 0.06977,
                    "close": 0.07225,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1704758400000,
                    "low": 0.06661,
                    "high": 0.07275,
                    "open": 0.07225,
                    "close": 0.06873,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1704844800000,
                    "low": 0.0675,
                    "high": 0.07681,
                    "open": 0.06873,
                    "close": 0.07589,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1704931200000,
                    "low": 0.07354,
                    "high": 0.07759,
                    "open": 0.07589,
                    "close": 0.07491,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1705017600000,
                    "low": 0.07124,
                    "high": 0.07666,
                    "open": 0.07491,
                    "close": 0.07348,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1705104000000,
                    "low": 0.07179,
                    "high": 0.07536,
                    "open": 0.07348,
                    "close": 0.07442,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1705190400000,
                    "low": 0.0719,
                    "high": 0.07509,
                    "open": 0.07442,
                    "close": 0.07243,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1705276800000,
                    "low": 0.07196,
                    "high": 0.07479,
                    "open": 0.07243,
                    "close": 0.07377,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1705363200000,
                    "low": 0.07238,
                    "high": 0.07464,
                    "open": 0.07377,
                    "close": 0.07352,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1705449600000,
                    "low": 0.07179,
                    "high": 0.07428,
                    "open": 0.07352,
                    "close": 0.07312,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1705536000000,
                    "low": 0.06462,
                    "high": 0.07351,
                    "open": 0.07312,
                    "close": 0.06462,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1705622400000,
                    "low": 0.06151,
                    "high": 0.06617,
                    "open": 0.06462,
                    "close": 0.06468,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1705708800000,
                    "low": 0.06415,
                    "high": 0.07095,
                    "open": 0.06468,
                    "close": 0.06905,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1705795200000,
                    "low": 0.06742,
                    "high": 0.0696,
                    "open": 0.06905,
                    "close": 0.06742,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1705881600000,
                    "low": 0.06424,
                    "high": 0.06817,
                    "open": 0.06742,
                    "close": 0.06431,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1705968000000,
                    "low": 0.06165,
                    "high": 0.06632,
                    "open": 0.06431,
                    "close": 0.06529,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1706054400000,
                    "low": 0.06466,
                    "high": 0.06767,
                    "open": 0.06529,
                    "close": 0.06767,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1706140800000,
                    "low": 0.06515,
                    "high": 0.07193,
                    "open": 0.06767,
                    "close": 0.06846,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1706227200000,
                    "low": 0.06728,
                    "high": 0.07052,
                    "open": 0.06846,
                    "close": 0.06871,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1706313600000,
                    "low": 0.06816,
                    "high": 0.07187,
                    "open": 0.06871,
                    "close": 0.07065,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1706400000000,
                    "low": 0.06964,
                    "high": 0.07709,
                    "open": 0.07065,
                    "close": 0.0758,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1706486400000,
                    "low": 0.0747,
                    "high": 0.0953,
                    "open": 0.0758,
                    "close": 0.08481,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1706572800000,
                    "low": 0.07911,
                    "high": 0.08616,
                    "open": 0.08481,
                    "close": 0.0811,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1706659200000,
                    "low": 0.0811,
                    "high": 0.0924,
                    "open": 0.0811,
                    "close": 0.08187,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1706745600000,
                    "low": 0.07879,
                    "high": 0.08614,
                    "open": 0.08187,
                    "close": 0.07879,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1706832000000,
                    "low": 0.07278,
                    "high": 0.08095,
                    "open": 0.07879,
                    "close": 0.07726,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1706918400000,
                    "low": 0.07419,
                    "high": 0.07812,
                    "open": 0.07726,
                    "close": 0.07753,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1707004800000,
                    "low": 0.07564,
                    "high": 0.08414,
                    "open": 0.07753,
                    "close": 0.08288,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1707091200000,
                    "low": 0.07675,
                    "high": 0.08414,
                    "open": 0.08288,
                    "close": 0.07788,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1707177600000,
                    "low": 0.07233,
                    "high": 0.08012,
                    "open": 0.07788,
                    "close": 0.0744,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1707264000000,
                    "low": 0.07187,
                    "high": 0.07953,
                    "open": 0.0744,
                    "close": 0.0784,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1707350400000,
                    "low": 0.05021,
                    "high": 0.07976,
                    "open": 0.0784,
                    "close": 0.07821,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1707436800000,
                    "low": 0.07,
                    "high": 0.07925,
                    "open": 0.07821,
                    "close": 0.07924,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1707523200000,
                    "low": 0.07924,
                    "high": 0.07924,
                    "open": 0.07924,
                    "close": 0.07924,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1707609600000,
                    "low": 0.07924,
                    "high": 0.07924,
                    "open": 0.07924,
                    "close": 0.07924,
                    "isLastBar": false,
                    "isBarClosed": true
                },
                {
                    "time": 1707609600000,
                    "low": 0.07924,
                    "high": 0.07924,
                    "open": 0.07924,
                    "close": 0.07924,
                    "isLastBar": false,
                    "isBarClosed": true
                }
            ]

            let bar3 = bar2.map((bar) => {
                return {
                    time: bar.time,
                    low: bar.low,
                    high: bar.high,
                    open: bar.open,
                    close: bar.close,
                }
            })


            onHistoryCallback(bars, { noData: false });
        } catch (error) {
            // console.log('[getBars]: Get error', error);
            onErrorCallback(error);
        }
    },

    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
        // console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
    },
    unsubscribeBars: (subscriberUID) => {
        // console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
    },
};
