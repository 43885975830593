import React from "react";

import classes from "./SingleRow.module.css";
import clsx from "clsx";

const SingleRow = ({
  coin,
  network,
  minDeposit,
  depositFee,
  minWithDrawal,
  withDrawalFee,
}) => {
  return (
    <>
      <tr className={clsx(classes.row, classes.valueContainer)}>
        <td className={classes.item}>
          <div className={classes.logoAndNameContainer}>
            <img src={coin.logo} alt="#" className={classes.logo} />
            <div className={classes.nameContainer}>
              <p>{coin.shortName}</p>
              <span>{coin.name}</span>
            </div>
          </div>
        </td>
        <td className={clsx(classes.item, classes.firstItem)}>{network}</td>{" "}
        <td className={classes.item}>{minDeposit}</td>
        <td className={clsx(classes.item)}>{depositFee}</td>
        <td className={clsx(classes.item)}>{minWithDrawal}</td>
        <td className={clsx(classes.item, classes.lastItem)}>
          {withDrawalFee}
        </td>
      </tr>
    </>
  );
};

export default SingleRow;
