import React, { useState, useMemo } from "react";
import Pagination from "common/Pagination/Pagination";
import classes from "./TradingFees.module.css";

import clsx from "clsx";
import SingleRow from "./SingleRow/SingleRow";

const data = [
  {
    userType: "Regular User",

    tradingVolumeUsdLast30Days: "< 1,000,000 USD",
    andOr: "and",
    makerOff: {
      maker: "0.1000%",
      off: "0.0750%",
    },
    takerOff: {
      taker: "0.0900% ",
      off: "0.0750%",
    },
    withDrawLimit24HourUsdt: "1,000,000",
  },
  {
    userType: "VIP Level 0",

    tradingVolumeUsdLast30Days: "< 1,000,000 USD",
    andOr: "or",
    makerOff: {
      maker: "0.1000%",
      off: "0.0750%",
    },
    takerOff: {
      taker: "0.0900% ",
      off: "0.0750%",
    },
    withDrawLimit24HourUsdt: "3,000,000",
  },
  {
    userType: "Regular User",

    tradingVolumeUsdLast30Days: "< 1,000,000 USD",
    andOr: "and",
    makerOff: {
      maker: "0.1000%",
      off: "0.0750%",
    },
    takerOff: {
      taker: "0.0900% ",
      off: "0.0750%",
    },
    withDrawLimit24HourUsdt: "1,000,000",
  },
  {
    userType: "VIP Level 0",

    tradingVolumeUsdLast30Days: "< 1,000,000 USD",
    andOr: "or",
    makerOff: {
      maker: "0.1000%",
      off: "0.0750%",
    },
    takerOff: {
      taker: "0.0900% ",
      off: "0.0750%",
    },
    withDrawLimit24HourUsdt: "3,000,000",
  },
  {
    userType: "Regular User",

    tradingVolumeUsdLast30Days: "< 1,000,000 USD",
    andOr: "and",
    makerOff: {
      maker: "0.1000%",
      off: "0.0750%",
    },
    takerOff: {
      taker: "0.0900% ",
      off: "0.0750%",
    },
    withDrawLimit24HourUsdt: "1,000,000",
  },
  {
    userType: "VIP Level 0",

    tradingVolumeUsdLast30Days: "< 1,000,000 USD",
    andOr: "or",
    makerOff: {
      maker: "0.1000%",
      off: "0.0750%",
    },
    takerOff: {
      taker: "0.0900% ",
      off: "0.0750%",
    },
    withDrawLimit24HourUsdt: "3,000,000",
  },
  {
    userType: "Regular User",

    tradingVolumeUsdLast30Days: "< 1,000,000 USD",
    andOr: "and",
    makerOff: {
      maker: "0.1000%",
      off: "0.0750%",
    },
    takerOff: {
      taker: "0.0900% ",
      off: "0.0750%",
    },
    withDrawLimit24HourUsdt: "1,000,000",
  },
  {
    userType: "VIP Level 0",

    tradingVolumeUsdLast30Days: "< 1,000,000 USD",
    andOr: "or",
    makerOff: {
      maker: "0.1000%",
      off: "0.0750%",
    },
    takerOff: {
      taker: "0.0900% ",
      off: "0.0750%",
    },
    withDrawLimit24HourUsdt: "3,000,000",
  },
  {
    userType: "Regular User",

    tradingVolumeUsdLast30Days: "< 1,000,000 USD",
    andOr: "and",
    makerOff: {
      maker: "0.1000%",
      off: "0.0750%",
    },
    takerOff: {
      taker: "0.0900% ",
      off: "0.0750%",
    },
    withDrawLimit24HourUsdt: "1,000,000",
  },
  {
    userType: "VIP Level 0",

    tradingVolumeUsdLast30Days: "< 1,000,000 USD",
    andOr: "or",
    makerOff: {
      maker: "0.1000%",
      off: "0.0750%",
    },
    takerOff: {
      taker: "0.0900% ",
      off: "0.0750%",
    },
    withDrawLimit24HourUsdt: "3,000,000",
  },
  {
    userType: "Regular User",

    tradingVolumeUsdLast30Days: "< 1,000,000 USD",
    andOr: "and",
    makerOff: {
      maker: "0.1000%",
      off: "0.0750%",
    },
    takerOff: {
      taker: "0.0900% ",
      off: "0.0750%",
    },
    withDrawLimit24HourUsdt: "1,000,000",
  },
  {
    userType: "VIP Level 0",

    tradingVolumeUsdLast30Days: "< 1,000,000 USD",
    andOr: "or",
    makerOff: {
      maker: "0.1000%",
      off: "0.0750%",
    },
    takerOff: {
      taker: "0.0900% ",
      off: "0.0750%",
    },
    withDrawLimit24HourUsdt: "3,000,000",
  },
  {
    userType: "Regular User",

    tradingVolumeUsdLast30Days: "< 1,000,000 USD",
    andOr: "and",
    makerOff: {
      maker: "0.1000%",
      off: "0.0750%",
    },
    takerOff: {
      taker: "0.0900% ",
      off: "0.0750%",
    },
    withDrawLimit24HourUsdt: "1,000,000",
  },
  {
    userType: "VIP Level 0",

    tradingVolumeUsdLast30Days: "< 1,000,000 USD",
    andOr: "or",
    makerOff: {
      maker: "0.1000%",
      off: "0.0750%",
    },
    takerOff: {
      taker: "0.0900% ",
      off: "0.0750%",
    },
    withDrawLimit24HourUsdt: "3,000,000",
  },
];
const TradingFees = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, itemsPerPage]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <tbody>
            <tr className={classes.row}>
              <th className={classes.heading}>
                <p className={classes.tableHeading}> User Type</p>
              </th>
              <th className={clsx(classes.heading, classes.textAlignCenter)}>
                <p className={classes.tableHeading}>
                  Trading Volume USD (last 30 days)
                </p>
              </th>
              <th className={classes.heading}>
                <p className={classes.tableHeading}>and/or</p>
              </th>
              <th className={classes.heading}>
                <p className={classes.tableHeading}>Maker | Off</p>
              </th>
              <th className={classes.heading}>
                <p className={classes.tableHeading}>Taker | Off</p>
              </th>
              <th className={classes.heading}>
                <p className={clsx(classes.tableHeading, classes.lastHeading)}>
                  24h Withdrawal Limit (USDT)
                </p>
              </th>
            </tr>
            {currentTableData?.map((el, index) => (
              <SingleRow {...el} key={index} index={index} />
            ))}
          </tbody>
        </table>{" "}
        <div className={classes.pagination}>
          <Pagination
            currentPage={currentPage}
            totalCount={data?.length}
            pageSize={itemsPerPage}
            onPageChange={(page) => setCurrentPage(page)}
            siblingCount={0}
          />
        </div>
      </div>
    </div>
  );
};

export default TradingFees;
