import React from "react";
import classes from "./Staking.module.css";
import CoinBalanceAndApi from "components/Staking/CoinBalanceAndApi/CoinBalanceAndApi";
import CreateNewStake from "components/Staking/CreateNewStake/CreateNewStake";
import Tables from "components/Staking/Tables/Table";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Features from "components/Staking/Features/Features";
import Footer from "components/Footer/Footer";
import { getMyStakes, getStakingInstances } from "../../redux/stakingInstances";
import { fetchBalance } from "../../redux/balance";
import { fetchCurrencies } from "../../redux/currencies";

const Staking = () => {


  let dispatch = useDispatch();

  


  useEffect(() => {
    dispatch(fetchBalance());
    dispatch(fetchCurrencies());
    dispatch(getMyStakes());
    dispatch(getStakingInstances());
  }, []);

  let stakingInstances = useSelector((state) => state.stakingInstances.stakingInstances);
  let myStakings = useSelector((state) => state.stakingInstances.mystakes);

  const [stakeCurrencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSeletedCurrency] = useState(stakeCurrencies[0]);
  const [balance, setBalance] = useState(0);

  const [durations, setDurations] = useState([]);


  let balances = useSelector((state) => state.balances.balances);
  let currencies = useSelector((state) => state.currencies.currencies);

  async function parseCurrencies() {
    let token_set = new Set();
    stakingInstances.map((i) => {
      token_set.add(i.token);
    })
    let token_array = Array.from(token_set)


    let token_arr = [];
    token_array.forEach((i) => {

      let token = currencies.find((cur) => cur.id == i);
      token_arr.push(token);
    })


    setCurrencies(token_arr)

  };
  useEffect(() => {
    parseCurrencies()
  }, [currencies])

  async function parseBalance() {
    let balance = balances.find((bal) => bal.currency == selectedCurrency?.id && bal.wallet_type == "Staking");




    setBalance(balance?.balance);
  }

  useEffect(() => {
    parseBalance()
  }, [selectedCurrency])

  async function parseDurations() {
    let staking = stakingInstances.filter((staking) => staking?.token == selectedCurrency?.id);
    let durations = [];

    staking.map((i) => {
      durations.push({
        duration: i.duration,
        apy: i.apy
      });
    })

    setDurations(durations)
  }

  useEffect(() => {
    parseDurations()
  }, [selectedCurrency])



  return (
    <>
      <div className={clsx("container", classes.container)}>
        <div className={classes.wrapper}>
          <CoinBalanceAndApi
            balance={balance}
            selectedCurrency={selectedCurrency}
            stakingInstances={stakingInstances} />
          <CreateNewStake
            durations={durations}
            selectedCurrency={selectedCurrency}
            stakeCurrencies={stakeCurrencies}
            balance={balance}
            setSeletedCurrency={setSeletedCurrency}
            stakingInstances={stakingInstances} />
        </div>
        <Tables myStakings={myStakings} />
        <Features />
      </div>
      <Footer />
    </>
  );
};

export default Staking;
