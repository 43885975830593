import React, { useState, useMemo, useEffect } from "react";
import Pagination from "common/Pagination/Pagination";
import classes from "./ReferList.module.css";
import { magnifier } from "assets";
import clsx from "clsx";
import { useTheme } from "ThemeContext/ThemeContext";
import Heading from "components/common/Heading/Heading";

import SingleRow from "./SingleRow/SingleRow";
const ReferList = ({ refData }) => {
  console.log(refData?.referrers)
  let [referrals, setReferrals] = useState([]);

  async function pushReferrals() {
    if (!refData?.referrers) return;
    console.log(refData.referrers, "refs")
    let refs = refData?.referrers;

    let refs_to_push = refs?.map((ref) => {
      let plans = [];
      if (ref.is_traded) {
        plans.push("Traded");
      }
      if (ref.is_deposited) {
        plans.push("Deposited");
      }

      if (ref.is_kyc_verified) {
        plans.push("KYC Verified");
      }

      plans.push("Joined");
      return {
        no: ref.id,
        user: ref.uuid,
        yourEarnInUsdt: ref.earnings,
        taskCompleted: plans,
      };
    });

    setReferrals(refs_to_push);
  }

  useEffect(() => {
    pushReferrals();
  }, [refData]);

  const { isDarkTheme } = useTheme();

  const [searchValue, setSearchValue] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  ///referrals will go on this function
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    //here I have use refferels
    return refData?.referrers?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, referrals, itemsPerPage]);

  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <div className={classes.header}>
        <Heading heading="Refer List" />
        <div className={classes.searchContainer}>
          <img src={magnifier} alt="magnifier" className={classes.icon} />
          <input
            type="search"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </div>
      <div className={clsx(classes.tableContainer, "overflow")}>
        <table className={classes.table}>
          <tbody>
            <tr className={classes.row}>
              {/* <th className={classes.heading}>
                <p className={classes.tableHeading}>No</p>
              </th> */}
              <th className={clsx(classes.heading, classes.textAlignCenter)}>
                <p className={classes.tableHeading}>User</p>
              </th>
              <th className={classes.heading}>
                <p className={classes.tableHeading}>Your Earnings (USD)</p>
              </th>
              <th className={clsx(classes.heading)}>
                <p className={clsx(classes.tableHeading, classes.lastHeading)}>
                  Task Completed
                </p>
              </th>
            </tr>
            {/* here current table data */}
            {currentTableData?.map((el, index) => (
              <SingleRow {...el} key={index} index={index} />
            ))}
          </tbody>
        </table>{" "}
        <div className={classes.pagination}>
          {/* <Pagination
            currentPage={currentPage}
            totalCount={user?.referrers?.length}
            pageSize={itemsPerPage}
            onPageChange={(page) => setCurrentPage(page)}
            siblingCount={0}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default ReferList;
