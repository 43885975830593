import { dontMiss } from "assets";
import classes from "./DontMiss.module.css";
import clsx from "clsx";
import { useTheme } from "ThemeContext/ThemeContext";

const DontMiss = () => {
  const { isDarkTheme } = useTheme();
  return (
    <div className={clsx(classes.dontMiss, !isDarkTheme && classes.lightTheme)}>
      <h2>Hurry Up!</h2>

      <p>{process.env.REACT_APP_SIGNUP_INFO}</p>

      <img src={dontMiss} alt="dont miss" />
    </div>
  );
};

export default DontMiss;
