import React from "react";
import classes from "./Heading.module.css";
import clsx from "clsx";
import { useTheme } from "ThemeContext/ThemeContext";

const Heading = ({
  heading,
  center,
  lg,
  wFull,
  border,
  success,
  className,
}) => {
  const { isDarkTheme } = useTheme();
  return (
    <h4
      className={clsx(
        classes.heading,
        className,
        center && classes.center,
        lg && classes.lg,
        wFull && classes.wFull,
        border && classes.border,
        success && classes.success,
        !isDarkTheme && classes.lightTheme
      )}
    >
      {heading}
    </h4>
  );
};

export default Heading;
