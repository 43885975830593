import clsx from "clsx";
import classes from "./Checkbox.module.css";
import { useTheme } from "ThemeContext/ThemeContext";

const Checkbox = ({ className, label, ...rest }) => {
  const { isDarkTheme } = useTheme();
  return (
    <label
      className={clsx(
        className,
        classes.container,
        !isDarkTheme && classes.lightTheme
      )}
    >
      {label}
      <input type="checkbox" {...rest} />
      <span className={classes.checkmark}></span>
    </label>
  );
};

export default Checkbox;
