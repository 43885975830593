import React, { useState, useMemo, useRef } from "react";

import clsx from "clsx";
import SingleRow from "./SingleRow/SingleRow";
import Pagination from "common/Pagination/Pagination";
import classes from "./OpenOrders.module.css";
import Dropdown from "components/Dropdown/Dropdown";
import { useTheme } from "ThemeContext/ThemeContext";
const typeDropDownItems = [
  {
    label: "All",
  },
  {
    label: "Limit",
  },
  {
    label: "Market",
  },
  {
    label: "Trailing Stop",
  },
  {
    label: "Stop Limit",
  },
  {
    label: "Stop Market",
  },
  {
    label: "OCO",
  },
];
const sideDropDownItems = [
  { label: "All" },
  { label: "Buy" },
  { label: "Sell" },
];

const OpenOrders = ({ data, setOpenOrders }) => {
  const { isDarkTheme } = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return data?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, itemsPerPage]);

  //dropdown
  //type dropdown
  const typeDropdownRef = useRef(null);
  const [isTypeDropdownActive, setIsTypeDropdownActive] = useState(false);
  const [selectedType, setSelectedType] = useState("All");

  //side Dropdown

  const sideDropdownRef = useRef(null);
  const [isSideDropdownActive, setIsSideDropdownActive] = useState(false);
  const [selectedSide, setSelectedSide] = useState("All");

  return (
    <div
      className={clsx(
        classes.tableContainer,
        !isDarkTheme && classes.lightTheme
      )}
    >
      <table className={classes.table}>
        <tbody>
          <tr className={classes.row}>
            <th className={clsx(classes.heading, classes.textAlignCenter)}>
              <p className={classes.tableHeading}>Time</p>
            </th>
            <th className={classes.heading}>
              <p className={classes.tableHeading}>Pair</p>
            </th>
            <th className={classes.heading} ref={typeDropdownRef}>
              <Dropdown
                placeholder="Type"
                className={clsx(classes.dropdownPlaceholder)}
                isActive={isTypeDropdownActive}
                selectedValue={selectedType}
                dropdownItems={typeDropDownItems}
                setIsDropdownActive={setIsTypeDropdownActive}
                dropdownRef={typeDropdownRef}
                onClick={() => {
                  if (isTypeDropdownActive) {
                    setIsTypeDropdownActive(false);
                  } else {
                    setIsTypeDropdownActive(true);
                  }
                }}
                isDropdownActive={isTypeDropdownActive}
                onSelect={(val) => {
                  setSelectedType(val);
                  setIsTypeDropdownActive(false);
                }}
                dropArrow
              />
            </th>
            <th className={classes.heading} ref={sideDropdownRef}>
              <Dropdown
                placeholder="Side"
                className={clsx(classes.dropdownPlaceholder)}
                isActive={isSideDropdownActive}
                selectedValue={selectedSide}
                dropdownItems={sideDropDownItems}
                setIsDropdownActive={setIsSideDropdownActive}
                dropdownRef={sideDropdownRef}
                onClick={() => {
                  if (isSideDropdownActive) {
                    setIsSideDropdownActive(false);
                  } else {
                    setIsSideDropdownActive(true);
                  }
                }}
                isDropdownActive={isSideDropdownActive}
                onSelect={(val) => {
                  setSelectedSide(val);
                  setIsSideDropdownActive(false);
                }}
                dropArrow
              />
            </th>
            <th className={classes.heading}>
              <p className={classes.tableHeading}>Price</p>
            </th>
            <th className={classes.heading}>
              <p className={classes.tableHeading}>Amount</p>
            </th>
            <th className={classes.heading}>
              <p className={classes.tableHeading}>Filled</p>
            </th>
            <th className={classes.heading}>
              <p className={classes.tableHeading}>Unfilled</p>
            </th>
            <th className={classes.heading}>
              <p className={classes.tableHeading}>Trigger Condition</p>
            </th>
            <th className={classes.heading}>
              <p className={classes.tableHeading}>Total</p>
            </th>
            <th className={classes.heading}>
              <p
                className={clsx(
                  classes.tableHeading,
                  classes.cancelAll,
                  classes.lastHeading
                )}
              >
                Cancel All
              </p>
            </th>
          </tr>
          {currentTableData
            ?.sort((a, b) => b.timestamp - a.timestamp)
            ?.map((el, index) => (
              <SingleRow
                el={el}
                key={index}
                setOpenOrders={setOpenOrders}
                index={index}
              />
            ))}
        </tbody>
      </table>
      <div className={classes.pagination}>
        <Pagination
          currentPage={currentPage}
          totalCount={data.length}
          pageSize={itemsPerPage}
          onPageChange={(page) => setCurrentPage(page)}
          siblingCount={0}
        />
      </div>
    </div>
  );
};

export default OpenOrders;
