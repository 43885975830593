import React, { useRef, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import classes from "./SingleRow.module.css";
import clsx from "clsx";
import useOnClickOutside from "hooks/useOnClickOutside";
import CancelPopup from "../../OpenOrders/CancelPopup/CancelPopup";
import { useSelector } from "react-redux";
import { parseTickers } from "../../../../../redux/tickers";
import { useTheme } from "ThemeContext/ThemeContext";

const SingleRow = ({
  pair,
  type,
  side,
  price,
  amount,
  executed_quantity: filled,
  initial_quantity,
  triggerCondition,
  id: order_id,
  total,
  timestamp,
  cancelAll,

  time,

  showDetails,
  setShowDetails,
  id,
}) => {
  const cancelRef = useRef();
  const [isShowCancelPopup, setIsShowCancelPopup] = useState(false);
  const [cancel, setCancel] = useState(false);

  let markets = useSelector(parseTickers);
  let market = markets.find((market) => market.market_id === pair);
  let unFilled = initial_quantity - filled;

  timestamp = timestamp / 1000;
  let date = new Date(timestamp).toLocaleString();

  useOnClickOutside(cancelRef, () => setIsShowCancelPopup(false));
  const { isDarkTheme } = useTheme();
  return (
    <>
      <div
        className={clsx(
          classes.row,
          showDetails && classes.active,
          !isDarkTheme && classes.lightTheme
        )}
      >
        <p className={classes.item}>{pair}</p>
        <p className={classes.item}>{time}</p>
        <div className={clsx(classes.buttonContainer, classes.item)}>
          <RiDeleteBin6Line
            className={classes.deleteIcon}
            color={isShowCancelPopup ? "var(--color-red)" : null}
            onClick={() => setIsShowCancelPopup(true)}
          />
          <div ref={cancelRef}>
            <CancelPopup
              isActive={isShowCancelPopup}
              setCancel={setCancel}
              setIsShowCancelPopup={setIsShowCancelPopup}
            />
          </div>
        </div>
        {showDetails === id ? (
          <FaAngleUp
            className={classes.arrow}
            color="#fff"
            onClick={() => {
              if (showDetails) {
                setShowDetails(false);
              } else {
                setShowDetails(true);
              }
            }}
          />
        ) : (
          <FaAngleDown
            className={classes.arrow}
            onClick={() => {
              setShowDetails(id);
            }}
          />
        )}
      </div>
      {showDetails === id && (
        <div className={classes.infoContainer}>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Type</p>
            <p className={classes.value}>{type}</p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Side</p>
            <p
              className={clsx(
                classes.value,
                side.toLowerCase() === "buy" && classes.buy,
                side.toLowerCase() === "sell" && classes.sell
              )}
            >
              {side}
            </p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Price</p>
            <p className={classes.value}>{price}</p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Amount</p>
            <p className={classes.value}>{amount}</p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Filled</p>
            <p className={classes.value}>{filled}</p>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Unfilled</p>
            <p className={classes.value}>{unFilled}</p>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Trigger Condition</p>
            <p className={classes.value}>{triggerCondition}</p>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Total</p>
            <p className={classes.value}>{total}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleRow;
