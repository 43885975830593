import React from "react";
import classes from "./CoinBalance.module.css";
import { copy } from "assets";
import CopyToClipboard from "react-copy-to-clipboard";
import clsx from "clsx";
import { useTheme } from "ThemeContext/ThemeContext";

const CoinBalanceAndApi = ({ stakingInstances, balance, selectedCurrency }) => {
  const { isDarkTheme } = useTheme();
  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <div className={classes.header}>
        <h2 className={classes.heading}>Staking</h2>
        <p className={classes.info}>
          Earning rewards in the form of additional coins for participating in
          the network's consensus mechanism.
        </p>
      </div>
      <div className={classes.container}>
        <div className={classes.coinBalance}>
          <h4 className={classes.label}>Coin Balance</h4>
          <div className={classes.amountContainer}>
            <h3 className={classes.amount}>
              {balance ? balance.toFixed(6) : 0.0}{" "}
              {selectedCurrency?.symbol?.toUpperCase()}
            </h3>
            {/* <p className={classes.usdAmount}>≈$--.--</p> */}
          </div>
        </div>{" "}
        {/* <div className={classes.stakingApi}>
          <h4 className={classes.label}>Staking API</h4>
          <div className={classes.addressWrapper}>
            {" "}
            <div className={classes.addressContainer}>
              <h3 className={classes.address}>
                de254b63-5395-4e46-b03e-b41d2aba4630
              </h3>
            </div>
            <CopyToClipboard text="de254b63-5395-4e46-b03e-b41d2aba4630">
              <div className={classes.copyContainer}>
                <img src={copy} alt="#" className={classes.copyIcon} />
              </div>
            </CopyToClipboard>
          </div>

          <p className={classes.earningFrom}>Earning from staking</p>
        </div> */}
      </div>
    </div>
  );
};

export default CoinBalanceAndApi;
