import classes from "./Table.module.css";
import { magnifier, binance } from "assets";
import clsx from "clsx";

import FinishedStaking from "./ClaimedTransaction/ClaimedTransaction";
import LiveAndFinished from "./LiveAndFinished/LiveAndFinished";
import ClaimedTransaction from "./ClaimedTransaction/ClaimedTransaction";
import { useState } from "react";
import { useTheme } from "ThemeContext/ThemeContext";
const liveStakeAndFinisedStake = [
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },

    stakeId: "30d2e6ac9add",
    amountStaked: "41,845.8",
    amountEarned: "2.64",
    status: "Finished",
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },

    stakeId: "30d2e6ac9add",
    amountStaked: "41,845.8",
    amountEarned: "2.64",
    status: "Finished",
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },

    stakeId: "30d2e6ac9add",
    amountStaked: "41,845.8",
    amountEarned: "2.64",
    status: "Finished",
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },

    stakeId: "30d2e6ac9add",
    amountStaked: "41,845.8",
    amountEarned: "2.64",
    status: "Finished",
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },

    stakeId: "30d2e6ac9add",
    amountStaked: "41,845.8",
    amountEarned: "2.64",
    status: "Finished",
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },

    stakeId: "30d2e6ac9add",
    amountStaked: "41,845.8",
    amountEarned: "2.64",
    status: "Finished",
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },

    stakeId: "30d2e6ac9add",
    amountStaked: "41,845.8",
    amountEarned: "2.64",
    status: "Finished",
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },

    stakeId: "30d2e6ac9add",
    amountStaked: "41,845.8",
    amountEarned: "2.64",
    status: "Live",
    actions: "",
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },

    stakeId: "30d2e6ac9add",
    amountStaked: "41,845.8",
    amountEarned: "2.64",
    status: "Live",
    actions: "",
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },

    stakeId: "30d2e6ac9add",
    amountStaked: "41,845.8",
    amountEarned: "2.64",
    status: "Live",
    actions: "",
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },

    stakeId: "30d2e6ac9add",
    amountStaked: "41,845.8",
    amountEarned: "2.64",
    status: "Live",
    actions: "",
  },
  {
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },

    stakeId: "30d2e6ac9add",
    amountStaked: "41,845.8",
    amountEarned: "2.64",
    status: "Live",
    actions: "",
  },
];
const claimedTransaction = [
  {
    date: "02-04 13:45:54",
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    minStaking: "10",
    maxStaking: "10,000",
    apy: "5.86%",
    duration: "30 days",
    status: "Finished",
  },
  {
    date: "02-04 13:45:54",
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    minStaking: "10",
    maxStaking: "10,000",
    apy: "5.86%",
    duration: "30 days",
    status: "Live",
  },
  {
    date: "02-04 13:45:54",
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    minStaking: "10",
    maxStaking: "10,000",
    apy: "5.86%",
    duration: "30 days",
    status: "Finished",
  },
  {
    date: "02-04 13:45:54",
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    minStaking: "10",
    maxStaking: "10,000",
    apy: "5.86%",
    duration: "30 days",
    status: "Live",
  },
  {
    date: "02-04 13:45:54",
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    minStaking: "10",
    maxStaking: "10,000",
    apy: "5.86%",
    duration: "30 days",
    status: "Finished",
  },
  {
    date: "02-04 13:45:54",
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    minStaking: "10",
    maxStaking: "10,000",
    apy: "5.86%",
    duration: "30 days",
    status: "Live",
  },
  {
    date: "02-04 13:45:54",
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    minStaking: "10",
    maxStaking: "10,000",
    apy: "5.86%",
    duration: "30 days",
    status: "Finished",
  },
  {
    date: "02-04 13:45:54",
    coin: { logo: binance, name: "Binacne Coin", shortName: "BNB" },
    minStaking: "10",
    maxStaking: "10,000",
    apy: "5.86%",
    duration: "30 days",
    status: "Live",
  },
];
const Tables = ({ rewards }) => {
  const { isDarkTheme } = useTheme();
  const tabs = ["Unclaimed", "Claimed Rewards"];
  const [activeTab, setActiveTab] = useState("Unclaimed");

  const [searchValue, setSearchValue] = useState("");
  const liveStakes = rewards.filter((item) => item.is_claimed === false);

  const finishedStakes = rewards.filter((item) => item.is_claimed === true);

  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <div className={classes.header}>
        <div className={classes.tabs}>
          {tabs.map((tab, i) => (
            <p
              className={clsx(
                classes.tab,
                tab.toLowerCase() === activeTab.toLowerCase() &&
                  classes.activeTab
              )}
              key={i}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </p>
          ))}
        </div>

        <div className={classes.searchContainer}>
          <img src={magnifier} alt="magnifier" className={classes.icon} />
          <input
            type="search"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </div>
      {activeTab === "Unclaimed" && <LiveAndFinished data={liveStakes} />}
      {activeTab === "Claimed Rewards" && (
        <LiveAndFinished finished data={finishedStakes} />
      )}
      {activeTab === "Claimed Transaction" && (
        <ClaimedTransaction data={claimedTransaction} />
      )}
    </div>
  );
};

export default Tables;
