import React, { useState } from "react";
import classes from "./WithdrawTo.module.css";
import Input from "components/common/Input/Input";

import Dropdown from "../../Deposit/DepositCrypto/Dropdown/Dropdown";

const WithdrawTo = ({
  dropdownItems,
  address,
  setAddress,
  selectedNetwork,
  setSelectedNetwork,
}) => {


  return (
    <div className={classes.wrapper}>
      <p className={classes.label}> Withdrawal Details</p>


      <Dropdown
        borderNone
        selectedOption={selectedNetwork}
        setSelectedOption={setSelectedNetwork}
        uniqueKey="select-network"
        pattern2
        placeholder="Select Network"
        options={dropdownItems}
        onSelect={(val) => {
          setSelectedNetwork(val);
        }}
      />
      <Input
        rootClassName={classes.input}
        type="text"
        value={address}
        placeholder="Enter Address"
        onChange={(e) => setAddress(e.target.value)}
      />
    </div>
  );
};

export default WithdrawTo;
