import clsx from "clsx";
import React from "react";
import classes from "./OverView.module.css";
import { greenArrow, redArrow } from "assets";

const SingleRow = ({ el: ticker, currency }) => {
  let base_curr = currency?.find((curr) => curr?.id == ticker?.base_asset?.toLowerCase());




  return (
    <div className={classes.lists}>
      <div className={clsx(classes.item, classes.firstItem)}>
        <div className={classes.logoAndNameContainer}>
          <img src={base_curr?.logo} alt="#" className={classes.logo} />

          <p>{ticker?.base_asset + "/" + ticker?.quote_asset}</p>
        </div>
      </div>
      <div className={classes.priceContainer}>
        <p className={clsx(classes.item, classes.price)}>${ticker?.last_price?.toFixed(2)}</p>
      </div>
      <p
        className={[
          classes.item,
          classes.change24h,
          ticker?.change > 0
            ? clsx(classes.positiveAmount, classes.amount)
            : clsx(classes.negativeAmount, classes.amount),
        ].join(" ")}
      >
        {ticker?.change > 0 ? "+" : "-"}
        {Math.abs(ticker?.change?.toFixed(2))}%
        <img src={2 > 0 ? greenArrow : redArrow} alt="#" />
      </p>
    </div>
  );
};

export default SingleRow;
