import { Link } from "react-router-dom";

import Section from "components/common/Section/Section";
import PageHeading from "components/common/PageHeading/PageHeading";
import Navigator from "components/common/Navigator/Navigator";
import {
  arrowRight,
  methodAuthenticator,
  methodEmail,
  methodPhone,
} from "assets";
import classes from "./VerificationMethod.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const VerificationMethod = () => {
  const [enabledMFA, setEnabledMFA] = useState([])
  const [loginData, setLoginData] = useState({})
  const navigate = useNavigate();


  async function getVerificationMethods() {
    let verification_methods = localStorage.getItem("enabled-mfa");
    let body = localStorage.getItem("login-details");
    setLoginData(JSON.parse(body))
    verification_methods = JSON.parse(verification_methods)
    setEnabledMFA(verification_methods)
  }
  useEffect(() => {
    getVerificationMethods()
  }, [])


  async function emailHandler(e) {
    let api = process.env.REACT_APP_API;
    let route = "/auth/request-otp";
    let url = api + route;

    let body = {
      ...loginData,
      otp_method: "Email"
    }
    try {
      let { data, status } = await axios.post(url, body);

      if (status == 200) {
        // window.location.href = "/email-verification"
        navigate("/email-verification")
      }



      // window.location.href = "/verification-method"

    } catch (e) {

    }
  }

  async function phoneHandler(e) {
    let api = process.env.REACT_APP_API;
    let route = "/auth/request-otp";
    let url = api + route;

    let body = {
      ...loginData,
      otp_method: "Phone"
    }


    try {
      let { data, status } = await axios.post(url, body);

      if (status == 200) {
        // window.location.href = "/phone-verification"
        navigate("/phone-verification")
      }

    } catch (e) {

    }
  }

  return (
    <Section withPadding xShort className={classes.verificationMethod}>
      <PageHeading
        heading="Verification Method"
        subHeading="Select a verification method. We will send a 6 digit verification code to your selected method."
      />

      <div className={classes.cards}>
        {enabledMFA.includes("Email") &&
          <Link
            onClick={emailHandler}
            className={classes.card}>
            <div className={classes.left}>
              <img src={methodEmail} alt="email" />
              <div>
                <h5>Email</h5>
                <p>Use email for 2FA verification</p>
              </div>
            </div>
            <div className={classes.right}>
              <img src={arrowRight} alt="arrow" />
            </div>
          </Link>}
        {enabledMFA.includes("Phone") &&
          <Link
            // to="/phone-verification"
            onClick={phoneHandler}
            className={classes.card}>
            <div className={classes.left}>
              <img src={methodPhone} alt="phone" />
              <div>
                <h5>Phone</h5>
                <p>Use phone number for verification</p>
              </div>
            </div>
            <div className={classes.right}>
              <img src={arrowRight} alt="arrow" />
            </div>
          </Link>}
        {enabledMFA.includes("TOTP") && <Link to="/google-authenticator-verification" className={classes.card}>
          <div className={classes.left}>
            <img src={methodAuthenticator} alt="authenticator" />
            <div>
              <h5>Google Authenticator</h5>
              <p>Use google authenticator for verification</p>
            </div>
          </div>
          <div className={classes.right}>
            <img src={arrowRight} alt="arrow" />
          </div>
        </Link>}
        {enabledMFA.length == 0 && <Link to="/google-authenticator-verification" className={classes.card}>
          <div className={classes.left}>
            <img src={methodAuthenticator} alt="authenticator" />
            <div>
              <h5>No 2FA Verifications Set</h5>
              <p>Account will be vulnerable</p>
            </div>
          </div>
          <div className={classes.right}>
            <img src={arrowRight} alt="arrow" />
          </div>
        </Link>}
      </div>

      <Navigator to="/login">Back to Login</Navigator>
    </Section>
  );
};

export default VerificationMethod;
