import React, { useRef, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";

import classes from "./SingleRow.module.css";
import clsx from "clsx";
import CancelPopup from "../CancelPopup/CancelPopup";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useSelector } from "react-redux";

const SingleRow = ({
  time,
  pair,
  type,
  side,
  price,
  amount,
  filled,

  unFilled,
  triggerCondition,
  order_id,
  total,
  timestamp,
  cancelAll,
}) => {
  const cancelRef = useRef();
  const [isShowCancelPopup, setIsShowCancelPopup] = useState(false);
  const [cancel, setCancel] = useState(false);

  useOnClickOutside(cancelRef, () => setIsShowCancelPopup(false));
  return (
    <>
      <tr className={clsx(classes.row, classes.valueContainer)}>
        <td className={clsx(classes.item, classes.firstItem)}>{time}</td>
        <td
          className={clsx(
            classes.item,
            classes.pair,
            side.toLowerCase() === "buy" && classes.buy,
            side.toLowerCase() === "sell" && classes.sell
          )}
        >
          {pair}
        </td>
        <td className={classes.item}>{type}</td>
        <td
          className={clsx(
            classes.item,
            side.toLowerCase() === "buy" && classes.buy,
            side.toLowerCase() === "sell" && classes.sell
          )}
        >
          {side}
        </td>
        <td className={classes.item}>{price}</td>
        <td className={classes.item}>{amount}</td>
        <td className={classes.item}>{filled}</td>
        <td className={classes.item}>{unFilled}</td>
        <td className={classes.item}>{"--"}</td>
        <td className={classes.item}>{total}</td>

        <td className={clsx(classes.item, classes.lastItem)}>
          <div className={classes.buttonContainer}>
            <RiDeleteBin6Line
              className={classes.deleteIcon}
              color={isShowCancelPopup ? "var(--color-red)" : null}
              onClick={() => setIsShowCancelPopup(true)}
            />
            <div ref={cancelRef}>
              <CancelPopup
                isActive={isShowCancelPopup}
                setCancel={setCancel}
                setIsShowCancelPopup={setIsShowCancelPopup}
                orderID={order_id}
              />
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default SingleRow;
