import Modal from "components/common/Modal/Modal";
import classes from "./SportDetailsModal.module.css";
import Button from "components/Button/Button";
import clsx from "clsx";

const SpotDetailsModal = ({
  isActive,
  onClose,
  coin,
  amount,
  usdAmount,
  available,
  withdrawing,
  spotOrders,
  convertOrder,
  frozen,
  spot,
  earn,
}) => {
  return (
    <Modal
      className={clsx(classes.wrapper, "overflow")}
      isActive={isActive}
      onClose={onClose}
      styled
    >
      <div className={classes.logoAndNameContainer}>
        <img src={coin.logo} alt="#" className={classes.logo} />

        <div className={classes.nameContainer}>
          <p className={classes.symbol}>{coin.symbol}</p>
          <span className={classes.name}>{coin.name}</span>
        </div>
      </div>
      <div className={classes.balanceContainer}>
        <div className={classes.balance}>
          <h2>{amount.toFixed(4)}</h2>
          <p>
            <span>≈</span> {usdAmount}
          </p>
        </div>

        <Button onClick={() => {}} btnGray size="sm" mlAuto>
          Convert
        </Button>
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.spaceBetween}>
          <p className={classes.label}>Available</p>
          <p className={classes.value}>{available.toFixed(2)}</p>
        </div>{" "}
        <div className={classes.spaceBetween}>
          <p className={classes.label}>Withdrawing</p>
          <p className={classes.value}>{withdrawing.toFixed(2)}</p>
        </div>{" "}
        <div className={classes.spaceBetween}>
          <p className={classes.label}>Spot Orders</p>
          <p className={classes.value}>{spotOrders.toFixed(2)}</p>
        </div>{" "}
        <div className={classes.spaceBetween}>
          <p className={classes.label}>Convert Orders</p>
          <p className={classes.value}>{convertOrder.toFixed(2)}</p>
        </div>{" "}
        <div className={classes.spaceBetween}>
          <p className={classes.label}>Frozen</p>
          <p className={classes.value}>{frozen.toFixed(2)}</p>
        </div>
      </div>
      <div className={classes.boxWrapper}>
        <div className={classes.infoContainer}>
          <div className={classes.spaceBetween}>
            <div className={classes.logoAndNameContainer}>
              <img src={spot.logo} alt="#" className={classes.logo} />
              <p className={classes.symbol}>{spot.name}</p>
            </div>
            <p className={classes.currency}>{spot.currency}</p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.text}>${usdAmount.toLocaleString()}</p>
            <p className={classes.growth}>
              {spot.growth > 0 ? "+" : "-"}
              {spot.growth}%
            </p>
          </div>
        </div>{" "}
        <div className={classes.infoContainer}>
          <div className={classes.spaceBetween}>
            <div className={classes.logoAndNameContainer}>
              <img src={earn.logo} alt="#" className={classes.logo} />
              <p className={classes.symbol}>{earn.name}</p>
            </div>
            <p className={classes.currency}>{earn.currency}</p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.text}>APR up to</p>
            <p className={classes.growth}>
              {earn.growth > 0 ? "+" : "-"}
              {earn.growth}%
            </p>
          </div>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button onClick={() => {}} btnGray size="sm">
          Convert
        </Button>{" "}
        <Button onClick={() => {}} btnGray size="sm">
          Earn
        </Button>{" "}
        <Button onClick={() => {}} btnGray size="sm">
          Buy / Sell
        </Button>{" "}
        <Button onClick={() => {}} btnGray size="sm">
          Deposit
        </Button>{" "}
        <Button onClick={() => {}} btnGray size="sm">
          Withdraw
        </Button>{" "}
        <Button onClick={() => {}} btnGray size="sm">
          Transfer
        </Button>{" "}
      </div>
    </Modal>
  );
};

export default SpotDetailsModal;
