import { magnifier, binance } from "assets";
import clsx from "clsx";
import { useState } from "react";
import { useTheme } from "ThemeContext/ThemeContext";
import DepositOrWithDrawal from "../DepositOrWithDrawal/DepositOrWithDrawal";
import classes from "./Tables.module.css";
import TradingFees from "../TradingFees/TradingFees";
const Tables = () => {
  const { isDarkTheme } = useTheme();
  const tabs = [
    "Trading Fees",
    "Deposit/Withdrawal",
    // "Claimed Transaction"
  ];
  const [activeTab, setActiveTab] = useState("Trading Fees");

  const [searchValue, setSearchValue] = useState("");

  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <div className={classes.header}>
        <div className={classes.tabs}>
          {tabs.map((tab, i) => (
            <p
              className={clsx(
                classes.tab,
                tab.toLowerCase() === activeTab.toLowerCase() &&
                  classes.activeTab
              )}
              key={i}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </p>
          ))}
        </div>

        <div className={classes.searchContainer}>
          <img src={magnifier} alt="magnifier" className={classes.icon} />
          <input
            type="search"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </div>
      {activeTab === "Trading Fees" && <TradingFees />}
      {activeTab === "Deposit/Withdrawal" && <DepositOrWithDrawal />}
    </div>
  );
};

export default Tables;
