import React from "react";
import classes from "./IdentifyVerification.module.css";

import Wrapper from "components/common/Wrapper/Wrapper";
import Button from "components/Button/Button";
import axios from "axios";
import {
  addressProve,
  encryted,
  faceRecognition,
  nationalId,
  personalInfo,
  verification,
} from "assets";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const IdentityVerification = ({ is_verified }) => {
  let Navigate = useNavigate();
  const lists = [
    { icon: personalInfo, label: "Personal information" },
    { icon: nationalId, label: "National ID or Passport" },
    { icon: faceRecognition, label: "Facial Recognition" },
    { icon: addressProve, label: "Address prove document" },
  ];

  const Verifiedlists = [
    { icon: personalInfo, label: "No Deposit Limit" },
    { icon: nationalId, label: "No Withdrawal Limit" },
  ];

  return (
    <Wrapper>
      <div className={classes.container}>
        <div className={classes.left}>
          <div className={classes.top}>
            <div className={classes.topContent}>
              <h4 className={classes.heading}>
                {is_verified ? "KYC Verified" : "Identity Verification"}
              </h4>
              <p className={classes.info}>
                {is_verified
                  ? "Your identity has been verified"
                  : "It only takes 3-5 minutes to verify your account."}
              </p>

              {is_verified == 0 && (
                <Button
                  onClick={async () => {
                    let api = process.env.REACT_APP_API;
                    let route = "/kyc/init";

                    try {
                      let { data, status } = await axios.get(api + route, {
                        headers: {
                          Authorization: "Bearer " + Cookies.get("auth-token"),
                        },
                      });
                      if (status == 200) {
                        let token = data.sdk_token;
                        localStorage.setItem("sumsub_sdk_token", token);
                        Navigate("/kyc/sumsub");
                      }
                    } catch (e) {}
                  }}
                  btnPrimary
                  size="md"
                  className={classes.button}
                >
                  Verify Now
                </Button>
              )}
              {is_verified > 0 && (
                <Button
                  className={classes.button}
                  onClick={() => {
                    Navigate("/wallet/crypto/deposit");
                  }}
                  btnPrimary
                  size="md"
                >
                  Deposit Crypto
                </Button>
              )}
            </div>
            <img src={verification} alt="#" className={classes.image} />
          </div>
          {is_verified == 0 && (
            <div className={classes.listContainer}>
              {lists.map((el, i) => (
                <div className={classes.list} key={i}>
                  <img src={el.icon} alt="#" className={classes.icon} />
                  <p className={classes.label}>{el.label}</p>
                </div>
              ))}
            </div>
          )}
          {is_verified > 0 && (
            <div className={classes.listContainer}>
              {Verifiedlists.map((el, i) => (
                <div className={classes.list} key={i}>
                  <img src={el.icon} alt="#" className={classes.icon} />
                  <p className={classes.label}>{el.label}</p>
                </div>
              ))}
            </div>
          )}
          <div className={classes.list}>
            <img src={encryted} alt="#" className={classes.icon} />
            <p className={classes.label}>
              Your information on {process.env.REACT_APP_EXCHANGE_NAME} is
              encrypted.
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default IdentityVerification;
