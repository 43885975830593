import React, { useEffect, useRef, useState } from "react";
import { LuCopy } from "react-icons/lu";

import Button from "components/Button/Button";
import clsx from "clsx";

import { useTheme } from "ThemeContext/ThemeContext";
import classes from "./InviteFriends.module.css";
import CopyToClipboard from "react-copy-to-clipboard";
import InviteFriendsModal from "modals/InviteFriendsModal/InviteFriendsModal";
import { toast } from "react-toastify";
const InviteFriends = ({ user_data }) => {
  const { isDarkTheme } = useTheme();
  const [isModalActive, setIsModalActive] = useState();

  return (
    <>
      <div
        className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}
      >
        <div className={classes.comissionRate}>
          <p className={classes.text}>Commission Rate:</p>
          <p className={classes.rate}>10%</p>
        </div>
        <div className={classes.box}>
          <p className={classes.label}>Code</p>
          <p className={classes.value}>{user_data?.platform_id}</p>
          <CopyToClipboard text={user_data?.platform_id}>
            <div className={classes.copyContainer}>
              <LuCopy className={classes.copyIcon} />
            </div>
          </CopyToClipboard>
        </div>{" "}
        <div className={classes.box}>
          <p className={classes.label}>Link</p>
          <p className={classes.value}>{process.env.REACT_APP_FRONTEND_URL + "/signup/" + user_data?.platform_id}</p>
          <CopyToClipboard text={process.env.REACT_APP_FRONTEND_URL + "/signup/" + user_data?.platform_id}>
            <div className={classes.copyContainer}>
              <LuCopy className={classes.copyIcon} />
            </div>
          </CopyToClipboard>
        </div>
        <Button
          wFull
          btnPrimary
          size="md"
          onClick={() => {
            // setIsModalActive(true);
            toast.error("Feature not available yet");
          }}
        >
          Invite Friend via Email
        </Button>
      </div>
      <InviteFriendsModal
        isActive={isModalActive}
        onClose={() => setIsModalActive(false)}
      />
    </>
  );
};

export default InviteFriends;
