import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { createSelector } from 'reselect';


const getMarkets = state => state.markets.markets;
const getBalances = state => state.balances.balances;
const getTickers = state => state.tickers.tickers;


export const parseTickers = createSelector(
    [getMarkets, getBalances, getTickers], // Input selectors
    (markets, balances, tickers) => {

        let parsedTickers = tickers.map((ticker) => {
            let market = markets?.find((market) => market.symbol == ticker.market_id)
            return {
                ...ticker,
                ...market
            }
        })

        localStorage.setItem("parsedTickers", JSON.stringify(parsedTickers))
        return parsedTickers
    })

export const fetchTickers = createAsyncThunk("ticker/tickers", async (args, {
    rejectWithValue
}) => {

    try {
        let base_url = process.env.REACT_APP_API
        let tickerRoute = "/ticker/tickers"
        let tickerData = await axios.get(base_url + tickerRoute)
        let tickers = (tickerData.data.tickers)
        return tickers
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


const tickersSlice = createSlice({
    name: 'tickers',
    initialState: {
        tickers: ["s"],
        isLoading: false,
        isFailed: false
    },
    reducers: {
    },
    // extra reducer use builder callback
    extraReducers: (builder) => {
        builder.addCase(fetchTickers.pending, (state, action) => {
            state.isLoading = true
            state.isFailed = false
        })
        builder.addCase(fetchTickers.fulfilled, (state, action) => {
            state.isLoading = false
            state.isFailed = false
            state.tickers = action.payload
        })
        builder.addCase(fetchTickers.rejected, (state, action) => {
            state.isLoading = false
            state.isFailed = true
        })
    }

})

export default tickersSlice.reducer