import React from "react";
import FeeStructure from "components/PaymentMethod/FeesStructure/FeeStructure";
import Tables from "components/PaymentMethod/Tables/Tables";
import Footer from "components/Footer/Footer";
import clsx from "clsx";
import classes from "./PaymentMethod.module.css";

const PaymentMethod = () => {
  return (
    <>
      <div className={clsx("container", classes.wrapper)}>
        <FeeStructure />
        <Tables />
      </div>
      <Footer />
    </>
  );
};

export default PaymentMethod;
