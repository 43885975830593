import clsx from "clsx";
import React, { useEffect, useState } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";

import Section from "components/common/Section/Section";
import PageHeading from "components/common/PageHeading/PageHeading";
import Navigator from "components/common/Navigator/Navigator";
import Button from "components/Button/Button";
import classes from "./Verification.module.css";
import axios from "axios";
import { useTheme } from "ThemeContext/ThemeContext";

const renderButton = (buttonProps) => {
  return (
    <button onClick={() => {}} {...buttonProps} className={classes.resend}>
      {buttonProps.remainingTime !== 0
        ? `Resend Code (${buttonProps.remainingTime})s`
        : "Resend"}
    </button>
  );
};
const renderTime = () => React.Fragment;

const Verification = ({
  noResend,
  title = "Login Verification",
  otpInvalid,
  setOtpInvalid,
  otp,
  setOTP,
  handler,
  subTitle,
  redirect,
}) => {
  // useEffect(() => {
  //   setOtpInvalid(false);
  // }, [otp]);
  const { isDarkTheme } = useTheme();
  return (
    <Section
      withPadding
      xShort
      className={clsx(
        classes.verificationMethod,
        !isDarkTheme && classes.lightTheme
      )}
    >
      <PageHeading heading={title} subHeading={subTitle} />

      <div className={classes.inputs}>
        <OTPInput
          inputClassName={clsx(classes.input, otpInvalid && classes.hasError)}
          value={otp}
          onChange={setOTP}
          autoFocus
          OTPLength={6}
          otpType="number"
          disabled={false}
        />
      </div>

      {otpInvalid && (
        <div className={classes.helperError}>
          Please enter valid verification code.
        </div>
      )}

      <div className={clsx(classes.actions, noResend && classes.fullWidth)}>
        {!noResend && (
          <ResendOTP
            renderButton={renderButton}
            renderTime={renderTime}
            action={() => alert("vvv")}
          />
        )}
        <Button
          // to={redirect || "/forgot-password"}
          onClick={handler}
          size="md"
          btnPrimary
        >
          Verify
        </Button>
      </div>

      <Navigator to="/login">Back to Login</Navigator>
    </Section>
  );
};

export default Verification;
