import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'


export const getTransactions = createAsyncThunk("fetch-user-transactions", async (args, {
    rejectWithValue
}) => {

    try {
        let base_url = process.env.REACT_APP_API
        let route = "/balance/deposits"
        let data = await axios.get(base_url + route, {
            headers: {
                "Authorization": "Bearer " + Cookies.get("auth-token")
            }
        })


        let route2 = "/balance/withdrawals"
        let data2 = await axios.get(base_url + route2, {
            headers: {
                "Authorization": "Bearer " + Cookies.get("auth-token")
            }
        })

        let deposits = data.data.deposits;
        let withdrawals = data2.data.withdrawals;

        let info = {
            deposits: deposits,
            withdrawals: withdrawals
        }
        return info
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


const userInfoSlice = createSlice({
    name: 'stakingInstances',
    initialState: {
        deposits: [],
        withdrawals: [],
        isLoading: false,
        isFailed: false
    },
    reducers: {
    },
    // extra reducer use builder callback
    extraReducers: (builder) => {
        builder.addCase(getTransactions.pending, (state, action) => {
            state.isLoading = true
            state.isFailed = false
        })
        builder.addCase(getTransactions.fulfilled, (state, action) => {
            state.isLoading = false
            state.isFailed = false
            state.deposits = action.payload.deposits
            state.withdrawals = action.payload.withdrawals
        })
        builder.addCase(getTransactions.rejected, (state, action) => {
            state.isLoading = false
            state.isFailed = true
        })
    }

})

export default userInfoSlice.reducer