import Button from "components/Button/Button";
import Modal from "components/common/Modal/Modal";
import Heading from "components/common/Heading/Heading";
import classes from "./InviteFriendsModal.module.css";
import Input from "components/common/Input/Input";
import { useState } from "react";
import { useTheme } from "ThemeContext/ThemeContext";
import clsx from "clsx";

const InviteFriendsModal = ({ isActive, onClose }) => {
  const { isDarkTheme } = useTheme();
  const [email, setEmail] = useState("");
  return (
    <Modal
      className={classes.wrapper}
      isActive={isActive}
      onClose={onClose}
      styled
    >
      <div
        className={clsx(classes.container, !isDarkTheme && classes.lightTheme)}
      >
        <div className={classes.textContainer}>
          <Heading wFull center lg heading="Invite Friend via Email" />
          <p className={classes.text}>
            We will send the email to your friend with your referral id. Please
            enter your friend email.
          </p>
        </div>
        <Input
          label="Enter Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          rootClassName={classes.inputContainer}
          className={classes.input}
          wFull
        />

        <div className={classes.buttonContainer}>
          <Button
            onClick={() => {}}
            className={classes.btn}
            btnPrimary
            size="md"
          >
            Send Email
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default InviteFriendsModal;
