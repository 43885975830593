import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Section from "components/common/Section/Section";
import Hr from "components/common/Hr/Hr";
import QrLogin from "components/Auth/QrLogin/QrLogin";
import Tabs from "components/common/Tabs/Tabs";
import Input from "components/common/Input/Input";
import Button from "components/Button/Button";
import PageHeading from "components/common/PageHeading/PageHeading";
import DontMiss from "./DontMiss/DontMiss";
import Checkbox from "components/common/Checkbox/Checkbox";
import { apple, dropdownArrow, google } from "assets";
import classes from "./Auth.module.css";
import Navigator from "components/common/Navigator/Navigator";
import PhoneNumberDropdown from "components/common/PhoneNumberDropdown/PhoneNumberDropdown";
import useOnClickOutside from "hooks/useOnClickOutside";
import { countries } from "common/constants";
import axios from "axios";
import SetPassword from "components/SetPassword/SetPassword";
import { useTheme } from "ThemeContext/ThemeContext";
import clsx from "clsx";
import { toast } from "react-toastify";

const Auth = ({ isLoginForm }) => {

  let { refferer } = useParams();

  async function blockIndia() {
    let path = window.location.pathname;
    console.log(path, "path");
    if (path == "/unserved-country") return;
    let { data } = await axios.get("https://ipinfo.io");
    let country = data.country;
    console.log(country, "country");
    if (country == "IN") {
      window.location.href = "/unserved-country";
    }
  }

  useEffect(() => {
    blockIndia();
  }, []);
  const { isDarkTheme } = useTheme();
  const [selectedTabState, setSelectedTabState] = useState("Email Address");
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [selectedPhoneValue, setSelectedPhoneValue] = useState(countries[0]);
  const dropdownRef = useRef(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const navigate = useNavigate();

  function checkIfNumber(event) {
    /**
     * Allowing: Integers | Backspace | Tab | Delete | Left & Right arrow keys
     **/

    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  }

  useOnClickOutside(dropdownRef, () => setIsDropdownActive(false));

  function emailHandler(e) {
    setEmail(e.target.value);
  }
  async function InitloginHandler() {
    let api = process.env.REACT_APP_API;
    let route = "/auth/get-mfa";
    let url = api + route;

    let signInMethod =
      selectedTabState === "Phone Number"
        ? "Phone"
        : selectedTabState === "Email Address"
          ? "Email"
          : "Email";

    let body = {
      method: signInMethod,
      data:
        selectedTabState === "Phone Number"
          ? countries.find((country) => country.code == selectedPhoneValue.code)
            .code + phone
          : email,
      password: loginPassword,
    };
    try {
      let { data } = await axios.post(url, body);

      localStorage.setItem("enabled-mfa", JSON.stringify(data.mfa));
      localStorage.setItem("login-details", JSON.stringify(body));

      // window.location.href = "/verification-method"
      navigate("/verification-method");
    } catch (e) {
      // if error is 401 then toast

      if (e.response.status === 401) {
        toast.error("Invalid credentials", {
          position: "top-center",
          autoClose: 5000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
      // alert(e.response.data.message);
    }
  }

  async function initSignupHandler() {
    let signInMethod =
      selectedTabState === "Phone Number"
        ? "Phone"
        : selectedTabState === "Email Address"
          ? "Email"
          : "Email";

    let body = {
      method: signInMethod,
      data:
        selectedTabState === "Phone Number"
          ? countries.find((country) => country.code == selectedPhoneValue.code)
            .code + phone
          : email,
    };
    try {
      localStorage.setItem("signup-details", JSON.stringify(body));
      localStorage.setItem("refferer", refferer);
      navigate("/set-password");
    } catch (e) { }
  }

  return (
    <Section withPadding className={classes.auth} short>
      <PageHeading
        heading={
          isLoginForm
            ? "Login to " + process.env.REACT_APP_EXCHANGE_NAME
            : "Create an Account"
        }
        subHeading={
          isLoginForm
            ? "Welcome back! Login to start spot trading."
            : "Register now and manage your cryptocurrency."
        }
      />

      <Hr />

      <div
        className={clsx(classes.authMain, !isDarkTheme && classes.lightTheme)}
      >
        <div className={classes.left}>
          <Tabs
            onTabChange={(tabLabel) => setSelectedTabState(tabLabel)}
            activeTab={selectedTabState}
            items={[
              {
                label: "Email Address",
              },
              {
                label: "Phone Number",
              },
            ]}
          />

          <form className={classes.form}>
            {selectedTabState === "Phone Number" ? (
              <div className={classes.phoneNumber}>
                <div ref={dropdownRef}>
                  <PhoneNumberDropdown
                    isActive={isDropdownActive}
                    selectedValue={selectedPhoneValue}
                    onSelect={(val) => {
                      setSelectedPhoneValue(val);
                      setIsDropdownActive(false);
                    }}
                  >
                    <div
                      className={classes.dropdownItem}
                      onClick={() => {
                        if (!isDropdownActive) setIsDropdownActive(true);
                      }}
                    >
                      {selectedPhoneValue.code}
                      <img src={dropdownArrow} alt="dropdown" />
                    </div>
                  </PhoneNumberDropdown>
                </div>
                <Input
                  rootClassName={classes.input}
                  onKeyDown={(event) => checkIfNumber(event)}
                  type="number"
                  placeholder="Phone number"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </div>
            ) : (
              <Input type="email" placeholder="Email" onChange={emailHandler} />
            )}
            {isLoginForm && (
              <Input
                type="password"
                placeholder="Password"
                onChange={(e) => {
                  setLoginPassword(e.target.value);
                }}
              />
            )}

            {isLoginForm ? (
              <div className={classes.actions}>
                <Button
                  type="button"
                  // to="/verification-method"
                  onClick={InitloginHandler}
                  size="md"
                  btnPrimary
                >
                  Login
                </Button>
                <Button
                  type="button"
                  to="/forgot-password"
                  size="md"
                  btnTransparent
                >
                  Forgot Password?
                </Button>
              </div>
            ) : (
              <Button
                type="button"
                // to="/signup-verify"
                onClick={initSignupHandler}
                className={classes.fullWidthBtn}
                size="md"
                btnPrimary
              >
                Create Account
              </Button>
            )}
          </form>

          <div className={classes.or}>
            <div className={classes.orInner}>
              or {isLoginForm ? "login" : "sign up"} with
            </div>
          </div>

          <div className={classes.btns}>
            <Button
              onClick={() => {
                // alert("SSO temporarily disabled!");
                toast.error("SSO will be enabled in the future", {
                  position: "top-center",
                  autoClose: 5000,
                  theme: "dark",
                  hideProgressBar: false,
                  closeOnClick: true,

                })
              }}
              size="md"
              btnBlack
            >
              <img src={google} alt="google" />
              <span>Google</span>
            </Button>
            <Button
              onClick={() => {
                toast.error("SSO will be enabled in the future", {
                  position: "top-center",
                  autoClose: 5000,
                  theme: "dark",
                  hideProgressBar: false,
                  closeOnClick: true,

                })
              }}
              size="md"
              btnBlack
            >
              <img src={apple} alt="apple" />
              <span>Apple</span>
            </Button>
          </div>

          {!isLoginForm && (
            <Checkbox
              className={classes.termsCheck}
              label={
                <>
                  I agree to all <Link to={"/terms-and-conditions"}> Terms & Condition</Link>,{" "}
                  <Link to={"/privacy-policy"}>Privacy Policy</Link>, <Link to={"/aml"}>AML / KYC</Link> and <Link to={"/risk-disclosure"}>Risk Disclosure</Link>
                </>
              }
            />
          )}

          {isLoginForm ? (
            <Navigator to="/signup">Create an Account</Navigator>
          ) : (
            <Navigator to="/login">Back to Login</Navigator>
          )}
        </div>
        <div className={classes.verticalLine}></div>
        {isLoginForm ? <QrLogin /> : <DontMiss />}
      </div>
    </Section>
  );
};

export default Auth;
