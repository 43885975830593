import React, { useState, useMemo } from "react";
import Pagination from "common/Pagination/Pagination";
import classes from "./LiveAndFinished.module.css";

import clsx from "clsx";
import SingleRow from "./SingleRow/SingleRow";

const LiveAndFinished = ({ data, finished }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, itemsPerPage]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <tbody>
            <tr className={classes.row}>
              <th className={clsx(classes.heading, classes.textAlignCenter)}>
                <p className={classes.tableHeading}>Token Earned</p>
              </th>
              <th className={classes.heading}>
                <p className={classes.tableHeading}>Reward ID</p>
              </th>
              <th className={classes.heading}>
                <p className={classes.tableHeading}>Rewards Earned</p>
              </th>
              <th className={classes.heading}>
                <p className={classes.tableHeading}> Date Received</p>
              </th>
              <th className={clsx(classes.heading)}>
                <p
                  className={clsx(
                    classes.tableHeading,
                    finished && classes.lastHeading
                  )}
                >
                  {" "}
                  Status
                </p>
              </th>
              {!finished && (
                <th className={classes.heading}>
                  <p
                    className={clsx(classes.tableHeading, classes.lastHeading)}
                  >
                    Action
                  </p>
                </th>
              )}
            </tr>
            {currentTableData?.map((el, index) => (
              <SingleRow {...el} key={index} index={index} />
            ))}
          </tbody>
        </table>{" "}
        <div className={classes.pagination}>
          <Pagination
            currentPage={currentPage}
            totalCount={data?.length}
            pageSize={itemsPerPage}
            onPageChange={(page) => setCurrentPage(page)}
            siblingCount={0}
          />
        </div>
      </div>
    </div>
  );
};

export default LiveAndFinished;
